import { SigninComponent } from './user/signin/signin.component';
import { LayoutComponent } from '../layout/layout.component';
import { SignupComponent } from './user/signup/signup.component';
import { LockComponent } from './user/lock/lock.component';
import { RecoverComponent } from './user/recover/recover.component';
import { Signinv2Component } from './user/signinv2/signinv2.component';
import { Signupv2Component } from './user/signupv2/signupv2.component';
import { ProveedoresComponent } from './catalogos/proveedores/proveedores.component';
import { ClientesComponent } from './catalogos/clientes/clientes.component';
import { ConsignatariosComponent } from './catalogos/consignatarios/consignatarios.component';
import { TipoCambioComponent } from './catalogos/tipo-cambio/tipo-cambio.component';
import { ProspectosComponent } from './catalogos/prospectos/prospectos.component';
import { ShippersComponent } from './catalogos/shippers/shippers.component';
import { AeropuertosComponent } from './catalogos/aeropuertos/aeropuertos.component';
import { AerolineasComponent } from './catalogos/aerolineas/aerolineas.component';
import { IncotermsComponent } from './catalogos/incoterms/incoterms.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { ConsultaServiciosComponent } from './consulta-servicios/consulta-servicios.component';
import { ServiciosInterComponent } from './servicios-inter/servicios-inter.component';
import { ConsultaServiciosInterComponent } from './consulta-servicios-inter/consulta-servicios-inter.component';
import { ValidaFacturasComponent } from './valida-facturas/valida-facturas.component';
import { EjecutivosComponent } from './catalogos/ejecutivos/ejecutivos.component';
import { ReasignarServiciosComponent } from './reasignar-servicios/reasignar-servicios.component';
import { ReasignarServiciosInterComponent } from './reasignar-servicios-inter/reasignar-servicios-inter.component';
import { BusquedaProveedorComponent } from './busqueda-proveedor/busqueda-proveedor.component';
import { BusquedaProspectoComponent } from './busqueda-prospecto/busqueda-prospecto.component';
import { ConsultaTerrestreComponent } from './consulta-terrestre/consulta-terrestre.component';

export const routes = [

    { path: '', redirectTo: '/signin', pathMatch: 'full'},
    { path: 'signin', component: SigninComponent },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
            { path: 'proveedores', component: ProveedoresComponent },
            { path: 'proveedores/page/:page', component: ProveedoresComponent },
            { path: 'clientes', component: ClientesComponent },
            { path: 'clientes/page/:page', component: ClientesComponent },
            { path: 'consignatarios', component: ConsignatariosComponent },
            { path: 'consignatarios/page/:page', component: ConsignatariosComponent },
            { path: 'tiposCambio', component: TipoCambioComponent },
            { path: 'tiposCambio/page/:page', component: TipoCambioComponent },
            { path: 'prospectos', component: ProspectosComponent },
            { path: 'prospectos/page/:page', component: ProspectosComponent },
            { path: 'shippers', component: ShippersComponent },
            { path: 'shippers/page/:page', component: ShippersComponent },
            { path: 'aeropuertos', component: AeropuertosComponent },
            { path: 'aeropuertos/page/:page', component: AeropuertosComponent },
            { path: 'aerolineas', component: AerolineasComponent },
            { path: 'aerolineas/page/:page', component: AerolineasComponent },
            { path: 'incoterms', component: IncotermsComponent },
            { path: 'incoterms/page/:page', component: IncotermsComponent },
            { path: 'ejecutivos', component: EjecutivosComponent },
            { path: 'ejecutivos/page/:page', component: EjecutivosComponent },
            { path: 'servicio', component: ServiciosComponent },
            { path: 'servicio/:idServicio', component: ServiciosComponent },
            { path: 'consultaServicios', component: ConsultaServiciosComponent },
            { path: 'servicioInter', component: ServiciosInterComponent },
            { path: 'servicioInter/:idServicio', component: ServiciosInterComponent },
            { path: 'consultaServiciosInter', component: ConsultaServiciosInterComponent },
            { path: 'validarFacturas', component: ValidaFacturasComponent },
            { path: 'kanban', loadChildren: './kanban/kanban.module#DashboardModule' },
            { path: 'widgets', loadChildren: './widgets/widgets.module#WidgetsModule' },
            { path: 'elements', loadChildren: './elements/elements.module#ElementsModule' },
            { path: 'forms', loadChildren: './forms/forms.module#FormsModule' },
            { path: 'charts', loadChildren: './charts/charts.module#ChartsModule' },
            { path: 'tables', loadChildren: './tables/tables.module#TablesModule' },
            { path: 'maps', loadChildren: './maps/maps.module#MapsModule' },
            { path: 'pages', loadChildren: './pages/pages.module#PagesModule' },
            { path: 'reasignar', component: ReasignarServiciosComponent },
            { path: 'reasignarInter', component: ReasignarServiciosInterComponent },
            { path: 'busquedaProveedor', component: BusquedaProveedorComponent },
            { path: 'busquedaProspecto', component: BusquedaProspectoComponent },
            { path: 'consultaTerrestres', component: ConsultaTerrestreComponent }
        ]
    },
    { path: 'error', loadChildren: './pages/errors/errors.module#ErrorsModule' },
    { path: 'signup', component: SignupComponent },
    { path: 'lock', component: LockComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'signinv2', component: Signinv2Component },
    { path: 'signupv2', component: Signupv2Component },

    // Not found
    { path: '**', redirectTo: 'signin' }

];
