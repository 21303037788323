import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Evaluacion } from '../../model/evaluacion';
import { EvaluacionProveedorService } from './evaluacion-proveedor.service';
import { EvaluacionesService } from '../../services/evaluaciones.service';
import { AuthService } from '../../auth/auth.service';
import { Equipo } from '../../model/equipo';
import { Contacto } from '../../model/contacto';
import { ProveedoresService } from '../../services/proveedores.service';
import { ProspectosService } from '../../services/prospectos.service';
import { SelectItem } from '../../model/select-item';
import { Catalogos } from '../../config/config';

const swal = require('sweetalert');

@Component({
  selector: 'evaluacion-proveedor',
  templateUrl: './evaluacion-proveedor.component.html',
  styleUrls: ['./evaluacion-proveedor.component.scss']
})
export class EvaluacionProveedorComponent implements OnInit, OnChanges {

  @Input()
  idEvaluacion: number;
  @Input()
  idProveedor: number;
  @Input()
  idProspecto: number;

  catalogoEquipos: string[] = Catalogos.equipos;
  catalogoGiros: string[] = Catalogos.giros;
  catalogoSociosComerciales: string[] = Catalogos.sociosComerciales;
  catalogoCoberturasNacional: string[] = Catalogos.coberturasNacional;
  catalogoPatiosOperacion: string[] = Catalogos.patiosOperacion;
  catalogoCodigosHazmat: string[] = Catalogos.codigosHazmat;
  catalogoServicios: string[] = Catalogos.servicios;
  catalogoUnidadesAutAero: string[] = Catalogos.unidadesAutAero;
  catalogoPuertos: string[] = Catalogos.puertos;
  catalogoFronteras: string[] = Catalogos.fronteras;
  catalogoRecursos: string[] = Catalogos.recursos;
  catalogoCertificacionesEmpresa: string[] = Catalogos.certificacionesEmpresa;
  catalogoCertificacionesOperador: string[] = Catalogos.certificacionesOperador;

  evaluacion: Evaluacion;
  giros: SelectItem[] = [];
  sociosComerciales: SelectItem[] = [];
  coberturasNacional: SelectItem[] = [];
  patiosOperacion: SelectItem[] = [];
  codigosHazmat: SelectItem[] = [];
  servicios: SelectItem[] = [];
  unidadesAutAero: SelectItem[] = [];
  puertosArrastre: SelectItem[] = [];
  fronterasCruce: SelectItem[] = [];
  recursosSeguridad: SelectItem[] = [];
  certificacionesEmpresa: SelectItem[] = [];
  certificadoOperadores: SelectItem[] = [];

  constructor(
    private evaluacionesServices: EvaluacionesService,
    public evaluacionProveedorService: EvaluacionProveedorService,
    public authService: AuthService,
    private proveedoresService: ProveedoresService,
    private prospectosService: ProspectosService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    this.cargaEvaluacion();
  }

  private cargaEvaluacion(): void {
    this.giros = [];
    this.sociosComerciales = [];
    this.coberturasNacional = [];
    this.patiosOperacion = [];
    this.codigosHazmat = [];
    this.servicios = [];
    this.unidadesAutAero = [];
    this.puertosArrastre = [];
    this.fronterasCruce = [];
    this.recursosSeguridad = [];
    this.certificacionesEmpresa = [];
    this.certificadoOperadores = [];

    if(this.idEvaluacion != 0) {
      this.evaluacionesServices.getEvaluacion(this.idEvaluacion).subscribe(
        response => {
          this.evaluacion = response;
        }
      );
    } else {
      this.evaluacion = new Evaluacion();
      this.evaluacion.esProveedor = false;
    }
  }

  public cancel(): void{
    this.evaluacionProveedorService.cerraModal();
  }

  public isReadOnly(): boolean {
    if (this.authService.hasRole('ROLE_SUPERADMIN')) {
      return false;
    }
    return true;
  }

  agregarEquipo(): void {
    if(this.evaluacion.equipos == null) {
      this.evaluacion.equipos = [];
    }
    let equipo: Equipo = new Equipo();
    this.evaluacion.equipos.push(equipo);
  }

  eliminarEquipo(equipo: Equipo) {
    const index: number = this.evaluacion.equipos.indexOf(equipo);
    if (index !== -1) {
      this.evaluacion.equipos.splice(index, 1);
    }
  }

  agregarContacto(): void {
    if(this.evaluacion.contactos == null) {
      this.evaluacion.contactos = [];
    }
    let contacto: Contacto = new Contacto();
    this.evaluacion.contactos.push(contacto);
  }

  eliminarContacto(contacto: Contacto) {
    const index: number = this.evaluacion.contactos.indexOf(contacto);
    if (index !== -1) {
      this.evaluacion.contactos.splice(index, 1);
    }
  }

  refreshGiros(value: any): void {
    this.giros = value;
  }

  refreshSC(value: any): void {
    this.sociosComerciales = value;
  }

  refreshCN(value: any): void {
    this.coberturasNacional = value;
  }

  refreshPO(value: any): void {
    this.patiosOperacion = value;
  }

  refreshCH(value: any): void {
    this.codigosHazmat = value;
  }

  refreshServicios(value: any): void {
    this.servicios = value;
  }

  refreshUnidades(value: any): void {
    this.unidadesAutAero = value;
  }

  refreshPuertos(value: any): void {
    this.puertosArrastre = value;
  }

  refreshFronteras(value: any): void {
    this.fronterasCruce = value;
  }

  refreshRecursos(value: any): void {
    this.recursosSeguridad = value;
  }

  refreshCE(value: any): void {
    this.certificacionesEmpresa = value;
  }

  refreshCO(value: any): void {
    this.certificadoOperadores = value;
  }

  guardar(): void {
    if(this.giros != null && this.giros.length > 0) {
      let girosNuevos: string[] = [];
      this.giros.map(
        giro => {
          girosNuevos.push(giro.text);
        }
      );
      this.evaluacion.giro = girosNuevos;
    }

    if(this.sociosComerciales != null && this.sociosComerciales.length > 0) {
      let sociosComercialesNuevos: string[] = [];
      this.sociosComerciales.map(
        socio => {
          sociosComercialesNuevos.push(socio.text);
        }
      );
      this.evaluacion.sociosComerciales = sociosComercialesNuevos;
    }

    if(this.coberturasNacional != null && this.coberturasNacional.length > 0) {
      let coberturasNacionalNuevos: string[] = [];
      this.coberturasNacional.map(
        cobertura => {
          coberturasNacionalNuevos.push(cobertura.text);
        }
      );
      this.evaluacion.coberturaNacional = coberturasNacionalNuevos;
    }

    if(this.patiosOperacion != null && this.patiosOperacion.length > 0) {
      let patiosOperacionNuevos: string[] = [];
      this.patiosOperacion.map(
        patio => {
          patiosOperacionNuevos.push(patio.text);
        }
      );
      this.evaluacion.patiosOperacion = patiosOperacionNuevos;
    }

    if(this.codigosHazmat != null && this.codigosHazmat.length > 0) {
      let codigosHazmatNuevos: string[] = [];
      this.codigosHazmat.map(
        codigo => {
          codigosHazmatNuevos.push(codigo.text);
        }
      );
      this.evaluacion.codigosHazmat = codigosHazmatNuevos;
    }

    if(this.servicios != null && this.servicios.length > 0) {
      let serviciosNuevos: string[] = [];
      this.servicios.map(
        servicio => {
          serviciosNuevos.push(servicio.text);
        }
      );
      this.evaluacion.servicios = serviciosNuevos;
    }

    if(this.unidadesAutAero != null && this.unidadesAutAero.length > 0) {
      let unidadesAutAeroNuevos: string[] = [];
      this.unidadesAutAero.map(
        unidad => {
          unidadesAutAeroNuevos.push(unidad.text);
        }
      );
      this.evaluacion.unidadesAutAero = unidadesAutAeroNuevos;
    }

    if(this.puertosArrastre != null && this.puertosArrastre.length > 0) {
      let puertosArrastreNuevos: string[] = [];
      this.puertosArrastre.map(
        puerto => {
          puertosArrastreNuevos.push(puerto.text);
        }
      );
      this.evaluacion.puertosArrastre = puertosArrastreNuevos;
    }

    if(this.fronterasCruce != null && this.fronterasCruce.length > 0) {
      let fronterasCruceNuevos: string[] = [];
      this.fronterasCruce.map(
        frontera => {
          fronterasCruceNuevos.push(frontera.text);
        }
      );
      this.evaluacion.fronterasCruce = fronterasCruceNuevos;
    }

    if(this.recursosSeguridad != null && this.recursosSeguridad.length > 0) {
      let recursosSeguridadNuevos: string[] = [];
      this.recursosSeguridad.map(
        recurso => {
          recursosSeguridadNuevos.push(recurso.text);
        }
      );
      this.evaluacion.recursosSeguridad = recursosSeguridadNuevos;
    }

    if(this.certificacionesEmpresa != null && this.certificacionesEmpresa.length > 0) {
      let certificacionesEmpresaNuevos: string[] = [];
      this.certificacionesEmpresa.map(
        certificado => {
          certificacionesEmpresaNuevos.push(certificado.text);
        }
      );
      this.evaluacion.certificacionesEmpresa = certificacionesEmpresaNuevos;
    }

    if(this.certificadoOperadores != null && this.certificadoOperadores.length > 0) {
      let certificadoOperadoresNuevos: string[] = [];
      this.certificadoOperadores.map(
        certificado => {
          certificadoOperadoresNuevos.push(certificado.text);
        }
      );
      this.evaluacion.certificadoOperadores = certificadoOperadoresNuevos;
    }

    if(this.evaluacion.idEvaluacion == null) {
      this.evaluacionesServices.createEvaluacion(this.evaluacion).subscribe(
        evaluacion => {
          this.evaluacion = evaluacion;
          if(this.idProveedor && this.idProveedor != null) {
            this.proveedoresService.updateEvaluacion(this.idProveedor, evaluacion.idEvaluacion).subscribe(
              () => {
                this.evaluacionProveedorService.saveEmitter.emit(evaluacion);
                swal('Evaluación Creada', 'Evaluación creada con exito', 'success');
                this.cancel();
              }
            );
          } else if(this.idProspecto && this.idProspecto != null) {
            this.prospectosService.updateEvaluacion(this.idProspecto, evaluacion.idEvaluacion).subscribe(
              () => {
                this.evaluacionProveedorService.saveEmitter.emit(evaluacion);
                swal('Evaluación Creada', 'Evaluación creada con exito', 'success');
                this.cancel();
              }
            );
          }
        }
      );
    } else {
      this.evaluacionesServices.updateEvaluacion(this.evaluacion).subscribe(
        evaluacion => {
          this.evaluacion = evaluacion;
          this.evaluacionProveedorService.saveEmitter.emit(evaluacion);
          swal('Evaluación Actualizada', 'Evaluación actualizada con exito', 'success');
          this.cancel();
        }
      );
    }
  }

}
