import { Component, OnInit, Input } from '@angular/core';
import { ServicioInter } from '../../../model/servicio-inter';
import { DetalleReasignarInterService } from './detalle-reasignar-inter.service';
import { ServiciosInterService } from '../../../services/servicios-inter.service';
import { Router } from '@angular/router';
import { Trafico } from '../../../model/trafico';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-reasignar-inter',
  templateUrl: './detalle-reasignar-inter.component.html',
  styleUrls: ['./detalle-reasignar-inter.component.scss']
})
export class DetalleReasignarInterComponent implements OnInit {

  @Input()
  servicio: ServicioInter;
  trafico: Trafico;
  listaTraficos: Trafico[];
  traficoRequerido: boolean = false;

  constructor(
    public detalleReasignarInterService: DetalleReasignarInterService,
    private serviciosInterService: ServiciosInterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.cargarTraficos();
  }

  cancelar(): void {
    this.detalleReasignarInterService.cerraModal();
  }

  private cargarTraficos(): void {
    this.serviciosInterService.listTraficos().subscribe(
      response => {
        this.listaTraficos = response;
      }
    );
  }

  reasignar() {
    if(this.trafico) {
      this.traficoRequerido = false;
      this.serviciosInterService.updateTrafico(this.servicio.idServicio, this.trafico.trafico, this.trafico.nombreTrafico).subscribe(
        servicio => {
          this.router.navigate(['/dashboard/']);
          swal('Servicio reasignado', `Servicio ${servicio.idServicioStr} ha sido reasignado con exito`, 'success');
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.traficoRequerido = true;
      swal('Trafico Requerido', 'Se debe seleccionar un nuevo Trafico', 'warning');
    }
  }

}
