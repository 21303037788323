import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnumsService {

  private urlEndPoint: string = URL_BACKEND + "/enums";

  constructor(
    private http: HttpClient
  ) { }

  listClavesPedimento(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/clavesPedimento');
  }

  listClavesTipoCarga(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/clavesTipoCarga');
  }

  listCodigosTransporteAereo(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/codigosTransporteAereo');
  }

  listConfigsAutotransporte(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/configsAutotransporte');
  }

  listConfigsMaritima(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/configsMaritima');
  }

  listContenedores(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/contenedores');
  }

  listContenedoresMaritimo(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/contenedoresMaritimo');
  }

  listDerechosDePaso(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/derechosDePaso');
  }

  listEstaciones(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/estaciones');
  }

  listEstados(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/estados');
  }

  listIncoterms(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/incoterms');
  }

  listMetodosPago(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/metodosPago');
  }

  listMonedas(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/monedas');
  }

  listNumsAutorizacionNaviero(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/numsAutorizacionNaviero');
  }

  listPaises(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/paises');
  }

  listPartesTransporte(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/partesTransporte');
  }

  listSubsTipoRem(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/subsTipoRem');
  }

  listTiposCarro(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/tiposCarro');
  }

  listTiposDeComprobante(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/tiposDeComprobante');
  }

  listTiposDeServicio(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/tiposDeServicio');
  }

  listTiposDeTrafico(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/tiposDeTrafico');
  }

  listTiposFactor(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/tiposFactor');
  }

  listTiposPermiso(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/tiposPermiso');
  }

  listUsosCfdi(): Observable<string[]>{
    return this.http.get<string[]>(this.urlEndPoint + '/usosCfdi');
  }

}
