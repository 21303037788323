import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CartaPorte } from '../model/cartaporte/carta-porte';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class CartasPorteService {

  private urlEndPoint: string = URL_BACKEND + "/cartaPorte";

  constructor(
    private http: HttpClient
  ) { }

  listCartasPorte(): Observable<CartaPorte[]> {
    return this.http.get<CartaPorte[]>(this.urlEndPoint + '/list');
  }

  pageCartasPorte(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getCartaPorte(idCartaPorte: number): Observable<CartaPorte> {
    return this.http.get<CartaPorte>(this.urlEndPoint + '/' + idCartaPorte).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createCartaPorte(cartaPorte: CartaPorte): Observable<CartaPorte> {
    return this.http.post(this.urlEndPoint, cartaPorte).pipe(
      map(
        (response: any) => response.cartaPorte as CartaPorte
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateCartaPorte(cartaPorte: CartaPorte): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, cartaPorte).pipe(
      map(
        (response: any) => response.cartaPorte as CartaPorte
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateServicioCartaPorte(idServicio: number, idCartaPorte: number, tipoServicio: number): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + '/' + idServicio + '/' + idCartaPorte + '/' + tipoServicio).pipe(
      map(
        (response: any) => response.cartaPorte as CartaPorte
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  uploadXml(archivoXml: File, idCartaPorte): Observable<HttpEvent<{}>>{
    let formData = new FormData();
    formData.append("archivoXml", archivoXml);
    formData.append("idCartaPorte", idCartaPorte);
    const req = new HttpRequest('POST', `${this.urlEndPoint}/uploadXml`, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

}
