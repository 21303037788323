import { Injectable } from '@angular/core';
import { AnioMes } from '../../../../model/anio-mes';
import { TiposCambioService } from '../../../../services/tipos-cambio.service';

@Injectable({
  providedIn: 'root'
})
export class DetalleTipoCambioService {

  modal: boolean = false;
  listaAniosMeses: AnioMes[];
  aniosMesesTemp: AnioMes[];

  constructor(
    private tipoCambioService: TiposCambioService,
  ) { }

  abrirModal() {
    this.modal = true;
  }

  cerraModal() {
    this.modal = false;
  }

  cargaAniosMeses(): void {
    this.tipoCambioService.listAniosMeses().subscribe(
      response => {
        this.listaAniosMeses = response;
        this.aniosMesesTemp = response;
      }
    );
  }

}
