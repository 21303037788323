import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { EstatusServicio } from '../model/estatus-servicio';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class EstatusServicioService {

  private urlEndPoint: string = URL_BACKEND + "/estatusServicio";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listEstatusServicio(): Observable<EstatusServicio[]> {
    return this.http.get<EstatusServicio[]>(this.urlEndPoint + '/list');
  }

  pageEstatusServicio(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getEstatusServicio(idEstatusServicio: number): Observable<EstatusServicio> {
    return this.http.get<EstatusServicio>(this.urlEndPoint + '/' + idEstatusServicio).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createEstatusServicio(estatusServicio: EstatusServicio): Observable<EstatusServicio> {
    return this.http.post(this.urlEndPoint, estatusServicio).pipe(
      map(
        (response: any) => response.estatusServicio as EstatusServicio
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateEstatusServicio(estatusServicio: EstatusServicio): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, estatusServicio).pipe(
      map(
        (response: any) => response.estatusServicio as EstatusServicio
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }
  
}
