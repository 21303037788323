import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { TipoEquipo } from '../model/tipo-equipo';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class TiposEquipoService {

  private urlEndPoint: string = URL_BACKEND + "/tipoEquipo";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listTiposEquipo(): Observable<TipoEquipo[]> {
    return this.http.get<TipoEquipo[]>(this.urlEndPoint + '/list');
  }

  pageTiposEquipo(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getTipoEquipo(idTipoEquipo: number): Observable<TipoEquipo> {
    return this.http.get<TipoEquipo>(this.urlEndPoint + '/' + idTipoEquipo).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createTipoEquipo(tipoEquipo: TipoEquipo): Observable<TipoEquipo> {
    return this.http.post(this.urlEndPoint, tipoEquipo).pipe(
      map(
        (response: any) => response.tipoEquipo as TipoEquipo
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateTipoEquipo(tipoEquipo: TipoEquipo): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, tipoEquipo).pipe(
      map(
        (response: any) => response.tipoEquipo as TipoEquipo
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }
  
}
