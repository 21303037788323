import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Evaluacion } from '../model/evaluacion';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {

  private urlEndPoint: string = URL_BACKEND + "/evaluacion";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listEvaluaciones(): Observable<Evaluacion[]> {
    return this.http.get<Evaluacion[]>(this.urlEndPoint + '/list');
  }

  pageEvaluaciones(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getEvaluacion(idEvaluacion: number): Observable<Evaluacion> {
    return this.http.get<Evaluacion>(this.urlEndPoint + '/' + idEvaluacion).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createEvaluacion(evaluacion: Evaluacion): Observable<Evaluacion> {
    return this.http.post(this.urlEndPoint, evaluacion).pipe(
      map(
        (response: any) => response.evaluacion as Evaluacion
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateEvaluacion(evaluacion: Evaluacion): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, evaluacion).pipe(
      map(
        (response: any) => response.evaluacion as Evaluacion
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
