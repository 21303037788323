import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { EstatusServicioService } from '../../services/estatus-servicio.service';
import { ClientesService } from '../../services/clientes.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { AuthService } from '../../auth/auth.service';
import { ServiciosService } from '../../services/servicios.service';
import { NgxSpinnerService } from "ngx-spinner";
import { EstatusServicio } from '../../model/estatus-servicio';
import { Cliente } from '../../model/cliente';
import { Proveedor } from '../../model/proveedor';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { Servicio } from '../../model/servicio';
import { ArchivoBox } from '../../model/archivo-box';

@Component({
  selector: 'app-consulta-terrestre',
  templateUrl: './consulta-terrestre.component.html',
  styleUrls: ['./consulta-terrestre.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsultaTerrestreComponent implements OnInit {

  numeroServicio: string;
  pagador: Cliente;
  pagadorCtrl: FormControl = new FormControl();
  pagadorFilterCtrl: FormControl = new FormControl();
  pagadoresFiltrados: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  estatusServicio: EstatusServicio;
  listaEstatusServicio: EstatusServicio[];
  proveedor: Proveedor;
  proveedorCtrl: FormControl = new FormControl();
  proveedorFilterCtrl: FormControl = new FormControl();
  proveedoresFiltrados: ReplaySubject<Proveedor[]> = new ReplaySubject<Proveedor[]>(1);
  fechaDesde: NgbDateStruct;
  fechaHasta: NgbDateStruct;
  listaServicios: Servicio[];
  searching = false;
  _onDestroy = new Subject<void>();
  trafico: string;
  archivoPod: ArchivoBox;

  constructor(
    public pt: PagetitleService,
    public authService: AuthService,
    private estatusServicioService: EstatusServicioService,
    private clientesService: ClientesService,
    private proveedoresService: ProveedoresService,
    private serviciosService: ServiciosService,
    private router: Router,
    private spinnerService: NgxSpinnerService
  ) {
    this.trafico = this.authService.account.localAccountId;
  }

  ngOnInit() {
    this.cargarEstatusServicios();
    this.filtrarClientes();
    this.filtrarProveedores();
    this.buscarInicio();
  }

  private cargarEstatusServicios(): void {
    this.estatusServicioService.listEstatusServicio().subscribe(
      response => {
        this.listaEstatusServicio = response;

      }
    )
  }

  private filtrarClientes(): void {
    this.pagadorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        search => {
          this.clientesService.listFirstClientesByBusqueda(search).subscribe(
            clientesFiltrados => {
              this.searching = false;
              if (this.pagadoresFiltrados == null) {
                this.pagadoresFiltrados = new ReplaySubject<Cliente[]>(1);
              }
              this.pagadoresFiltrados.next(clientesFiltrados);
            }
          );
        }
      );
  }

  private filtrarProveedores(): void {
    this.proveedorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(search => {
        this.proveedoresService.listFirstProveedoresByRazonComercial(search).subscribe(
          proveedoresFiltrados => {
            this.searching = false;
            if (this.proveedoresFiltrados == null) {
              this.proveedoresFiltrados = new ReplaySubject<Proveedor[]>(1);
            }
            this.proveedoresFiltrados.next(proveedoresFiltrados);
          }
        );
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
  }

  limpiar(): void {
    this.numeroServicio = undefined;
    this.pagador = undefined;
    this.estatusServicio = undefined;
    this.proveedor = undefined;
    this.fechaDesde = undefined;
    this.fechaHasta = undefined;
  }

  buscar(): void {
    let numeroServicio = null;
    let idPagador = 0;
    let idEstatus = 0;
    let idProveedor = 0;
    let fechaDesde = null;
    let fechaHasta = null;

    if (this.numeroServicio) {
      numeroServicio = this.numeroServicio;
    }
    if (this.pagador) {
      idPagador = this.pagador.idCliente;
    }
    if (this.estatusServicio) {
      idEstatus = this.estatusServicio.idEstatusServicio;
    }
    if (this.proveedor) {
      idProveedor = this.proveedor.idProveedor;
    }
    if (this.fechaDesde) {
      fechaDesde = this.fechaDesde.year + '/' +
        (this.fechaDesde.month < 10 ? '0' + this.fechaDesde.month : this.fechaDesde.month) + '/' +
        (this.fechaDesde.day < 10 ? '0' + this.fechaDesde.day : this.fechaDesde.day);
    }
    if (this.fechaHasta) {
      fechaHasta = this.fechaHasta.year + '/' +
        (this.fechaHasta.month < 10 ? '0' + this.fechaHasta.month : this.fechaHasta.month) + '/' +
        (this.fechaHasta.day < 10 ? '0' + this.fechaHasta.day : this.fechaHasta.day);
    }
    this.spinnerService.show();
    this.serviciosService.searchServicio(numeroServicio, idEstatus, this.trafico, idPagador,
      idProveedor, null, fechaDesde, fechaHasta, null, this.authService.rolName).subscribe(
        response => {
          this.listaServicios = response;
          this.spinnerService.hide();
          //console.log("2");
          }
      );
  }

  buscarInicio(): void {
    let numeroServicio = null;
    let idPagador = 0;
    let idEstatus = 0;
    let idProveedor = 0;
    let fechaDesde = null;
    let fechaHasta = null;
    /*
    this.estatusServicio = new EstatusServicio();
    this.estatusServicio.idEstatusServicio = 1;
    this.estatusServicio.descripcion = "En tránsito";*/
    idEstatus = 1;

    this.serviciosService.searchServicio(numeroServicio, idEstatus, this.trafico, idPagador,
      idProveedor, null, fechaDesde, fechaHasta, null, this.authService.rolName).subscribe(
        response => {
          this.listaServicios = response;
          //this.spinnerService.hide();
          //console.log("2");
          }
      );
  }

  detalles(servicio: Servicio) {
    this.router.navigate(['/servicio/' + servicio.idServicio]);
  }
/*
  downloadArchivoPod(): void {
		window.open(this.archivoPod.url, '_blank', '', true);
	}
*/
}
