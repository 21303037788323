import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Servicio } from '../../../model/servicio';
import { ServicioInter } from '../../../model/servicio-inter';
import { ServiciosService } from '../../../services/servicios.service';
import { ServiciosInterService } from '../../../services/servicios-inter.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'header-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {

  @ViewChild('searchModal', { static: true }) public searchModal: ModalDirective;
  filtro: string;
  listaServicios: Servicio[] = [];
  listaServiciosInter: ServicioInter[] = [];

  constructor(
    private element: ElementRef,
    private serviciosService: ServiciosService,
    private serviciosInterService: ServiciosInterService,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit() { }

  onModalShown() {
    let input = this.element.nativeElement.querySelector('.header-input-search')
    if (input) input.focus();
  }

  filtrar(event) {
    if (event.key == 'Enter') {
      this.filtro = event.target.value;

      if (this.filtro != null && this.filtro != '') {
        this.listaServicios = [];
        this.listaServiciosInter = [];

        var idUsuarioBuscar = null;
        var rolBuscar = null;

        if (!this.authService.hasRole('ROLE_SUPERADMIN') && !this.authService.hasRole('ROLE_CUENTAS_PAGAR') && !this.authService.hasRole('ROLE_ANALISTA')
          && !this.authService.hasRole('ROLE_CUENTAS_COBRAR')) {
          idUsuarioBuscar = this.authService.account.localAccountId;
            rolBuscar = this.authService.rolName;
        }

        if (this.authService.hasRole('ROLE_TRAFICO_TERRESTRE') || this.authService.hasRole('ROLE_SUPERADMIN') || this.authService.hasRole('ROLE_CUENTAS_PAGAR')
          || this.authService.hasRole('ROLE_ANALISTA') || this.authService.hasRole('ROLE_CUENTAS_COBRAR') || this.authService.hasRole('ROLE_EJECUTIVO')) {
          this.serviciosService.searchServicio(this.filtro, 0, idUsuarioBuscar, 0, 0, null, null, null, null,this.authService.rolName).subscribe(
            response => {
              console.log(response);
              this.listaServicios = response;
            }
          );
        }

        if (this.authService.hasRole('ROLE_TRAFICO_AEREO_EXPO') || this.authService.hasRole('ROLE_TRAFICO_AEREO_IMPO') || this.authService.hasRole('ROLE_SUPERADMIN')
          || this.authService.hasRole('ROLE_CUENTAS_PAGAR') || this.authService.hasRole('ROLE_ANALISTA') || this.authService.hasRole('ROLE_CUENTAS_COBRAR')
          || this.authService.hasRole('ROLE_EJECUTIVO')) {

          this.serviciosInterService.searchServicio(this.filtro, idUsuarioBuscar, null, null, 0, 0, null, null,rolBuscar,false).subscribe(
            response => {
              console.log(response);
              this.listaServiciosInter = response;
            }
          );
        }
      } else {
        this.listaServicios = [];
        this.listaServiciosInter = [];
      }
    }
  }

  verServicio(servicio: Servicio) {
    this.limpiaFiltro();
    this.router.navigate(['/servicio/' + servicio.idServicio]);
  }

  verServicioInter(servicioInter: ServicioInter) {
    this.limpiaFiltro();
    this.router.navigate(['/servicioInter/' + servicioInter.idServicio]);
  }

  limpiaFiltro(): void {
    this.filtro = '';
    this.listaServicios = [];
    this.listaServiciosInter = [];
  }

}
