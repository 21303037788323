import { Component, OnInit, Input } from '@angular/core';
import { Aeropuerto } from '../../../../model/aeropuerto';
import { DetalleAeropuertoService } from './detalle-aeropuerto.service';
import { AeropuertosService } from '../../../../services/aeropuertos.service';
import { PaisesService } from '../../../../services/paises.service';
import { Pais } from '../../../../model/pais';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-aeropuerto',
  templateUrl: './detalle-aeropuerto.component.html',
  styleUrls: ['./detalle-aeropuerto.component.scss']
})
export class DetalleAeropuertoComponent implements OnInit {

  @Input()
  aeropuerto: Aeropuerto;

  listaPaises: Pais[];

  constructor(
    public detalleService: DetalleAeropuertoService,
    private aeropuertosService: AeropuertosService,
    private paisesService: PaisesService
  ) { }

  ngOnInit() {
    this.cargaPaises();
  }

  private cargaPaises(): void {
    this.paisesService.listPaises().subscribe(
      response => {
        this.listaPaises = response;
      }
    );
  }

  cancelar(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.aeropuertosService.createAeropuerto(this.aeropuerto).subscribe(
      aeropuerto => {
        this.detalleService.cerraModal();
        swal('Aeropuerto Creado', `Aeropuerto ${aeropuerto.nombre} creado con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.aeropuertosService.updateAeropuerto(this.aeropuerto).subscribe(
      aeropuerto => {
        this.detalleService.cerraModal();
        swal('Aeropuerto Actualizado', `Aeropuerto ${aeropuerto.nombre} actualizado con exito`, 'success');
      }
    );
  }

  compararPais(o1: Pais, o2: Pais) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idPais === o2.idPais;
  }

}
