import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Aerolinea } from '../../../model/aerolinea';
import { AerolineasService } from '../../../services/aerolineas.service';
import { DetalleAerolineaService } from './detalle-aerolinea/detalle-aerolinea.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'app-aerolineas',
  templateUrl: './aerolineas.component.html',
  styleUrls: ['./aerolineas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AerolineasComponent implements OnInit {
  public listaAerolineas: Aerolinea[];
  public aerolineasTemp: Aerolinea[];
  public aerolineaSeleccionada: Aerolinea;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private aerolineasService: AerolineasService,
    private detalleService: DetalleAerolineaService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Aerolíneas');
  }

  ngOnInit() {
    this.aerolineaSeleccionada = null;
    this.cargaAerolineas();
  }

  private cargaAerolineas(): void {
    this.aerolineasService.listAerolineas().subscribe(
      response => {
        this.listaAerolineas = response;
        this.aerolineasTemp = response;
      }
    );
  }

  agregar(): void {
    this.aerolineaSeleccionada = new Aerolinea();
    this.detalleService.abrirModal();
  }

  editar(aerolinea: Aerolinea): void {
    this.aerolineaSeleccionada = aerolinea;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.aerolineasTemp.filter(function(d) {
          return d.idAerolinea == val || d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaAerolineas = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
