import { Mpc } from './mpc';
import { Agente } from './agente';
import { Shipper } from './shipper';
import { Consignatario } from './consignatario';
import { Cliente } from './cliente';
import { Aerolinea } from './aerolinea';
import { Aeropuerto } from './aeropuerto';
import { Incoterm } from './incoterm';
import { CargoOrigen } from './cargo-origen';
import { CargoDestino } from './cargo-destino';
import { EstatusServicio } from './estatus-servicio';
import { CartaPorte } from './cartaporte/carta-porte';
import { BitacoraSap } from './bitacora-sap';

export class ServicioInter {
  idServicio: number;
  idServicioStr: string;
  anio: number;
  consecutivo: number;
  borrador: boolean;
  fechaServicio: Date;
  mpc: Mpc;
  tipo: string;
  subtipo: string;
  shipper: Shipper;
  consignatario: Consignatario;
  cliente: Cliente;
  pagador: Cliente;
  trafico: string;
  nombreTrafico: string;
  cargosOrigen: CargoOrigen[];
  cargosDestino: CargoDestino[];
  estatus: EstatusServicio;
  fechaSalida: Date;
  sFechaSalida: string;
  fechaArribo1: Date;
  sFechaArribo1: string;
  fechaArribo2: Date;
  sFechaArribo2: string;
  fechaArribo3: Date;
  sFechaArribo3: string;
  fechaDocumentacion: Date;
  sFechaDocumentacion: string;
  ghPrefijoAgente: Agente;
  ghNumGuia: string;
  gmPrefijoAerolinea: Aerolinea;
  gmNumGuia: string;
  aeropuertoOrigen: Aeropuerto;
  aeropuertoDestino: Aeropuerto;
  incotermOrigen: Incoterm;
  incotermDestino: Incoterm;
  numPiezas: number;
  pesoBruto: number;
  pesoCargable: number;
  notas: string;
  motivoCancelacion: string;
  notasCompletas: boolean;
  noFacturaCliente: string;
  refFacturacion: string;
  totalprofitCargosOrigen: number;
  totalprofitCargosDestino: number;
  totalprofit: number;
  fechaFactura: Date;
  ordenCompra: string;
  cartaPorte: CartaPorte;
  idChat: string;
  bitacoras: BitacoraSap[];
  lcl: string;
  contenedor: string;
}
