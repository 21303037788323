import { Component, OnInit, Input } from '@angular/core';
import { CancelarServicioService } from './cancelar-servicio.service';
import { ServiciosService } from '../../../services/servicios.service';
import { Router } from '@angular/router';

const swal = require('sweetalert');

@Component({
  selector: 'cancelar-servicio',
  templateUrl: './cancelar-servicio.component.html',
  styleUrls: ['./cancelar-servicio.component.scss']
})
export class CancelarServicioComponent implements OnInit {

  @Input()
  idServicio: number;

  motivoCancelacion: string;
  motivoRequerido: boolean;

  constructor(
    public cancelarServicioService: CancelarServicioService,
    private serviciosService: ServiciosService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  cancelar(): void {
    this.cancelarServicioService.cerraModal();
  }

  confirmar() {
    if(this.motivoCancelacion && this.motivoCancelacion != null) {
      this.motivoRequerido = false;
      this.serviciosService.cancelarServicio(this.idServicio, this.motivoCancelacion).subscribe(
        servicio => {
          this.router.navigate(['/dashboard/']);
          swal('Servicio cancelado', `Servicio ${servicio.idServicioStr} cancelado con exito`, 'success');
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.motivoRequerido = true;
    }
  }

}
