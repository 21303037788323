import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Ubicacion } from '../model/ubicacion';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class UbicacionesService {

  private urlEndPoint: string = URL_BACKEND + "/ubicacion";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listUbicaciones(): Observable<Ubicacion[]> {
    return this.http.get<Ubicacion[]>(this.urlEndPoint + '/list');
  }

  listFirstUbicacionesByCiudad(ciudad: string): Observable<Ubicacion[]> {
    return this.http.get<Ubicacion[]>(this.urlEndPoint + '/search?ciudad=' + ciudad);
  }

  listFirstUbicacionesByCiudadAndPais(ciudad: string, pais: string): Observable<Ubicacion[]> {
    return this.http.get<Ubicacion[]>(this.urlEndPoint + '/search?ciudad=' + ciudad + '&pais=' + pais);
  }

  pageUbicaciones(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getUbicacion(idUbicacion: number): Observable<Ubicacion> {
    return this.http.get<Ubicacion>(this.urlEndPoint + '/' + idUbicacion).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createUbicacion(ubicacion: Ubicacion): Observable<Ubicacion> {
    return this.http.post(this.urlEndPoint, ubicacion).pipe(
      map(
        (response: any) => response.ubicacion as Ubicacion
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateUbicacion(ubicacion: Ubicacion): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, ubicacion).pipe(
      map(
        (response: any) => response.ubicacion as Ubicacion
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
