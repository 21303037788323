import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PreferenciaExport } from '../model/preferencia-export';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class PreferenciasExportService {

  private urlEndPoint: string = URL_BACKEND + "/preferenciaExport";

  constructor(
    private http: HttpClient
  ) { }

  listPreferenciasExport(): Observable<PreferenciaExport[]> {
    return this.http.get<PreferenciaExport[]>(this.urlEndPoint + '/list');
  }

  pagePreferenciasExport(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getPreferenciaExport(idPreferencia: number): Observable<PreferenciaExport> {
    return this.http.get<PreferenciaExport>(this.urlEndPoint + '/' + idPreferencia).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  getPreferenciaExportByUsuario(idUsuario: number, tipo: string): Observable<PreferenciaExport> {
    return this.http.get<PreferenciaExport>(this.urlEndPoint + '/usuario/' + idUsuario + '/' + tipo).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createPreferenciaExport(preferenciaExport: PreferenciaExport): Observable<PreferenciaExport> {
    return this.http.post(this.urlEndPoint, preferenciaExport).pipe(
      map(
        (response: any) => response.preferenciaExport as PreferenciaExport
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updatePreferenciaExport(preferenciaExport: PreferenciaExport): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, preferenciaExport).pipe(
      map(
        (response: any) => response.preferenciaExport as PreferenciaExport
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
