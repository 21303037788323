import { Component, OnInit, Input } from '@angular/core';
import { Aerolinea } from '../../../../model/aerolinea';
import { DetalleAerolineaService } from './detalle-aerolinea.service';
import { AerolineasService } from '../../../../services/aerolineas.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-aerolinea',
  templateUrl: './detalle-aerolinea.component.html',
  styleUrls: ['./detalle-aerolinea.component.scss']
})
export class DetalleAerolineaComponent implements OnInit {

  @Input()
  aerolinea: Aerolinea;

  constructor(
    public detalleService: DetalleAerolineaService,
    private aerolineasService: AerolineasService
  ) { }

  ngOnInit() {
  }

  cancelar(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.aerolineasService.createAerolinea(this.aerolinea).subscribe(
      aerolinea => {
        this.detalleService.cerraModal();
        swal('Aerolínea Creada', `Aerolínea ${aerolinea.nombre} creada con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.aerolineasService.updateAerolinea(this.aerolinea).subscribe(
      aerolinea => {
        this.detalleService.cerraModal();
        swal('Aerolínea Actualizada', `Aerolínea ${aerolinea.nombre} actualizada con exito`, 'success');
      }
    );
  }

}
