import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Proveedor } from '../model/proveedor';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {

  private urlEndPoint: string = URL_BACKEND + "/proveedor";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listProveedores(): Observable<Proveedor[]> {
    return this.http.get<Proveedor[]>(this.urlEndPoint + '/list');
  }

  listFirstProveedoresByRazonComercial(razonComercial: string): Observable<Proveedor[]> {
    return this.http.get<Proveedor[]>(this.urlEndPoint + '/search?razonComercial=' + razonComercial);
  }

  pageProveedores(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getProveedor(idProveedor: number): Observable<Proveedor> {
    return this.http.get<Proveedor>(this.urlEndPoint + '/' + idProveedor).pipe(
      catchError(e => {
        if (e.status != 401) {
          this.router.navigate(['/proveedores']);
        }
        return throwError(e);
      })
    );
  }

  createProveedor(proveedor: Proveedor): Observable<Proveedor> {
    return this.http.post(this.urlEndPoint, proveedor).pipe(
      map(
        (response: any) => response.proveedor as Proveedor
      ),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateProveedor(proveedor: Proveedor): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, proveedor).pipe(
      map(
        (response: any) => response.proveedor as Proveedor
      ),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateEvaluacion(idProveedor: number, idEvaluacion: number) {
    return this.http.get(this.urlEndPoint + '/' + idProveedor + '/evaluacion/' + idEvaluacion).pipe(
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  searchProveedor(giros: string[], sociosComerciales: string[], coberturas: string[], patios: string[],
    codigosHazmat: string[], servicios: string[], unidadesAutAero: string[], puertos: string[],
    fronteras: string[], recursos: string[], certificacionesEmpresa: string[],
    certificacionesOperador: string[], equipos: string[]): Observable<Proveedor[]> {
    let filtros = '';
    if(giros.length > 0) {
      filtros += '&giros=' + giros + '&';
    }
    if(sociosComerciales.length > 0) {
      filtros += '&sociosComerciales=' + sociosComerciales + '&';
    }
    if(coberturas.length > 0) {
      filtros += '&coberturas=' + coberturas + '&';
    }
    if(patios.length > 0) {
      filtros += '&patios=' + patios + '&';
    }
    if(codigosHazmat.length > 0) {
      filtros += '&codigosHazmat=' + codigosHazmat + '&';
    }
    if(servicios.length > 0) {
      filtros += '&servicios=' + servicios + '&';
    }
    if(unidadesAutAero.length > 0) {
      filtros += '&unidadesAutAero=' + unidadesAutAero + '&';
    }
    if(puertos.length > 0) {
      filtros += '&puertos=' + puertos + '&';
    }
    if(fronteras.length > 0) {
      filtros += '&fronteras=' + fronteras + '&';
    }
    if(recursos.length > 0) {
      filtros += '&recursos=' + recursos + '&';
    }
    if(certificacionesEmpresa.length > 0) {
      filtros += '&certificacionesEmpresa=' + certificacionesEmpresa + '&';
    }
    if(certificacionesOperador.length > 0) {
      filtros += '&certificacionesOperador=' + certificacionesOperador + '&';
    }
    if(equipos.length > 0) {
      filtros += '&equipos=' + equipos + '&';
    }
    if(filtros.endsWith('&')) {
      filtros = filtros.substring(0, filtros.length - 1);
    }

    return this.http.get<Proveedor[]>(this.urlEndPoint + '/search/evaluacion?' + filtros).pipe(
      catchError(e => {
        if (e.status != 401) {
          this.router.navigate(['/busquedaProveedor']);
        }
        return throwError(e);
      })
    );
  }

  searchProveedorReporte(giros: string[], sociosComerciales: string[], coberturas: string[], patios: string[],
    codigosHazmat: string[], servicios: string[], unidadesAutAero: string[], puertos: string[],
    fronteras: string[], recursos: string[], certificacionesEmpresa: string[],
    certificacionesOperador: string[], equipos: string[]): Observable<Blob> {
    let filtros = '';
    if(giros.length > 0) {
      filtros += '&giros=' + giros + '&';
    }
    if(sociosComerciales.length > 0) {
      filtros += '&sociosComerciales=' + sociosComerciales + '&';
    }
    if(coberturas.length > 0) {
      filtros += '&coberturas=' + coberturas + '&';
    }
    if(patios.length > 0) {
      filtros += '&patios=' + patios + '&';
    }
    if(codigosHazmat.length > 0) {
      filtros += '&codigosHazmat=' + codigosHazmat + '&';
    }
    if(servicios.length > 0) {
      filtros += '&servicios=' + servicios + '&';
    }
    if(unidadesAutAero.length > 0) {
      filtros += '&unidadesAutAero=' + unidadesAutAero + '&';
    }
    if(puertos.length > 0) {
      filtros += '&puertos=' + puertos + '&';
    }
    if(fronteras.length > 0) {
      filtros += '&fronteras=' + fronteras + '&';
    }
    if(recursos.length > 0) {
      filtros += '&recursos=' + recursos + '&';
    }
    if(certificacionesEmpresa.length > 0) {
      filtros += '&certificacionesEmpresa=' + certificacionesEmpresa + '&';
    }
    if(certificacionesOperador.length > 0) {
      filtros += '&certificacionesOperador=' + certificacionesOperador + '&';
    }
    if(equipos.length > 0) {
      filtros += '&equipos=' + equipos + '&';
    }
    if(filtros.endsWith('&')) {
      filtros = filtros.substring(0, filtros.length - 1);
    }

    return this.http.get(this.urlEndPoint + '/searchProveedorReport?' + filtros, {
       responseType: 'blob'
    });
  }

}
