import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Servicio } from '../model/servicio';
import { map, catchError } from 'rxjs/operators';
import { Trafico } from '../model/trafico';
import { ServicioPorCliente } from '../model/servicio-cliente';
import { CargoPorServicio } from '../model/cargo-servicio';
import { Dashboard } from '../model/dashboard';
import { DashboardBar } from '../model/dashboard-bar';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  private urlEndPoint: string = URL_BACKEND + "/servicio";

  constructor(
    private http: HttpClient
  ) { }

  listServicios(): Observable<Servicio[]> {
    return this.http.get<Servicio[]>(this.urlEndPoint + '/list');
  }

  pageServicios(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getServicio(idServicio: number): Observable<Servicio> {
    return this.http.get<Servicio>(this.urlEndPoint + '/' + idServicio).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  searchServicio(numeroServicio: string, idEstatus: number, idTrafico: string, idCliente: number,
    idProveedor: number, numeroFactura: string, fechaDesde: string,
    fechaHasta: string, borradores: string, rolId: string): Observable<Servicio[]> {
      let filtros = '';
      if(numeroServicio && numeroServicio != null) {
        numeroServicio = numeroServicio.replace('&', '(amp)');
        filtros += 'numeroServicio=' + numeroServicio + '&';
      }
      if(idEstatus > 0) {
        filtros += 'idEstatus=' + idEstatus + '&';
      }
      if(idTrafico && idTrafico != null) {
        filtros += 'idTrafico=' + idTrafico + '&';
      }
      if(idCliente > 0) {
        filtros += 'idCliente=' + idCliente + '&';
      }
      if(idProveedor > 0) {
        filtros += 'idProveedor=' + idProveedor + '&';
      }
      if(numeroFactura && numeroFactura != null) {
        filtros += 'numeroFactura=' + numeroFactura + '&';
      }
      if(fechaDesde && fechaDesde != null) {
        filtros += 'fechaDesde=' + fechaDesde + '&';
      }
      if(fechaHasta && fechaHasta != null) {
        filtros += '&fechaHasta=' + fechaHasta + '&';
      }
      if(borradores && borradores != null) {
        filtros += '&borradores=' + borradores + '&';
      }
      if(rolId && rolId != null) {
        filtros += '&rolId=' + rolId + '&';
      }
      if(filtros.endsWith('&')) {
        filtros = filtros.substring(0, filtros.length - 1);
      }

      return this.http.get<Servicio[]>(this.urlEndPoint + '/search?' + filtros).pipe(
        catchError(e => {
          swal(e.error.mensaje, e.error.error, 'error');
          return throwError(e);
        })
      );
  }

  searchServicioReporte(numeroServicio: string, idEstatus: number, idTrafico: string, idCliente: number,
    idProveedor: number, numeroFactura: string, fechaDesde: string,
    fechaHasta: string, borradores: string, rolId: string): Observable<Blob> {
      let filtros = '';
      if(numeroServicio && numeroServicio != null) {
        filtros += 'numeroServicio=' + numeroServicio + '&';
      }
      if(idEstatus > 0) {
        filtros += 'idEstatus=' + idEstatus + '&';
      }
      if(idTrafico && idTrafico != null) {
        filtros += 'idTrafico=' + idTrafico + '&';
      }
      if(idCliente > 0) {
        filtros += 'idCliente=' + idCliente + '&';
      }
      if(idProveedor > 0) {
        filtros += 'idProveedor=' + idProveedor + '&';
      }
      if(numeroFactura && numeroFactura != null) {
        filtros += 'numeroFactura=' + numeroFactura + '&';
      }
      if(fechaDesde && fechaDesde != null) {
        filtros += 'fechaDesde=' + fechaDesde + '&';
      }
      if(fechaHasta && fechaHasta != null) {
        filtros += '&fechaHasta=' + fechaHasta + '&';
      }
      if(borradores && borradores != null) {
        filtros += '&borradores=' + borradores + '&';
      }
      if(rolId && rolId != null) {
        filtros += '&rolId=' + rolId + '&';
      }
      if(filtros.endsWith('&')) {
        filtros = filtros.substring(0, filtros.length - 1);
      }

      return this.http.get(this.urlEndPoint + '/searchReport?' + filtros, {
         responseType: 'blob'
      });
  }

  createServicio(servicio: Servicio): Observable<Servicio> {
    return this.http.post(this.urlEndPoint, servicio).pipe(
      map(
        (response: any) => response.servicio as Servicio
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateServicio(servicio: Servicio): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, servicio).pipe(
      map(
        (response: any) => response.servicio as Servicio
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  cancelarServicio(idServicio: number, motivo: string): Observable<any> {
    return this.http.delete<any>(this.urlEndPoint + '/cancel/' + idServicio + '/' + motivo).pipe(
      map(
        (response: any) => response.servicio as Servicio
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  uploadPod(archivo: File, idServicio): Observable<HttpEvent<{}>>{
    let formData = new FormData();
    formData.append("archivo", archivo);
    if(idServicio && idServicio > 0) {
      formData.append("idServicio", idServicio);
    }

    const req = new HttpRequest('POST', `${this.urlEndPoint}/uploadPod`, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  listTraficos(): Observable<Trafico[]> {
    return this.http.get<Trafico[]>(this.urlEndPoint + '/traficos').pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateRuta(idServicio: number, ruta: ServicioPorCliente): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/ruta/update/' + idServicio, ruta).pipe(
      map(
        (response: any) => response.ruta as ServicioPorCliente
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateCargo(idServicio: number, cargo: CargoPorServicio): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/cargo/update/' + idServicio, cargo).pipe(
      map(
        (response: any) => response.cargo as CargoPorServicio
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  deleteCargo(idServicio: number, cargo: CargoPorServicio): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/cargo/delete/' + idServicio, cargo).pipe(
      map(
        (response: any) => response.cargo as CargoPorServicio
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateTrafico(idServicio: number, idTrafico: string, nombreTrafico: string): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + '/trafico/' + idServicio + '/' + idTrafico + '/' + nombreTrafico).pipe(
      map(
        (response: any) => response.servicio as Servicio
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  getDashboard(idTrafico: string, idRol: string): Observable<Dashboard> {
    return this.http.get<Dashboard>(this.urlEndPoint + '/dashboardData?idTrafico=' + idTrafico + '&rolId=' + idRol).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  getDashboardBar(idTrafico: string, idRol: string): Observable<DashboardBar[]> {
    return this.http.get<DashboardBar[]>(this.urlEndPoint + '/dashboardBarData?idTrafico=' + idTrafico + '&rolId=' + idRol).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

}
