import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CargoAereo } from '../model/cargo-aereo';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class CargosAereosService {

  private urlEndPoint: string = URL_BACKEND + "/cargoAereo";

  constructor(
    private http: HttpClient
  ) { }

  listCargosAereos(): Observable<CargoAereo[]> {
    return this.http.get<CargoAereo[]>(this.urlEndPoint + '/list');
  }

  pageCargosAereos(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getCargoAereo(idCargoAereo: number): Observable<CargoAereo> {
    return this.http.get<CargoAereo>(this.urlEndPoint + '/' + idCargoAereo).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createCargoAereo(cargoAereo: CargoAereo): Observable<CargoAereo> {
    return this.http.post(this.urlEndPoint, cargoAereo).pipe(
      map(
        (response: any) => response.cargoAereo as CargoAereo
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateCargoAereo(cargoAereo: CargoAereo): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, cargoAereo).pipe(
      map(
        (response: any) => response.cargoAereo as CargoAereo
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }
  
}
