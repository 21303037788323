import { Mpc } from './mpc';
import { ServicioPorCliente } from './servicio-cliente';
import { Cliente } from './cliente';
import { CargoPorServicio } from './cargo-servicio';
import { EstatusServicio } from './estatus-servicio';
import { Factura } from './factura';
import { CartaPorte } from './cartaporte/carta-porte';
import { BitacoraSap } from './bitacora-sap';

export class Servicio {
  idServicio: number;
  idServicioStr: string;
  anio: number;
  consecutivo: number;
  borrador: boolean;
  fechaServicio: Date;
  fechaCierre: Date;
  mpc: Mpc;
  serviciosPorCliente: ServicioPorCliente[];
  cliente: Cliente;
  pagador: Cliente;
  cargosPorServicio: CargoPorServicio[];
  trafico: string;
  nombreTrafico: string;
  estatus: EstatusServicio;
  costoServicioUsd: number;
  ventaServicioUsd: number;
  costoCargoUsd: number;
  ventaCargoUsd: number;
  notas: string;
  motivoCancelacion: string;
  notasCompletas: boolean;
  noFacturaCliente: string;
  facturas: Factura[];
  estatusBox: string;
  estatusSat: string;
  ordenCompra: string;
  deCrm: boolean;
  ofertaCrm: string;
  cartaPorte: CartaPorte;
  bitacoras: BitacoraSap[];
  idChat: string;
}
