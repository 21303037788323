import { Component, OnInit, Input } from '@angular/core';
import { CancelarServicioInterService } from './cancelar-servicio-inter.service';
import { ServiciosInterService } from '../../../services/servicios-inter.service';
import { Router } from '@angular/router';

const swal = require('sweetalert');

@Component({
  selector: 'cancelar-servicio-inter',
  templateUrl: './cancelar-servicio-inter.component.html',
  styleUrls: ['./cancelar-servicio-inter.component.scss']
})
export class CancelarServicioInterComponent implements OnInit {

  @Input()
  idServicio: number;

  motivoCancelacion: string;
  motivoRequerido: boolean;

  constructor(
    public cancelarServicioInterService: CancelarServicioInterService,
    private serviciosInterService: ServiciosInterService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  cancelar(): void {
    this.cancelarServicioInterService.cerraModal();
  }

  confirmar() {
    if(this.motivoCancelacion && this.motivoCancelacion != null) {
      this.motivoRequerido = false;
      this.serviciosInterService.cancelarServicio(this.idServicio, this.motivoCancelacion).subscribe(
        servicio => {
          this.router.navigate(['/dashboard/']);
          swal('Servicio cancelado', `Servicio ${servicio.idServicioStr} cancelado con exito`, 'success');
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.motivoRequerido = true;
    }
  }

}
