import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Incoterm } from '../../../model/incoterm';
import { IncotermsService } from '../../../services/incoterms.service';
import { DetalleIncotermService } from './detalle-incoterm/detalle-incoterm.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'app-incoterms',
  templateUrl: './incoterms.component.html',
  styleUrls: ['./incoterms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IncotermsComponent implements OnInit {
  public listaIncoterms: Incoterm[];
  public incotermsTemp: Incoterm[];
  public incotermSeleccionado: Incoterm;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private incotermsService: IncotermsService,
    private detalleService: DetalleIncotermService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Incoterms');
  }

  ngOnInit() {
    this.incotermSeleccionado = null;
    this.cargaIncoterms();
  }

  private cargaIncoterms(): void {
    this.incotermsService.listIncoterms().subscribe(
      response => {
        this.listaIncoterms = response;
        this.incotermsTemp = response;
      }
    );
  }

  agregar(): void {
    this.incotermSeleccionado = new Incoterm();
    this.detalleService.abrirModal();
  }

  editar(incoterm: Incoterm): void {
    this.incotermSeleccionado = incoterm;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.incotermsTemp.filter(function(d) {
          return d.idIncoterm == val || d.descripcion.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaIncoterms = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
