import { Domicilio } from './domicilio';

export class UbicacionCp {
  idUbicacion: number;
	domicilio: Domicilio;
	tipoUbicacion: string;
	rfcRemitenteDestinatario: string;
  nombreRemitenteDestinatario: string;
  numRegIdTrib: string;
  residenciaFiscal: string;
  numEstacion: string;
  nombreEstacion: string;
  navegacionTrafico: string;
  fechaHoraSalidaLlegada: string;
  tipoEstacion: string;
  distanciaRecorrida: number;
}
