import { ImpuestoRequest } from './impuesto-request';

export class ConceptosRequest {
  ClaveProdServ: string;
  Cantidad: string;
  ClaveUnidad: string;
  Descripcion: string;
  ValorUnitario: string;
  Importe: string;
  Impuestos: ImpuestoRequest[];
}
