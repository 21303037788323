import { Component, OnInit, Input } from '@angular/core';
import { Prospecto } from '../../../../model/prospecto';
import { DetalleProspectoService } from './detalle-prospecto.service';
import { ProspectosService } from '../../../../services/prospectos.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-prospecto',
  templateUrl: './detalle-prospecto.component.html',
  styleUrls: ['./detalle-prospecto.component.scss']
})
export class DetalleProspectoComponent implements OnInit {

  @Input()
  prospecto: Prospecto;

  constructor(
    public detalleService: DetalleProspectoService,
    private prospectosService: ProspectosService
  ) { }

  ngOnInit() {
  }

  cancel(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.prospectosService.createProspecto(this.prospecto).subscribe(
      prospecto => {
        this.detalleService.cerraModal();
        swal('Prospecto Creado', `Prospecto ${prospecto.razonComercial} creado con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.prospectosService.updateProspecto(this.prospecto).subscribe(
      prospecto => {
        this.detalleService.cerraModal();
        swal('Prospecto Actualizado', `Prospecto ${prospecto.razonComercial} actualizado con exito`, 'success');
      }
    );
  }

}
