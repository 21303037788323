import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Mensaje } from '../model/mensaje';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ChatType } from '../model/graph/chat-type';
import { ChannelMessagesType } from '../model/graph/channel-messages-type';
import { MessageType } from '../model/graph/message-type';
import { UserGroupsType } from '../model/graph/user-groups-type';
import { GroupType } from '../model/graph/group-type';
import { GroupUsersType } from '../model/graph/group-users-type';
//import { UserType } from '../model/graph/user-type';

const swal = require('sweetalert');
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';
const ME_ENDPOINT = "/me/";
const GROUPS_ENDPOINT = "/groups/";
//const TEAMS_ENDPOINT = '/teams/';
//const TEAM_SERVICIOS = '1d06d23b-67a9-414f-8ead-69c6924214b0';
const MESSAGES_ENDPOINT = '/messages/';
const USERS_ENDPOINT = '/users/';
const CHATS_ENDPOINT = '/chats/';
const MEMBERS_ENDPOINT = '/members';
const SUPERADMIN_GROUP = '6ecdf70f-2909-4a23-8dac-49eda8b1f4c3';
const CUENTAS_PAGAR_GROUP = '6e78e7b3-afe0-4a25-a526-08d68545b488';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  foto: any;
  toasterConfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right'
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    public authService: AuthService,
    private toasterService: ToasterService
  ) { }

  popMensaje(mensaje: Mensaje): void {
    var toast: Toast = {
      type: 'success',
      title: 'Nuevo mensaje',
      body: mensaje.titulo,
      showCloseButton: true,
      clickHandler: (t, isClosed): boolean => {
        this.router.navigate(['/mensajes']);
        return true; // remove this toast !
      }
    };

    this.toasterService.pop(toast);
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT + ME_ENDPOINT).subscribe(profile => console.log(profile));
  }

  getPhoto() {
    this.http.get(GRAPH_ENDPOINT + ME_ENDPOINT + '/photo/$value', { responseType: 'blob' }).subscribe(data => this.createImageFromBlob(data));
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.foto = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getUserGroups(): Observable<UserGroupsType> {
    return this.http.get<UserGroupsType>(GRAPH_ENDPOINT + ME_ENDPOINT + 'transitiveMemberOf/microsoft.graph.group');
  }

  getGrupo(idGrupo: string): Observable<GroupType> {
    return this.http.get<GroupType>(GRAPH_ENDPOINT + GROUPS_ENDPOINT + idGrupo);
  }

  createChat(displayName: string, members : any[]): Observable<ChatType> {
    console.log('creando chat');

    let requestChat = {
      'chatType': 'group',
      'topic': displayName,
      'members': members
    };

    return this.http.post<ChatType>(GRAPH_ENDPOINT + CHATS_ENDPOINT, requestChat);
  }

  getChatMessages(chatId: string): Observable<ChannelMessagesType> {
    return this.http.get<ChannelMessagesType>(GRAPH_ENDPOINT + CHATS_ENDPOINT + chatId + MESSAGES_ENDPOINT);
  }

  createChatMessage(chatId: string, mensaje: string): Observable<MessageType> {
    let message = { "body": { "content": mensaje } };
    return this.http.post<MessageType>(GRAPH_ENDPOINT + CHATS_ENDPOINT + chatId + MESSAGES_ENDPOINT, message);
  }

  /*
  getMessageReplies(channelId: string, messageId: string): Observable<ChannelMessagesType> {
    return this.http.get<ChannelMessagesType>(GRAPH_ENDPOINT + TEAMS_ENDPOINT + TEAM_SERVICIOS + CHANNELS_ENDPOINT + channelId + MESSAGES_ENDPOINT + messageId + '/replies');
  }
  */

  getUserMessages(userId: string): Observable<ChannelMessagesType> {
    return this.http.get<ChannelMessagesType>(GRAPH_ENDPOINT + USERS_ENDPOINT + userId + CHATS_ENDPOINT + 'getAllMessages');
  }

  getMembersGroups(groupId: string): Observable<GroupUsersType> {
    return this.http.get<GroupUsersType>(GRAPH_ENDPOINT + GROUPS_ENDPOINT + groupId + MEMBERS_ENDPOINT);
  }

  getMembersSuperAdmin(): Observable<GroupUsersType> {
    return this.http.get<GroupUsersType>(GRAPH_ENDPOINT + GROUPS_ENDPOINT + SUPERADMIN_GROUP + MEMBERS_ENDPOINT);
  }

  getMembersCuentasPagar(): Observable<GroupUsersType> {
    return this.http.get<GroupUsersType>(GRAPH_ENDPOINT + GROUPS_ENDPOINT + CUENTAS_PAGAR_GROUP + MEMBERS_ENDPOINT);
  }

}
