import { IdentificacionVehicular } from './identificacion-vehicular';
import { Seguro } from './seguro';
import { Remolque } from './remolque';

export class Autotransporte{
  idAutotransporte: number;
  identificacionVehicular: IdentificacionVehicular;
  seguros: Seguro[];
	remolques: Remolque[];
	permSCT: string;
  numPermisoSCT: string;
}
