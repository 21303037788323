import { ParteTransporte } from './parte-transporte';

export class TipoFigura {
  idTipoFigura: string;
  partesTransporte: ParteTransporte[];
  tipoFigura: string;
  rfcFigura: string;
  numLicencia: string;
  nombreFigura: string;
  numRegIdTribFigura: string;
  residenciaFiscalFigura: string;
}
