import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BitacoraSap } from '../model/bitacora-sap';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class BitacorasSapService {

  private urlEndPoint: string = URL_BACKEND + "/bitacoraSap";

  constructor(
    private http: HttpClient
  ) { }

  listBitacorasSap(): Observable<BitacoraSap[]> {
    return this.http.get<BitacoraSap[]>(this.urlEndPoint + '/list');
  }

  pageBitacoraSap(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getBitacoraSap(idBitacoraSap: number): Observable<BitacoraSap> {
    return this.http.get<BitacoraSap>(this.urlEndPoint + '/' + idBitacoraSap).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  getBitacoraSapByIdServicio(idServicio: number): Observable<BitacoraSap[]> {
    return this.http.get<BitacoraSap[]>(this.urlEndPoint + '/servicio/' + idServicio);
  }

  createBitacoraSap(bitacoraSap: BitacoraSap): Observable<BitacoraSap> {
    return this.http.post(this.urlEndPoint, bitacoraSap).pipe(
      map(
        (response: any) => response.bitacoraSap as BitacoraSap
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateBitacoraSap(bitacoraSap: BitacoraSap): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, bitacoraSap).pipe(
      map(
        (response: any) => response.bitacoraSap as BitacoraSap
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
