export const URL_BACKEND = 'https://virtualflam.multilog.com.mx:12443/Multilog';
//export const URL_BACKEND = 'http://localhost:6060/Multilog';
export const URL_MY_SUITE = '/api/CartaPorteV2';

export class Catalogos {
  public static giros: string[] = [
    "Transportista", "Logistica"
  ];

  public static sociosComerciales: string[] = [];

  public static coberturasNacional: string[] = [
    "Todo el pais",
    "Zona Noroeste (Baja California, Baja California Sur, Chihuahua, Durango, Sinaloa y Sonora)",
    "Noreste (Coahuila, Nuevo Leon y Tamaulipas)",
    "Oriente (Hidalgo, Puebla, Veracruz y Tlaxcala)",
    "Occidente (Colima, Jalisco, Michoacan y Nayarit)",
    "Centro Norte (Aguascalientes, Guanuajuato, Queretaro, SLP y Zacatecas)",
    "Centro Sur (CDMX, Estado de Mexico y Morelos)",
    "Sureste (Campeche, Quintana Roo, Tabasco y Yucatan)",
    "Suroeste (Chiapas, Guerrero y Oaxaca)"
  ];

  public static patiosOperacion: string[] = [
    "Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Coahuila", "CDMX",
    "Colima", "Chiapas", "Chihuahua", "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco",
    "Estado de Mexico", "Michoacan", "Morelos", "Nayarit", "Nuevo Leon", "Oaxaca", "Puebla",
    "Queretaro", "Quintana Roo", "San Luis Potosi", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas",
    "Tlaxcala", "Veracruz", "Yucatan", "Zacatecas", "USA", "Canada"
  ];

  public static codigosHazmat: string[] = [];

  public static tiposEquipo: string[] = [
    "Seco", "Refrigerado", "Peligroso"
  ];

  public static equipos: string[] = [
    "Caja 53", "Caja 48", "Caja 40", "Full de Cajas", "Contenedores", "Plataforma",
    "Full de Plataformas", "Portacontenedores", "Pipas / Tanques", "Isotanques",
    "Full de Pipas / Tanques", "Thorton Abierto", "Thorton Cerrado", "Rabon Abierto",
    "Rabon Cerrado", "5 Toneladas Abiertas", "5 Toneladas Cerradas", "3.5 Toneladas Abierta",
    "3.5 Toneladas Cerrada", "1 Tonelada", "Camionetas Tipo Van", "Estaquitas",
    "Tanques Termicos", "Tolvas", "Góndola", "Lowboy", "Semi lowboy"
  ];

  public static servicios: string[] = [
    "Transporte Nacional", "Transporte Consolidado Nacional", "Transporte Impo /Expo (USA)",
		"Transporte Impo / Expo (USA y Canadá)", "Transporte Impo /Expo (Centroamérica)",
		"Transporte Consolidado Impo / Expo", "Cruce de Frontera", "Renta de Unidades", "Almacenaje",
		"Pension"
  ];

  public static unidadesAutAero: string[] = [];

  public static puertos: string[] = [
    "Veracruz, Veracruz", "Altamira, Tamaulipas", "Lazaro Cardenas, Michoacan",
		"Manzanillo, Colima", "Ensenada, Baja California", "No aplica"
  ];

  public static fronteras: string[] = [
    "Nuevo Laredo, Tamaulipas", "Matamoros, Tamaulipas", "Reynosa, Tamaulipas", "Colombia, Tamaulipas",
    "Ciudad Juarez, Chihuahua", "Mexicali, Baja California", "Tijuana, Baja California",
    "Tecate, Baja California", "Nogales, Sonora", "Cd. Hidalgo, Chiapas"
  ];

  public static recursos: string[] = [
    "GPS en Tractocamion", "Custodia", "GPS en Cajas", "Paro de Motor",
		"Politica de horarios y ruta de circulacion", "Cuenta Espejo", "Camara en cabina",
		"Georutas / Geocercas", "Protocolo de seguridad ante emergencias"
  ];

  public static certificacionesEmpresa: string[] = [
    "C-TPAT", "EEC / OEA", "ISO 9001 / 14000"
  ];

  public static certificacionesOperador: string[] = [
    "R Control", "OPALO"
  ];

}
