import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserModule } from './user/user.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SelectModule } from 'ng2-select';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../services/ngb-date-custom-parser-formatter.service';
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";

import { routes } from './routes';

import { ProveedoresComponent } from './catalogos/proveedores/proveedores.component';
import { DetalleProveedorComponent } from './catalogos/proveedores/detalle-proveedor/detalle-proveedor.component';
import { ClientesComponent } from './catalogos/clientes/clientes.component';
import { DetalleClienteComponent } from './catalogos/clientes/detalle-cliente/detalle-cliente.component';
import { ConsignatariosComponent } from './catalogos/consignatarios/consignatarios.component';
import { DetalleConsignatarioComponent } from './catalogos/consignatarios/detalle-consignatario/detalle-consignatario.component';
import { TipoCambioComponent } from './catalogos/tipo-cambio/tipo-cambio.component';
import { DetalleTipoCambioComponent } from './catalogos/tipo-cambio/detalle-tipo-cambio/detalle-tipo-cambio.component';
import { EvaluacionProveedorComponent } from './evaluacion-proveedor/evaluacion-proveedor.component';
import { ProspectosComponent } from './catalogos/prospectos/prospectos.component';
import { DetalleProspectoComponent } from './catalogos/prospectos/detalle-prospecto/detalle-prospecto.component';
import { ShippersComponent } from './catalogos/shippers/shippers.component';
import { DetalleShipperComponent } from './catalogos/shippers/detalle-shipper/detalle-shipper.component';
import { AeropuertosComponent } from './catalogos/aeropuertos/aeropuertos.component';
import { DetalleAeropuertoComponent } from './catalogos/aeropuertos/detalle-aeropuerto/detalle-aeropuerto.component';
import { AerolineasComponent } from './catalogos/aerolineas/aerolineas.component';
import { DetalleAerolineaComponent } from './catalogos/aerolineas/detalle-aerolinea/detalle-aerolinea.component';
import { IncotermsComponent } from './catalogos/incoterms/incoterms.component';
import { DetalleIncotermComponent } from './catalogos/incoterms/detalle-incoterm/detalle-incoterm.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { DetalleServicioComponent } from './servicios/detalle-servicio/detalle-servicio.component';
import { ConsultaServiciosComponent } from './consulta-servicios/consulta-servicios.component';
import { ServiciosInterComponent } from './servicios-inter/servicios-inter.component';
import { TotalesComponent } from './servicios-inter/totales/totales.component';
import { ConsultaServiciosInterComponent } from './consulta-servicios-inter/consulta-servicios-inter.component';
import { MensajesServicioComponent } from './mensajes-servicio/mensajes-servicio.component';
import { ExportarComponent } from './consulta-servicios/exportar/exportar.component';
import { CartaPorteComponent } from './carta-porte/carta-porte.component';
import { CatalogoComponent } from './servicios-inter/catalogo/catalogo.component';
import { ValidaFacturasComponent } from './valida-facturas/valida-facturas.component';
import { EjecutivosModalComponent } from './catalogos/clientes/ejecutivos/ejecutivos-modal.component';
import { BitacoraSapComponent } from './servicios/bitacora-sap/bitacora-sap.component';
import { DetalleEjecutivoComponent } from './catalogos/ejecutivos/detalle-ejecutivo/detalle-ejecutivo.component';
import { EjecutivosComponent } from './catalogos/ejecutivos/ejecutivos.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { CancelarServicioComponent } from './servicios/cancelar-servicio/cancelar-servicio.component';
import { CancelarServicioInterComponent } from './servicios-inter/cancelar-servicio-inter/cancelar-servicio-inter.component';
import { BitacoraInterSapComponent } from './servicios-inter/bitacora-inter-sap/bitacora-inter-sap.component';
import { ReasignarServiciosComponent } from './reasignar-servicios/reasignar-servicios.component';
import { ReasignarServiciosInterComponent } from './reasignar-servicios-inter/reasignar-servicios-inter.component';
import { DetalleReasignarComponent } from './reasignar-servicios/detalle-reasignar/detalle-reasignar.component';
import { DetalleReasignarInterComponent } from './reasignar-servicios-inter/detalle-reasignar-inter/detalle-reasignar-inter.component';
import { BusquedaProveedorComponent } from './busqueda-proveedor/busqueda-proveedor.component';
import { BusquedaProspectoComponent } from './busqueda-prospecto/busqueda-prospecto.component';
import { ConsultaTerrestreComponent } from './consulta-terrestre/consulta-terrestre.component';

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, {
          initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
        }),
        UserModule,
        NgxDatatableModule,
        SelectModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        BrowserModule,
        FormsModule,
        NgbModule,
        NgxSpinnerModule
    ],
    declarations: [
      ProveedoresComponent,
      DetalleProveedorComponent,
      ClientesComponent,
      DetalleClienteComponent,
      ConsignatariosComponent,
      DetalleConsignatarioComponent,
      TipoCambioComponent,
      DetalleTipoCambioComponent,
      EvaluacionProveedorComponent,
      ProspectosComponent,
      DetalleProspectoComponent,
      ShippersComponent,
      DetalleShipperComponent,
      AeropuertosComponent,
      DetalleAeropuertoComponent,
      AerolineasComponent,
      DetalleAerolineaComponent,
      IncotermsComponent,
      DetalleIncotermComponent,
      ServiciosComponent,
      DetalleServicioComponent,
      ConsultaServiciosComponent,
      ServiciosInterComponent,
      TotalesComponent,
      ConsultaServiciosInterComponent,
      MensajesServicioComponent,
      ExportarComponent,
      CartaPorteComponent,
      CatalogoComponent,
      ValidaFacturasComponent,
      EjecutivosModalComponent,
      BitacoraSapComponent,
      DetalleEjecutivoComponent,
      EjecutivosComponent,
      CancelarServicioComponent,
      CancelarServicioInterComponent,
      BitacoraInterSapComponent,
      ReasignarServiciosComponent,
      ReasignarServiciosInterComponent,
      DetalleReasignarComponent,
      DetalleReasignarInterComponent,
      BusquedaProveedorComponent,
      BusquedaProspectoComponent,
      ConsultaTerrestreComponent
    ],
    exports: [
        RouterModule
    ],
    providers: [
      { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ]
})
export class RoutesModule {
    constructor() {
    }
}
