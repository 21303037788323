import { Direccion } from './direccion';

export class Proveedor{
  idProveedor: number;
  razonSocial: string;
  razonComercial: string;
  terminoBusqueda: string;
  bloqueado: boolean;
  motivoBloqueo: string;
  rfc: string;
  borrado: boolean;
  aereo: boolean;
  direccion: Direccion;
  lineaCredito: number;
  plazo: number;
  idEvaluacion: number;
}
