import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TipoCambio } from '../../../../model/tipo-cambio';
import { AnioMes } from '../../../../model/anio-mes';
import { Mes } from '../../../../model/mes';
import { Moneda } from '../../../../model/moneda';
import { DetalleTipoCambioService } from './detalle-tipo-cambio.service';
import { TiposCambioService } from '../../../../services/tipos-cambio.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-tipo-cambio',
  templateUrl: './detalle-tipo-cambio.component.html',
  styleUrls: ['./detalle-tipo-cambio.component.scss']
})
export class DetalleTipoCambioComponent implements OnInit, OnChanges {

  @Input()
  anioMes: AnioMes;

  public nuevo: boolean;
  public meses: Mes[];
  public listTiposCambio: TipoCambio[];
  public tipoDolar: TipoCambio;
  public tipoEuro: TipoCambio;
  public tipoLibra: TipoCambio;
  public tipoYen: TipoCambio;

  constructor(
    public detalleService: DetalleTipoCambioService,
    private tipoCambioService: TiposCambioService
  ) { }

  ngOnInit() {
    this.cargaMeses();
  }

  ngOnChanges(changes: SimpleChanges){
    if(this.anioMes && this.anioMes.anio && this.anioMes.mes) {
      this.nuevo = false;
      this.cargaTiposCambio();
    } else {
      this.nuevo = true;
      this.anioMes = new AnioMes();
      this.anioMes.mes = undefined;
      this.tipoDolar = new TipoCambio();
      this.tipoEuro = new TipoCambio();
      this.tipoLibra = new TipoCambio();
      this.tipoYen = new TipoCambio();
    }
  }

  public cargaMeses(): void {
    const req = new XMLHttpRequest();
    req.open('GET', `assets/static/meses.json`);

    req.onload = () => {
        this.meses = JSON.parse(req.response);
    };

    req.send();
  }

  public cargaTiposCambio(): void {
    this.tipoCambioService.getTiposCambioByAnioMes(this.anioMes.anio, this.anioMes.mes.idMes).subscribe(
      response => {
        this.listTiposCambio = response;
        this.seleccionaMonedas();
      }
    );
  }

  public cancel(): void{
    this.detalleService.cerraModal();
  }

  public crear(): void{
    this.listTiposCambio = [];

    const monedaDolar = new Moneda();
    monedaDolar.idMoneda = 1;
    this.tipoDolar.moneda = monedaDolar;
    this.tipoDolar.anioMes = this.anioMes;
    this.listTiposCambio.push(this.tipoDolar);

    const monedaEuro = new Moneda();
    monedaEuro.idMoneda = 2;
    this.tipoEuro.moneda = monedaEuro;
    this.tipoEuro.anioMes = this.anioMes;
    this.listTiposCambio.push(this.tipoEuro);

    const monedaLibra = new Moneda();
    monedaLibra.idMoneda = 3;
    this.tipoLibra.moneda = monedaLibra;
    this.tipoLibra.anioMes = this.anioMes;
    this.listTiposCambio.push(this.tipoLibra);

    const monedaYen = new Moneda();
    monedaYen.idMoneda = 4;
    this.tipoYen.moneda = monedaYen;
    this.tipoYen.anioMes = this.anioMes;
    this.listTiposCambio.push(this.tipoYen);

    this.tipoCambioService.createTiposCambio(this.listTiposCambio).subscribe(
      tiposCambio => {
        this.detalleService.cerraModal();
        this.detalleService.cargaAniosMeses();
        swal('Tipos de Cambio Creados', `Tipos de Cambio ${this.anioMes.anio} ${this.anioMes.mes.nombreLargo} creados con exito`, 'success');
      }
    );
  }

  public actualizar(): void{
    this.tipoCambioService.updateTiposCambio(this.listTiposCambio).subscribe(
      tiposCambio => {
        this.detalleService.cerraModal();
        this.detalleService.cargaAniosMeses();
        swal('Tipos de Cambio Actualizados', `Tipos de Cambio ${this.anioMes.anio} ${this.anioMes.mes.nombreLargo} actualizados con exito`, 'success');
      }
    );
  }

  private seleccionaMonedas(): void {
    this.listTiposCambio.map( tipo => {
      switch(tipo.moneda.idMoneda){
        case 1:
          this.tipoDolar = tipo;
          break;
        case 2:
          this.tipoEuro = tipo;
          break;
        case 3:
          this.tipoLibra = tipo;
          break;
        case 4:
          this.tipoYen = tipo;
          break;
      }
    });
  }

  compararMes(o1: Mes, o2: Mes) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idMes === o2.idMes;
  }

}
