import { Component, OnInit, Input } from '@angular/core';
import { Cliente } from '../../../../model/cliente';
import { Ejecutivo } from '../../../../model/ejecutivo';
import { EjecutivosModalService } from './ejecutivos-modal.service';
import { EjecutivosService } from '../../../../services/ejecutivos.service';
import { ClientesService } from '../../../../services/clientes.service';

const swal = require('sweetalert');

@Component({
  selector: 'cliente-ejecutivos',
  templateUrl: './ejecutivos-modal.component.html',
  styleUrls: ['./ejecutivos-modal.component.scss']
})
export class EjecutivosModalComponent implements OnInit {

  @Input()
  cliente: Cliente;
  panelLista: boolean = true;
  panelAgregar: boolean;
  ejecutivo: Ejecutivo;
  listaEjecutivos: Ejecutivo[];

  constructor(
    private ejecutivosModalService: EjecutivosModalService,
    private ejecutivosService: EjecutivosService,
    private clientesService: ClientesService
  ) { }

  ngOnInit() {
  }

  cancel(): void {
    this.ejecutivosModalService.cerraModal();
    this.panelLista = true;
    this.panelAgregar = false;
  }

  agregar(): void {
    this.cargaEjecutivos();
    this.panelLista = false;
    this.panelAgregar = true;
  }

  cargaEjecutivos(): void {
    this.ejecutivosService.listEjecutivos().subscribe(
      response => {
        this.listaEjecutivos = [];
        response.map(
          ejecutivo => {
            var encontrado: Ejecutivo = this.cliente.ejecutivos.find(e => e.idEjecutivo === ejecutivo.idEjecutivo);
            if(encontrado == null) {
              this.listaEjecutivos.push(ejecutivo);
            }
          }
        );
      }
    );
  }

  agregarEjecutivo(): void {
    if(this.ejecutivo != null) {
      if(this.cliente.ejecutivos == null) {
        this.cliente.ejecutivos = [];
      }

      this.cliente.ejecutivos.push(this.ejecutivo);
      this.clientesService.updateCliente(this.cliente).subscribe(
        cliente => {
          this.cliente = cliente;
          this.panelLista = true;
          this.panelAgregar = false;
          swal('Ejecutivos Actualizados', `Cliente ${cliente.nombre1} ${cliente.nombre2} actualizado con exito`, 'success');
          this.ejecutivo = null;
        }
      );
    } else {
      swal('Ejecutivo Obligatorio', 'Se debe seleccionar un ejecutivo agregar', 'warning');
    }
  }

  eliminar(ejecutivo: Ejecutivo) {
    const index: number = this.cliente.ejecutivos.indexOf(ejecutivo);
    if (index !== -1) {
      this.cliente.ejecutivos.splice(index, 1);
      this.clientesService.updateCliente(this.cliente).subscribe(
        cliente => {
          this.cliente = cliente;
          swal('Ejecutivo eliminado', 'Ejecutivo eliminado del cliente con exito', 'success');
        }
      );
    }
  }

}
