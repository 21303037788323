import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Cliente } from '../model/cliente';
import { map, catchError } from 'rxjs/operators';
import { Ejecutivo } from '../model/ejecutivo';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  private urlEndPoint: string = URL_BACKEND + "/cliente";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listClientes(): Observable<Cliente[]>{
    return this.http.get<Cliente[]>(this.urlEndPoint + '/list');
  }

  listFirstClientesByBusqueda(busqueda: string): Observable<Cliente[]> {
    return this.http.get<Cliente[]>(this.urlEndPoint + '/search?busqueda=' + busqueda);
  }

  pageClientes(page: number): Observable<any>{
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getCliente(idCliente: number): Observable<Cliente>{
    return this.http.get<Cliente>(this.urlEndPoint + '/' + idCliente).pipe(
      catchError(e => {
        if(e.status != 401){
          this.router.navigate(['/clientes']);
        }
        return throwError(e);
      })
    );
  }

  createCliente(cliente: Cliente): Observable<Cliente>{
    return this.http.post(this.urlEndPoint, cliente).pipe(
      map(
        (response: any) => response.cliente as Cliente
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateCliente(cliente: Cliente): Observable<any>{
    return this.http.put<any>(this.urlEndPoint, cliente).pipe(
      map(
        (response: any) => response.cliente as Cliente
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  getEjecutivosByCliente(idCliente: number): Observable<Ejecutivo[]>{
    return this.http.get<Ejecutivo[]>(this.urlEndPoint + '/ejecutivos/' + idCliente);
  }

}
