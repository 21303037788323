import { DerechoDePaso } from './derecho-de-paso';
import { Carro } from './carro';

export class TransporteFerroviario {
  idTransporteFerroviario: number;
	derechosDePaso: DerechoDePaso[];
	carros: Carro[];
	tipoDeServicio: string;
  tipoDeTrafico: string;
	nombreAseg: string;
	numPolizaSeguro: string;
}
