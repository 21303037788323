import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Ejecutivo } from '../model/ejecutivo';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class EjecutivosService {

  private urlEndPoint: string = URL_BACKEND + "/ejecutivo";

  constructor(
    private http: HttpClient
  ) { }

  listEjecutivos(): Observable<Ejecutivo[]> {
    return this.http.get<Ejecutivo[]>(this.urlEndPoint + '/list');
  }

  pageEjecutivos(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getEjecutivo(idEjecutivo: number): Observable<Ejecutivo> {
    return this.http.get<Ejecutivo>(this.urlEndPoint + '/' + idEjecutivo).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createEjecutivo(ejecutivo: Ejecutivo): Observable<Ejecutivo> {
    return this.http.post(this.urlEndPoint, ejecutivo).pipe(
      map(
        (response: any) => response.ejecutivo as Ejecutivo
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateEjecutivo(ejecutivo: Ejecutivo): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, ejecutivo).pipe(
      map(
        (response: any) => response.ejecutivo as Ejecutivo
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
