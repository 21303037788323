import { Mercancia } from './mercancia';
import { Autotransporte } from './autotransporte';
import { TransporteMaritimo } from './transporte-maritimo';
import { TransporteAereo } from './transporte-aereo';
import { TransporteFerroviario } from './transporte-ferroviario';

export class Mercancias {
  idResumenMercancia: number;
	mercancia: Mercancia[];
	autotransporte: Autotransporte;
	transporteMaritimo: TransporteMaritimo;
	transporteAereo: TransporteAereo;
	transporteFerroviario: TransporteFerroviario;
	pesoBrutoTotal: number;
	unidadPeso: string;
	pesoNetoTotal: number;
	numTotalMercancias: number;
	cargoPorTasacion: number;
}
