import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { TipoMovimiento } from '../model/tipo-movimiento';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class TiposMovimientoService {

  private urlEndPoint: string = URL_BACKEND + "/tipoMovimiento";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listTiposMovimiento(): Observable<TipoMovimiento[]> {
    return this.http.get<TipoMovimiento[]>(this.urlEndPoint + '/list');
  }

  pageTiposMovimiento(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getTipoMovimiento(idTipoMovimiento: number): Observable<TipoMovimiento> {
    return this.http.get<TipoMovimiento>(this.urlEndPoint + '/' + idTipoMovimiento).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createTipoMovimiento(tipoMovimiento: TipoMovimiento): Observable<TipoMovimiento> {
    return this.http.post(this.urlEndPoint, tipoMovimiento).pipe(
      map(
        (response: any) => response.tipoMovimiento as TipoMovimiento
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateTipoMovimiento(tipoMovimiento: TipoMovimiento): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, tipoMovimiento).pipe(
      map(
        (response: any) => response.tipoMovimiento as TipoMovimiento
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }
  
}
