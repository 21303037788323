import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { TipoCambio } from '../model/tipo-cambio';
import { AnioMes } from '../model/anio-mes';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class TiposCambioService {

  private urlEndPoint: string = URL_BACKEND + "/tipoCambio";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listAniosMeses(): Observable<AnioMes[]>{
    return this.http.get<AnioMes[]>(this.urlEndPoint + '/anioMes/list');
  }

  pageAniosMeses(page: number): Observable<any>{
    return this.http.get(this.urlEndPoint + '/anioMes/page/' + page);
  }

  listTiposCambio(): Observable<TipoCambio[]>{
    return this.http.get<TipoCambio[]>(this.urlEndPoint + '/list');
  }

  pageTipoCambio(page: number): Observable<any>{
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getTipoCambio(idTipoCambio: number): Observable<TipoCambio>{
    return this.http.get<TipoCambio>(this.urlEndPoint + '/' + idTipoCambio).pipe(
      catchError(e => {
        if(e.status != 401){
          this.router.navigate(['/tiposCambio']);
        }
        return throwError(e);
      })
    );
  }

  getTiposCambioByAnioMes(anio: number, mes: number): Observable<TipoCambio[]>{
    return this.http.get<TipoCambio[]>(this.urlEndPoint + '/' + anio + '/' + mes).pipe(
      catchError(e => {
        if(e.status != 401){
          this.router.navigate(['/tiposCambio']);
        }
        return throwError(e);
      })
    );
  }

  createTipoCambio(tipoCambio: TipoCambio): Observable<TipoCambio>{
    return this.http.post(this.urlEndPoint, tipoCambio).pipe(
      map(
        (response: any) => response.tipoCambio as TipoCambio
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  createTiposCambio(tiposCambio: TipoCambio[]): Observable<TipoCambio[]>{
    return this.http.post(this.urlEndPoint + '/list', tiposCambio).pipe(
      map(
        (response: any) => response.tiposCambio as TipoCambio[]
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateTipoCambio(tipoCambio: TipoCambio): Observable<TipoCambio>{
    return this.http.put<any>(this.urlEndPoint, tipoCambio).pipe(
      map(
        (response: any) => response.tipoCambio as TipoCambio
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateTiposCambio(tiposCambio: TipoCambio[]): Observable<TipoCambio[]> {
    return this.http.put<any>(this.urlEndPoint + '/list', tiposCambio).pipe(
      map(
        (response: any) => response.tiposCambio as TipoCambio[]
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
