export class TransporteAereo {
  idTransporteAereo: number;
	permSCT: string;
	numPermisoSCT: string;
	matriculaAeronave: string;
	nombreAseg: string;
	numPolizaSeguro: string;
	numeroGuia: string;
	lugarContrato: string;
	rfcTransportista: string;
	codigoTransportista: string;
	numRegIdTribTranspor: string;
	residenciaFiscalTranspor: string;
	nombreTransportista: string;
	rfcEmbarcador: string;
	numRegIdTribEmbarc: string;
	residenciaFiscalEmbarc: string;
	nombreEmbarcador: string;
}
