import { Component, OnInit, Input } from '@angular/core';
import { Shipper } from '../../../../model/shipper';
import { DetalleShipperService } from './detalle-shipper.service';
import { ShippersService } from '../../../../services/shippers.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-shipper',
  templateUrl: './detalle-shipper.component.html',
  styleUrls: ['./detalle-shipper.component.scss']
})
export class DetalleShipperComponent implements OnInit {

  @Input()
  shipper: Shipper;

  constructor(
    public detalleService: DetalleShipperService,
    private shippersService: ShippersService
  ) { }

  ngOnInit() {
  }

  cancel(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.shippersService.createShipper(this.shipper).subscribe(
      shipper => {
        this.detalleService.cerraModal();
        swal('Shipper Creado', `Shipper ${shipper.nombre} creado con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.shippersService.updateShipper(this.shipper).subscribe(
      shipper => {
        this.detalleService.cerraModal();
        swal('Shipper Actualizado', `Shipper ${shipper.nombre} actualizado con exito`, 'success');
      }
    );
  }

}
