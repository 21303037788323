import { Component, OnInit, Input } from '@angular/core';
import { ExportarService } from './exportar.service';
import { PreferenciasExportService } from '../../../services/preferencias-export.service';
import { AuthService } from '../../../auth/auth.service';
import { PreferenciaExport } from '../../../model/preferencia-export';
import { ExcelService } from '../../../services/excel.service';
import { Servicio } from '../../../model/servicio';

const swal = require('sweetalert');

@Component({
  selector: 'exportar',
  templateUrl: './exportar.component.html',
  styleUrls: ['./exportar.component.scss']
})
export class ExportarComponent implements OnInit {

  @Input()
  servicios: Servicio[];

  preferencias: PreferenciaExport;
  numeroServicio: boolean;
  trafico: boolean;
  fechaServicio: boolean;
  cliente: boolean;
  mpc: boolean;
  completo: boolean;
  notas: boolean;
  tipoRuta: boolean;
  ubicacionRuta: boolean;
  consignatarioRuta: boolean;
  proveedorRuta: boolean;
  costoRuta: boolean;
  ventaRuta: boolean;
  utilidadRuta: boolean;
  utilidadCarago: boolean;
  proveedorCargo: boolean;
  cargoCargo: boolean;
  observacionesCargo: boolean;
  costoCargo: boolean;
  ventaCargo: boolean;

  constructor(
    public exportarService: ExportarService,
    private preferenciasExportService: PreferenciasExportService,
    private authService: AuthService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.cargarPreferencias();
  }

  cargarPreferencias(): void {
    this.preferenciasExportService.getPreferenciaExportByUsuario(this.authService.usuario.idUsuario, 'Terrestre').subscribe(
      response => {
        if(response != null) {
          this.preferencias = response;
          this.procesaPreferencias();
        } else {
          this.preferencias = new PreferenciaExport();
          this.preferencias.trafico = this.authService.usuario;
          this.preferencias.seleccion = '';
          this.preferencias.tipo = 'Terrestre';
        }
      }
    );
  }

  cancelar(): void {
    this.exportarService.cerraModal();
  }

  exportar(): void {
    let header = [];
    let colServicio = 0;
    let colRuta = 0;
    let colCargo = 0;

    this.preferencias.seleccion = '';
    if(this.numeroServicio) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'numeroServicio,';
      header.push('Número de Servicio');
      colServicio = colServicio + 1;
    }
    if(this.trafico) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'trafico,';
      header.push('Tráfico');
      colServicio = colServicio + 1;
    }
    if(this.fechaServicio) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'fechaServicio,';
      header.push('Fecha de Servicio');
      colServicio = colServicio + 1;
    }
    if(this.cliente) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'cliente,';
      header.push('Cliente');
      colServicio = colServicio + 1;
    }
    if(this.mpc) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'mpc,';
      header.push('MPC');
      colServicio = colServicio + 1;
    }
    if(this.completo) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'completo,';
      header.push('Completo');
      colServicio = colServicio + 1;
    }
    if(this.notas) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'notas,';
      header.push('Notas');
      colServicio = colServicio + 1;
    }
    if(this.tipoRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'tipoRuta,';
      header.push('Tipo Ruta');
      colRuta = colRuta + 1;
    }
    if(this.ubicacionRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'ubicacionRuta,';
      header.push('Ubicación');
      colRuta = colRuta + 1;
    }
    if(this.consignatarioRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'consignatarioRuta,';
      header.push('Consignatario');
      colRuta = colRuta + 1;
    }
    if(this.proveedorRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'proveedorRuta,';
      header.push('Proveedor Ruta');
      colRuta = colRuta + 1;
    }
    if(this.costoRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'costoRuta,';
      header.push('Costo Ruta');
      colRuta = colRuta + 1;
    }
    if(this.ventaRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'ventaRuta,';
      header.push('Venta Ruta');
      colRuta = colRuta + 1;
    }
    if(this.utilidadRuta) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'utilidadRuta,';
      header.push('Utilidad Ruta');
      colRuta = colRuta + 1;
    }
    if(this.proveedorCargo) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'proveedorCargo,';
      header.push('Proveedor Cargo');
      colCargo = colCargo + 1;
    }
    if(this.cargoCargo) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'cargoCargo,';
      header.push('Cargo');
      colCargo = colCargo + 1;
    }
    if(this.observacionesCargo) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'observacionesCargo,';
      header.push('Observaciones');
      colCargo = colCargo + 1;
    }
    if(this.costoCargo) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'costoCargo,';
      header.push('Costo Cargo');
      colCargo = colCargo + 1;
    }
    if(this.ventaCargo) {
      this.preferencias.seleccion = this.preferencias.seleccion + 'ventaCargo,';
      header.push('Venta Cargo');
      colCargo = colCargo + 1;
    }

    if(this.preferencias.idPreferencia != null) {
      this.preferenciasExportService.updatePreferenciaExport(this.preferencias).subscribe(
        preferencia => {
          swal('Exportando', 'Se exportó de forma correcta', 'success');
        }
      );
    } else {
      this.preferenciasExportService.createPreferenciaExport(this.preferencias).subscribe(
        preferencia => {
          this.preferencias = preferencia;
          swal('Exportando', 'Se exportó de forma correcta', 'success');
        }
      );
    }

    this.excelService.title = 'Servicios Terrestres';
    this.excelService.header = header;
    this.excelService.colServicio = colServicio;
    this.excelService.colRuta = colRuta;
    this.excelService.colCargo = colCargo;
    this.excelService.servicios = this.servicios;
    this.excelService.generateExcel();

  }

  procesaPreferencias(): void {
    if(this.preferencias.seleccion) {
      if(this.preferencias.seleccion.indexOf('numeroServicio') > -1) {
        this.numeroServicio = true;
      }
      if(this.preferencias.seleccion.indexOf('trafico') > -1) {
        this.trafico = true;
      }
      if(this.preferencias.seleccion.indexOf('fechaServicio') > -1) {
        this.fechaServicio = true;
      }
      if(this.preferencias.seleccion.indexOf('cliente') > -1) {
        this.cliente = true;
      }
      if(this.preferencias.seleccion.indexOf('mpc') > -1) {
        this.mpc = true;
      }
      if(this.preferencias.seleccion.indexOf('completo') > -1) {
        this.completo = true;
      }
      if(this.preferencias.seleccion.indexOf('notas') > -1) {
        this.notas = true;
      }
      if(this.preferencias.seleccion.indexOf('tipoRuta') > -1) {
        this.tipoRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('ubicacionRuta') > -1) {
        this.ubicacionRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('consignatarioRuta') > -1) {
        this.consignatarioRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('proveedorRuta') > -1) {
        this.proveedorRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('costoRuta') > -1) {
        this.costoRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('ventaRuta') > -1) {
        this.ventaRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('utilidadRuta') > -1) {
        this.utilidadRuta = true;
      }
      if(this.preferencias.seleccion.indexOf('proveedorCargo') > -1) {
        this.proveedorCargo = true;
      }
      if(this.preferencias.seleccion.indexOf('cargoCargo') > -1) {
        this.cargoCargo = true;
      }
      if(this.preferencias.seleccion.indexOf('observacionesCargo') > -1) {
        this.observacionesCargo = true;
      }
      if(this.preferencias.seleccion.indexOf('costoCargo') > -1) {
        this.costoCargo = true;
      }
      if(this.preferencias.seleccion.indexOf('ventaCargo') > -1) {
        this.ventaCargo = true;
      }
    }
  }

}
