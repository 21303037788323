import { ContenedorMaritimo } from './contenedor-maritimo';

export class TransporteMaritimo {
  idTransporteMaritimo: number;
	contenedores: ContenedorMaritimo[];
	permSCT: string;
	numPermisoSCT: string;
	nombreAseg: string;
	numPolizaSeguro: string;
	tipoEmbarcacion: string;
	matricula: string;
	numeroOMI: string;
	anioEmbarcacion: number;
	nombreEmbarc: string;
	nacionalidadEmbarc: string;
	unidadesDeArqBruto: number;
	tipoCarga: string;
	numCertITC: string;
	eslora: number;
	manga: number;
	calado: number;
	lineaNaviera: string;
	nombreAgenteNaviero: string;
	numAutorizacionNaviero: string;
	numViaje: string;
	numConocEmbarc: string;
}
