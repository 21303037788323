import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Usuario } from '../model/usuario';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  private urlEndPoint: string = URL_BACKEND + "/usuario";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listUsuarios(): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(this.urlEndPoint + '/list');
  }

  pageUsuarios(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getUsuario(idUsuario: number): Observable<Usuario> {
    return this.http.get<Usuario>(this.urlEndPoint + '/' + idUsuario).pipe(
      catchError(e => {
        if (e.status != 401) {
          this.router.navigate(['/usuarios']);
        }
        return throwError(e);
      })
    );
  }

  createUsuario(usuario: Usuario): Observable<Usuario> {
    return this.http.post(this.urlEndPoint, usuario).pipe(
      map(
        (response: any) => response.usuario as Usuario
      ),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateUsuario(usuario: Usuario): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, usuario).pipe(
      map(
        (response: any) => response.usuario as Usuario
      ),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  upload(archivo: File, idUsuario): Observable<HttpEvent<{}>>{
    let formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("idUsuario", idUsuario);
    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true
    });
    return this.http.request(req).pipe(
      catchError(e => {
        swal.fire(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

}
