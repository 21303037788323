import { Menu } from './menu';
import { Rol } from './rol';

export class Usuario{
  idUsuario: number;
  nombre1: string;
  nombre2: string;
  nombre3: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  correoElectronico: string;
  avatar: string;
  username: string;
  password: string;
  enabled: boolean;
  bloqueado: boolean;
  motivoBloqueo: string;
  rol: Rol;
  menu: Menu[];
}
