export class Shipper {
  idShipper: number;
  nombre: string;
  contacto: string;
  terminoBusqueda: string;
  bloqueado: boolean;
  motivoBloqueo: string;
  rfc: string;
  borrado: boolean;
}
