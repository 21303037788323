import { Pais } from './pais';
import { Estado } from './estado';

export class Aeropuerto {
  idAeropuerto: number;
  pais: Pais;
  estado: string;
  nombre: string;
  iniciales: string;
  borrado: boolean;
}
