import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Shipper } from '../model/shipper';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class ShippersService {

  private urlEndPoint: string = URL_BACKEND + "/shipper";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listShippers(): Observable<Shipper[]> {
    return this.http.get<Shipper[]>(this.urlEndPoint + '/list');
  }

  pageShippers(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getShipper(idShipper: number): Observable<Shipper> {
    return this.http.get<Shipper>(this.urlEndPoint + '/' + idShipper).pipe(
      catchError(e => {
        if (e.status != 401) {
          this.router.navigate(['/shippers']);
        }
        return throwError(e);
      })
    );
  }

  createShipper(shipper: Shipper): Observable<Shipper> {
    return this.http.post(this.urlEndPoint, shipper).pipe(
      map(
        (response: any) => response.shipper as Shipper
      ),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateShipper(shipper: Shipper): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, shipper).pipe(
      map(
        (response: any) => response.shipper as Shipper
      ),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
