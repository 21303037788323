import { ContenedorFerroviario } from './contenedor-ferroviario';

export class Carro {
  idCarro: number;
  contenedores: ContenedorFerroviario[];
  tipoCarro: string;
  matriculaCarro: string;
  guiaCarro: string;
  toneladasNetasCarro: number;
}
