import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AnioMes } from '../../../model/anio-mes';
import { DetalleTipoCambioService } from './detalle-tipo-cambio/detalle-tipo-cambio.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

declare var $: any;

@Component({
  selector: 'app-tipo-cambio',
  templateUrl: './tipo-cambio.component.html',
  styleUrls: ['./tipo-cambio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TipoCambioComponent implements OnInit {

  public anioMesSeleccionado: AnioMes;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    public detalleService: DetalleTipoCambioService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Tipos de Cambio');
  }

  ngOnInit() {
    this.anioMesSeleccionado = null;
    this.detalleService.cargaAniosMeses();
  }

  agregar(): void {
    this.anioMesSeleccionado = new AnioMes();
    this.detalleService.abrirModal();
  }

  editar(anioMes: AnioMes): void {
    this.anioMesSeleccionado = anioMes;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.detalleService.aniosMesesTemp.filter(function(d) {
      return d.anio == val || d.mes.nombreLargo.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.detalleService.listaAniosMeses = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
