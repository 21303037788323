import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '../../shared/settings/settings.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

    constructor(
      public settings: SettingsService,
      public authService: AuthService
    ) { }

    ngOnInit() {
      this.settings.app.footer.fixed = true;
    }

}
