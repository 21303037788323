import { TipoRuta } from './tipo-ruta';
import { Moneda } from './moneda';
import { TipoMovimiento } from './tipo-movimiento';
import { TipoEquipo } from './tipo-equipo';
import { Proveedor } from './proveedor';
import { Ubicacion } from './ubicacion';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { ArchivoBox } from './archivo-box';

export class ServicioPorCliente {
  idServicioCliente: number;
  tipoRuta: TipoRuta;
  tipoRutaRequerido: boolean = false;
  orden: number;
  costo: number;
  venta: number;
  monedaCosto: Moneda;
  monedaCostoRequerida: boolean = false;
  costoRequerido: boolean = false;
  monedaVenta: Moneda;
  monedaVentaRequerida: boolean = false;
  ventaRequerida: boolean = false;
  numFactura: string;
  fechaFactura: Date;
  fechaCarga: Date;
  fechaAgenteAduanal: Date;
  fechaCruce: Date;
  fechaDestino: Date;
  referenciaCliente: string;
  podId: number;
  entregado: boolean;
  tipoMovimiento: TipoMovimiento;
  numEquipoTipoMovimiento: string;
  tipoEquipo: TipoEquipo;
  numEquipoTipoEquipo: string;
  comentarios: string;
  archivoPod: ArchivoBox;
  consignatario: string;
  consignatarioRequerido: boolean = false;
  proveedor: Proveedor;
  proveedorRequerido: boolean = false;
  ubicacion: Ubicacion;
  ubicacionRequerida: boolean = false;
  pedidoCliente: string;
  crmId: number;
  ubicacionCtrl: FormControl;
  ubicacionFilterCtrl: FormControl;
  ubicacionesFiltradas: ReplaySubject<Ubicacion[]>;
  proveedorCtrl: FormControl;
  proveedorFilterCtrl: FormControl;
  proveedoresFiltrados: ReplaySubject<Proveedor[]>;
  configuracionRequerida: boolean = false;
}
