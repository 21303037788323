import { Component, OnInit } from '@angular/core';
import { ProspectosService } from '../../services/prospectos.service';
import { Prospecto } from '../../model/prospecto';
import { EvaluacionProveedorService } from '../evaluacion-proveedor/evaluacion-proveedor.service';
import { Catalogos } from '../../config/config';
import { SelectItem } from '../../model/select-item';

@Component({
  selector: 'app-busqueda-prospecto',
  templateUrl: './busqueda-prospecto.component.html',
  styleUrls: ['./busqueda-prospecto.component.scss']
})
export class BusquedaProspectoComponent implements OnInit {

  catalogoGiros: string[] = Catalogos.giros;
  catalogoSociosComerciales: string[] = Catalogos.sociosComerciales;
  catalogoCoberturasNacional: string[] = Catalogos.coberturasNacional;
  catalogoPatiosOperacion: string[] = Catalogos.patiosOperacion;
  catalogoCodigosHazmat: string[] = Catalogos.codigosHazmat;
  catalogoServicios: string[] = Catalogos.servicios;
  catalogoUnidadesAutAero: string[] = Catalogos.unidadesAutAero;
  catalogoPuertos: string[] = Catalogos.puertos;
  catalogoFronteras: string[] = Catalogos.fronteras;
  catalogoRecursos: string[] = Catalogos.recursos;
  catalogoCertificacionesEmpresa: string[] = Catalogos.certificacionesEmpresa;
  catalogoCertificacionesOperador: string[] = Catalogos.certificacionesOperador;
  catalogoEquipos: string[] = Catalogos.equipos;

  giros: SelectItem[] = [];
  sociosComerciales: SelectItem[] = [];
  coberturasNacional: SelectItem[] = [];
  patiosOperacion: SelectItem[] = [];
  codigosHazmat: SelectItem[] = [];
  servicios: SelectItem[] = [];
  unidadesAutAero: SelectItem[] = [];
  puertosArrastre: SelectItem[] = [];
  fronterasCruce: SelectItem[] = [];
  recursosSeguridad: SelectItem[] = [];
  certificacionesEmpresa: SelectItem[] = [];
  certificadoOperadores: SelectItem[] = [];
  equipos: SelectItem[] = [];

  listaProspectos: Prospecto[];
  idEvaluacionSeleccionada: number;
  idProspectoSeleccionado: number;

  constructor(
    private prospectosService: ProspectosService,
    private evaluacionProveedorService: EvaluacionProveedorService
  ) { }

  ngOnInit() {
  }

  limpiar(): void {
    this.listaProspectos = [];
    this.idEvaluacionSeleccionada = 0;
    this.idProspectoSeleccionado = 0;
    this.giros = [];
    this.sociosComerciales = [];
    this.coberturasNacional = [];
    this.patiosOperacion = [];
    this.codigosHazmat = [];
    this.servicios = [];
    this.unidadesAutAero = [];
    this.puertosArrastre = [];
    this.fronterasCruce = [];
    this.recursosSeguridad = [];
    this.certificacionesEmpresa = [];
    this.certificadoOperadores = [];
    this.equipos = [];
  }

  buscar(): void {
    let girosNuevos: string[] = [];
    let sociosComercialesNuevos: string[] = [];
    let coberturasNacionalNuevos: string[] = [];
    let patiosOperacionNuevos: string[] = [];
    let codigosHazmatNuevos: string[] = [];
    let serviciosNuevos: string[] = [];
    let unidadesAutAeroNuevos: string[] = [];
    let puertosArrastreNuevos: string[] = [];
    let fronterasCruceNuevos: string[] = [];
    let recursosSeguridadNuevos: string[] = [];
    let certificacionesEmpresaNuevos: string[] = [];
    let certificadoOperadoresNuevos: string[] = [];
    let equiposNuevos: string[] = [];

    if(this.giros != null && this.giros.length > 0) {
      this.giros.map(
        giro => {
          girosNuevos.push(giro.text);
        }
      );
    }

    if(this.sociosComerciales != null && this.sociosComerciales.length > 0) {
      this.sociosComerciales.map(
        socio => {
          sociosComercialesNuevos.push(socio.text);
        }
      );
    }

    if(this.coberturasNacional != null && this.coberturasNacional.length > 0) {
      this.coberturasNacional.map(
        cobertura => {
          coberturasNacionalNuevos.push(cobertura.text);
        }
      );
    }

    if(this.patiosOperacion != null && this.patiosOperacion.length > 0) {
      this.patiosOperacion.map(
        patio => {
          patiosOperacionNuevos.push(patio.text);
        }
      );
    }

    if(this.codigosHazmat != null && this.codigosHazmat.length > 0) {
      this.codigosHazmat.map(
        codigo => {
          codigosHazmatNuevos.push(codigo.text);
        }
      );
    }

    if(this.servicios != null && this.servicios.length > 0) {
      this.servicios.map(
        servicio => {
          serviciosNuevos.push(servicio.text);
        }
      );
    }

    if(this.unidadesAutAero != null && this.unidadesAutAero.length > 0) {
      this.unidadesAutAero.map(
        unidad => {
          unidadesAutAeroNuevos.push(unidad.text);
        }
      );
    }

    if(this.puertosArrastre != null && this.puertosArrastre.length > 0) {
      this.puertosArrastre.map(
        puerto => {
          puertosArrastreNuevos.push(puerto.text);
        }
      );
    }

    if(this.fronterasCruce != null && this.fronterasCruce.length > 0) {
      this.fronterasCruce.map(
        frontera => {
          fronterasCruceNuevos.push(frontera.text);
        }
      );
    }

    if(this.recursosSeguridad != null && this.recursosSeguridad.length > 0) {
      this.recursosSeguridad.map(
        recurso => {
          recursosSeguridadNuevos.push(recurso.text);
        }
      );
    }

    if(this.certificacionesEmpresa != null && this.certificacionesEmpresa.length > 0) {
      this.certificacionesEmpresa.map(
        certificado => {
          certificacionesEmpresaNuevos.push(certificado.text);
        }
      );
    }

    if(this.certificadoOperadores != null && this.certificadoOperadores.length > 0) {
      this.certificadoOperadores.map(
        certificado => {
          certificadoOperadoresNuevos.push(certificado.text);
        }
      );
    }

    if(this.equipos != null && this.equipos.length > 0) {
      this.equipos.map(
        equipo => {
          equiposNuevos.push(equipo.text);
        }
      );
    }

    this.prospectosService.searchProspecto(girosNuevos, sociosComercialesNuevos, coberturasNacionalNuevos,
      patiosOperacionNuevos, codigosHazmatNuevos, serviciosNuevos, unidadesAutAeroNuevos, puertosArrastreNuevos,
      fronterasCruceNuevos, recursosSeguridadNuevos, certificacionesEmpresaNuevos, certificadoOperadoresNuevos,
      equiposNuevos).subscribe(
      response => {
        this.listaProspectos = response as Prospecto[];
      }
    );
  }

  verEvaluacion(proveedor: Prospecto) {
    this.idEvaluacionSeleccionada = proveedor.idEvaluacion;
    this.idProspectoSeleccionado = proveedor.idProspecto;
    this.evaluacionProveedorService.abrirModal();
  }

  refreshGiros(value: any): void {
    this.giros = value;
  }

  refreshSC(value: any): void {
    this.sociosComerciales = value;
  }

  refreshCN(value: any): void {
    this.coberturasNacional = value;
  }

  refreshPO(value: any): void {
    this.patiosOperacion = value;
  }

  refreshServicios(value: any): void {
    this.servicios = value;
  }

  refreshUnidades(value: any): void {
    this.unidadesAutAero = value;
  }

  refreshPuertos(value: any): void {
    this.puertosArrastre = value;
  }

  refreshFronteras(value: any): void {
    this.fronterasCruce = value;
  }

  refreshRecursos(value: any): void {
    this.recursosSeguridad = value;
  }

  refreshCE(value: any): void {
    this.certificacionesEmpresa = value;
  }

  refreshCO(value: any): void {
    this.certificadoOperadores = value;
  }

  refreshEquipos(value: any): void {
    this.equipos = value;
  }
  
}
