import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SettingsService } from '../../shared/settings/settings.service';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { GraphService } from '../../services/graph.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.menu-links.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  sidebarVisible: true;

  constructor(
    private msalService: MsalService,
    public settings: SettingsService,
    public pt: PagetitleService,
    public authService: AuthService,
    private router: Router,
    public graphService: GraphService
  ) {
  }

  ngOnInit() {
  }

  toggleSidebarCoverModeVisible() {
    this.settings.app.sidebar.coverModeVisible = !this.settings.app.sidebar.coverModeVisible;
  }

  toggleSidebar(state = null) {
    //  state === true -> open
    //  state === false -> close
    //  state === null -> toggle
    this.settings.app.sidebar.visible = state !== null ? state : !this.settings.app.sidebar.visible;
  }

  openModalSearch() {
  }

  openModalBar() {
  }

  logout(): void {
    this.authService.logout();
    this.msalService.logoutRedirect({
      
      //postLogoutRedirectUri: 'http://localhost:4200'
	  postLogoutRedirectUri: 'https://virtualflam.multilog.com.mx/multilog-app/'
    });
    this.router.navigate(['/signin']);
  }

}
