import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Shipper } from '../../../model/shipper';
import { ShippersService } from '../../../services/shippers.service';
import { DetalleShipperService } from './detalle-shipper/detalle-shipper.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'app-shippers',
  templateUrl: './shippers.component.html',
  styleUrls: ['./shippers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShippersComponent implements OnInit {
  public listaShippers: Shipper[];
  public shippersTemp: Shipper[];
  public shipperSeleccionado: Shipper;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private shippersService: ShippersService,
    private detalleService: DetalleShipperService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Shippers');
  }

  ngOnInit() {
    this.shipperSeleccionado = null;
    this.cargaShippers();
  }

  private cargaShippers(): void {
    this.shippersService.listShippers().subscribe(
      response => {
        this.listaShippers = response;
        this.shippersTemp = response;
      }
    );
  }

  agregar(): void {
    this.shipperSeleccionado = new Shipper();
    this.detalleService.abrirModal();
  }

  editar(shipper: Shipper): void {
    this.shipperSeleccionado = shipper;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.shippersTemp.filter(function(d) {
          return d.idShipper == val || d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaShippers = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
