import { UbicacionCp } from './ubicacion-cp';
import { Mercancias } from './mercancias';
import { FiguraTransporte } from './figura-transporte';

export class CartaPorte {
  idCartaPorte: number;
	ubicaciones: UbicacionCp[];
	mercancias: Mercancias;
	figuraTransporte: FiguraTransporte;
  version: string;
  transpInternac: string;
  entradaSalidaMerc: string;
  paisOrigenDestino: string;
  viaEntradaSalida: string;
  totalDistRec: number;
}
