export class Domicilio {
  idDomicilio: number;
  calle: string;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  localidad: string;
  referencia: string;
  municipio: string;
  estado: string;
  pais: string;
  codigoPostal: string;
}
