import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Consignatario } from '../model/consignatario';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class ConsignatariosService {

  private urlEndPoint: string = URL_BACKEND + "/consignatario";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listConsignatarios(): Observable<Consignatario[]>{
    return this.http.get<Consignatario[]>(this.urlEndPoint + '/list');
  }

  pageConsignatarios(page: number): Observable<any>{
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getConsignatario(idConsignatario: number): Observable<Consignatario>{
    return this.http.get<Consignatario>(this.urlEndPoint + '/' + idConsignatario).pipe(
      catchError(e => {
        if(e.status != 401){
          this.router.navigate(['/consignatarios']);
        }
        return throwError(e);
      })
    );
  }

  createConsignatario(consignatario: Consignatario): Observable<Consignatario>{
    return this.http.post(this.urlEndPoint, consignatario).pipe(
      map(
        (response: any) => response.consignatario as Consignatario
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateConsignatario(consignatario: Consignatario): Observable<any>{
    return this.http.put<any>(this.urlEndPoint, consignatario).pipe(
      map(
        (response: any) => response.consignatario as Consignatario
      ),
      catchError(e => {
        if(e.status == 400){
          return throwError(e);
        }
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
