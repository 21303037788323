import { Injectable } from '@angular/core';
import { Usuario } from '../model/usuario';
import { Rol } from '../model/rol';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URL_BACKEND } from '../config/config';
import { AccountInfo } from "@azure/msal-common";

const PASSWORD = 'Admin2021';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _usuario: Usuario;
  private _token: string;
  private _account: AccountInfo;
  private _rol: string;

  constructor(
    private http: HttpClient
  ) { }

  public get usuario(): Usuario {
    if (this._usuario != null) {
      return this._usuario;
    } else if (this._usuario == null && sessionStorage.getItem('usuario') != null) {
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
      return this._usuario;
    }
    return new Usuario();
  }

  public get token(): string {
    if (this._token != null) {
      return this._token;
    } else if (this._token == null && sessionStorage.getItem('token') != null) {
      this._token = sessionStorage.getItem('token');
      return this._token;
    }
    return null;
  }

  public get account(): AccountInfo {
    if(this._account != null) {
      return this._account;
    } else if(this._account == null && sessionStorage.getItem('account') != null) {
      this._account = JSON.parse(sessionStorage.getItem('account')) as AccountInfo;
      return this._account;
    }
    return null;
  }

  public get rolName(): string {
    if (this._usuario == null && sessionStorage.getItem('usuario') != null) {
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
    }
    return this._usuario.rol.nombre;
  }


  public get rol(): string {
    if (this._usuario == null && sessionStorage.getItem('usuario') != null) {
      this._usuario = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
    }
    var rol = '';
    switch(this._usuario.rol.nombre) {
      case 'ROLE_ADMIN':
        rol = 'Administrador';
        break;
      case 'ROLE_TRAFICO_TERRESTRE':
        rol = 'Tráfico Terrestre';
        break;
      case 'ROLE_EJECUTIVO':
        rol = 'Ejecutivo';
        break;
      case 'ROLE_CUENTAS_PAGAR':
        rol = 'Cuentas por Pagar';
        break;
      case 'ROLE_CUENTAS_COBRAR':
        rol = 'Cuentas por Cobrar';
        break;
      case 'ROLE_ANALISTA':
        rol = 'Analista';
        break;
      case 'ROLE_FACTURACION':
        rol = 'Facturación';
        break;
      case 'ROLE_TRAFICO_AEREO_IMPO':
        rol = 'Tráfico Aéreo Importación';
        break;
      case 'ROLE_TRAFICO_AEREO_EXPO':
        rol = 'Tráfico Aéreo Exportación';
        break;
      case 'ROLE_ADMIN_PROVEEDORES':
        rol = 'Administrador de Proveedores';
        break;
      case 'ROLE_SUPERADMIN':
        rol = 'Super Administrador';
        break;
      default:
        rol = 'Sin Rol';
    }
    return rol;
  }

  isAuthenticated(): boolean {
    let payload = this.obtenerDatosToken(this.token);
    if (payload != null && payload.user_name && payload.user_name.length > 0) {
      return true;
    }
    return false;
  }

  hasRole(role: string): boolean {
    if (this.usuario.rol.nombre == role) {
      return true;
    }
    return false;
  }

  login(usuario: string): Observable<any> {
    const urlLogin = URL_BACKEND + '/oauth/token';
    const credenciales = btoa('multilog-app:Multilog2021');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + credenciales
    });
    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', usuario);
    params.set('password', PASSWORD);
    return this.http.post<any>(urlLogin, params.toString(), { headers: httpHeaders });
  }

  logout(): void {
    this._token = null;
    this._usuario = null;
    this._account = null;
    sessionStorage.clear();
  }

  guardarUsuario(response: any): void {
    let payload = this.obtenerDatosToken(response.access_token);
    if (payload != null) {
      this._usuario = new Usuario();
      this._usuario.idUsuario = response.idUsuario;
      this._usuario.nombre1 = response.nombre1;
      this._usuario.nombre2 = response.nombre2;
      this._usuario.nombre3 = response.nombre3;
      this._usuario.apellidoPaterno = response.apellidoPaterno;
      this._usuario.apellidoMaterno = response.apellidoMaterno;
      this._usuario.correoElectronico = response.correoElectronico;
      this._usuario.username = payload.user_name;
      var rol: Rol = new Rol();
      rol.nombre = payload.authorities[0];
      this._usuario.rol = rol;
      this._usuario.menu = payload.menu;
      sessionStorage.setItem('usuario', JSON.stringify(this._usuario));
    }
  }

  guardarToken(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem('token', accessToken);
  }

  guardarAccount(account: AccountInfo): void {
    this._account = account;
    sessionStorage.setItem('account', JSON.stringify(account));
  }

  obtenerDatosToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split(".")[1]));
    }
    return null;
  }

}
