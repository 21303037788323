import { Injectable } from '@angular/core';
import { URL_MY_SUITE } from '../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Servicio } from '../model/servicio';
import { TimbradoRequest } from '../model/timbrado/timbrado-request';
import { DatosResponse } from '../model/timbrado/datos-response';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as fs from 'file-saver';
import { ComprobanteRequest } from '../model/timbrado/comprobante-request';
import { DatosRequest } from '../model/timbrado/datos-request';
import { EmisorRequest } from '../model/timbrado/emisor-request';
import { ReceptorRequest } from '../model/timbrado/receptor-request';
import { ConceptosRequest } from '../model/timbrado/conceptos-request';
import { ImpuestoTotalRequest } from '../model/timbrado/impuesto-total-request';
import { ImpuestoRequest } from '../model/timbrado/impuesto-request';
import { ImpuestoConceptoRequest } from '../model/timbrado/impuesto-concepto-request';
import { RetencionRequest } from '../model/timbrado/retencion-request';
import { TrasladoRequest } from '../model/timbrado/traslado-request';
import { ComplementoRequest } from '../model/timbrado/complemento-request';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class TimbradoService {

  tipo: string = 'PDF';
  transaccion: string = 'CartaPorte2';
  requestor: string = '33333333-d4f1-47bc-9fb4-77995f9bf333';
  pruebaRequest: string;

  constructor(
    private http: HttpClient
  ) {
    this.leerJson();
  }

  leerJson() {
      const req = new XMLHttpRequest();
      req.open('GET', `assets/static/prueba.json`);

      req.onload = () => {
        this.pruebaRequest = req.responseText;
        //console.log(this.pruebaRequest);
      };

      req.send();
  }

  getPdf(timbradoRequest: string): Observable<DatosResponse> {
    const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
            })
        };
    return this.http.post(URL_MY_SUITE, timbradoRequest, httpOptions).pipe(
      map(
        (response: any) => JSON.parse(response) as DatosResponse
      ),
      catchError(e => {
        console.log(e);
        swal(e.message, 'error');
        return throwError(e);
      })
    );
  }

  pruebaTimbrarServicio(servicio: Servicio): void {
    this.getPdf(this.pruebaRequest).subscribe(
      respuestaPdf => {
        console.log(respuestaPdf);
        var datos: string = respuestaPdf.Datos2;
        var binary = atob(datos.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        var blob = new Blob( [view], { type: "application/pdf" });
        fs.saveAs(blob, 'Servicio-' + servicio.idServicioStr + '.pdf');
      }
    )
  }

  timbrarServicio(servicio: Servicio): void {
    var timbradoRequest: TimbradoRequest = new TimbradoRequest();
    timbradoRequest.Datos2 = 'PDF';
    timbradoRequest.Transaccion = 'CartaPorte2';
    timbradoRequest.Requestor = '33333333-d4f1-47bc-9fb4-77995f9bf333';
    timbradoRequest.RFC = 'JES900109Q90';

    var datos1: DatosRequest = new DatosRequest();

    var comprobante: ComprobanteRequest = new ComprobanteRequest();
    comprobante.Sucursal = '';
    comprobante.Subtotal = '';
    comprobante.Moneda = '';
    comprobante.Total = '';
    comprobante.TipoDeComprobante = 'FACTURA';
    comprobante.LugarExpedicion = '';
    comprobante.MetodoDePago = '';
    comprobante.CondicionesDePago = '';
    comprobante.FormaDePago = '';
    comprobante.TotalEnLetra = '';
    datos1.Comprobante = comprobante;

    var emisor: EmisorRequest = new EmisorRequest();
    emisor.Rfc = 'JES900109Q90';
    emisor.Nombre = 'EMPRESA DE ASP MYSUITE';
    emisor.RegimenFiscal = '601';
    emisor.CodigoPostal = '54000';
    emisor.Calle = 'La gran vía';
    emisor.Municipio = 'Tlalnepantla de Baz';
    emisor.Estado = 'Estado de México';
    emisor.Pais = 'México';
    datos1.Emisor = emisor;

    var receptor: ReceptorRequest = new ReceptorRequest();
    receptor.Rfc = servicio.cliente.rfc;
    receptor.Nombre = servicio.cliente.nombre1 + ' ' + servicio.cliente.nombre2;
    receptor.UsoCfdi = '';
    datos1.Receptor = receptor;

    var conceptos: ConceptosRequest[] = [];
    var concepto: ConceptosRequest = new ConceptosRequest();
    concepto.ClaveProdServ = '';
    concepto.Cantidad = '';
    concepto.ClaveUnidad = '';
    concepto.Descripcion = '';
    concepto.ValorUnitario = '';
    concepto.Importe = '';

    var impuestos: ImpuestoRequest[] = [];

    var impuesto: ImpuestoRequest = new ImpuestoRequest();

    var impuestosConceptos: ImpuestoConceptoRequest[] = [];

    var impuestoConcepto: ImpuestoConceptoRequest = new ImpuestoConceptoRequest();
    impuestoConcepto.Tipo = '';
    impuestoConcepto.Base = '';
    impuestoConcepto.Impuesto = '';
    impuestoConcepto.TipoFactor = '';
    impuestoConcepto.TasaOCuota = '';
    impuestoConcepto.Importe = '';

    impuestosConceptos.push(impuestoConcepto);

    impuesto.ImpuestoConcepto = impuestosConceptos;

    impuestos.push(impuesto);

    concepto.Impuestos = impuestos;

    conceptos.push(concepto);

    datos1.Conceptos = conceptos;

    var impuestosTotales: ImpuestoTotalRequest[] = [];

    var impuestoTotal: ImpuestoTotalRequest = new ImpuestoTotalRequest();
    impuestoTotal.TotalImpuestosRetenidos = '';
    impuestoTotal.TotalImpuestosTrasladados = '';

    var retenciones: RetencionRequest[] = [];

    var retencion: RetencionRequest = new RetencionRequest();
    retencion.Impuesto = '';
    retencion.Importe = '';

    retenciones.push(retencion);

    impuestoTotal.Retenciones = retenciones;

    var traslados: TrasladoRequest[] = [];

    var traslado: TrasladoRequest = new TrasladoRequest();
    traslado.Impuesto = '';
    traslado.TipoFactor = '';
    traslado.TasaOCuota = '';
    traslado.Importe = '';

    traslados.push(traslado);

    impuestoTotal.Traslados = traslados;

    impuestosTotales.push(impuestoTotal);

    datos1.ImpuestosTotales = impuestosTotales;

    var complemento = new ComplementoRequest();
    complemento.CartaPorte = servicio.cartaPorte;
    datos1.Complemento = complemento;

    timbradoRequest.Datos1 = datos1;

    console.log(timbradoRequest);

    this.getPdf(JSON.stringify(timbradoRequest)).subscribe(
      respuestaPdf => {
        var datos: string = respuestaPdf.Datos2;
        var binary = atob(datos.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        var blob = new Blob( [view], { type: "application/pdf" });
        fs.saveAs(blob, 'Servicio-' + servicio.idServicioStr + '.pdf');
      }
    )
  }

}
