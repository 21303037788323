import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Cliente } from '../../../model/cliente';
import { ClientesService } from '../../../services/clientes.service';
import { DetalleClienteService } from './detalle-cliente/detalle-cliente.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';
import { EjecutivosModalService } from './ejecutivos/ejecutivos-modal.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientesComponent implements OnInit {
  public listaClientes: Cliente[];
  public clientesTemp: Cliente[];
  public clienteSeleccionado: Cliente;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private clientesService: ClientesService,
    private detalleService: DetalleClienteService,
    private ejecutivosModalService: EjecutivosModalService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Pagadores');
  }

  ngOnInit() {
    this.clienteSeleccionado = null;
    this.cargaClientes();
  }

  private cargaClientes(): void {
    this.clientesService.listClientes().subscribe(
      response => {
        this.listaClientes = response;
        this.clientesTemp = response;
      }
    );
  }

  abrirModal(cliente: Cliente): void {
    this.clienteSeleccionado = cliente;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.clientesTemp.filter(function(d) {
          return d.idCliente == val || d.nombre1.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaClientes = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

  abrirEjecutivos(idCliente: number): void {
    console.log('Buscando cliente: ' + idCliente);
    this.clientesService.getCliente(idCliente).subscribe(
      clienteEncontrado => {
        this.clienteSeleccionado = clienteEncontrado;
        this.ejecutivosModalService.abrirModal();
      }
    )
  }

}
