import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

const swal = require('sweetalert');

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router : Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(e => {
        if(e.status == 401){
          if(this.authService.isAuthenticated()){
            this.authService.logout();
          }
          this.router.navigate(['/signin']);
        }
        if(e.status == 403){
          swal('Acceso Denegado', `Hola ${this.authService.usuario.nombre1} ${this.authService.usuario.apellidoPaterno} no tienes acceso a este recurso!`, 'warning');
          this.router.navigate(['/signin']);
        }
        return throwError(e);
      })
    );
  }
}
