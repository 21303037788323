import { Component, OnInit, Input } from '@angular/core';
import { CartaPorte } from '../../model/cartaporte/carta-porte';
import { UbicacionCp } from '../../model/cartaporte/ubicacion-cp';
import { Domicilio } from '../../model/cartaporte/domicilio';
import { Mercancia } from '../../model/cartaporte/mercancia';
import { Mercancias } from '../../model/cartaporte/mercancias';
import { Pedimento } from '../../model/cartaporte/pedimento';
import { GuiaIdentificacion } from '../../model/cartaporte/guia-identificacion';
import { CantidadTransportada } from '../../model/cartaporte/cantidad-transportada';
import { DetalleMercancia } from '../../model/cartaporte/detalle-mercancia';
import { Autotransporte } from '../../model/cartaporte/autotransporte';
import { Seguro } from '../../model/cartaporte/seguro';
import { Remolque } from '../../model/cartaporte/remolque';
import { TransporteMaritimo } from '../../model/cartaporte/transporte-maritimo';
import { ContenedorMaritimo } from '../../model/cartaporte/contenedor-maritimo';
import { TransporteFerroviario } from '../../model/cartaporte/transporte-ferroviario';
import { DerechoDePaso } from '../../model/cartaporte/derecho-de-paso';
import { Carro } from '../../model/cartaporte/carro';
import { ContenedorFerroviario } from '../../model/cartaporte/contenedor-ferroviario';
import { ParteTransporte } from '../../model/cartaporte/parte-transporte';
import { TipoFigura } from '../../model/cartaporte/tipo-figura';
import { CartaPorteService } from './carta-porte.service';
import { EnumsService } from '../../services/enums.service';
import { CartasPorteService } from '../../services/cartas-porte.service';
import { ExcelService } from '../../services/excel.service';
import { HttpEventType } from '@angular/common/http';

const swal = require('sweetalert');

@Component({
  selector: ' carta-porte',
  templateUrl: './carta-porte.component.html',
  styleUrls: ['./carta-porte.component.scss']
})
export class CartaPorteComponent implements OnInit {

  @Input()
  cartaPorte: CartaPorte;
  @Input()
  idServicio: number;
  @Input()
  tipoServicio: number;

  paises: string[];
  monedas: string[];
  tiposPermiso: string[];
  configsVehicular: string[];
  subtiposRem: string[];
  tiposContenedorM: string[];
  configsMaritima: string[];
  tiposCarga: string[];
  numerosAutorizacion: string[];
  codigosTransportista: string[];
  tiposDerecho: string[];
  tiposTrafico: string[];
  tiposServicio: string[];
  tiposContenedorF: string[];
  tiposCarro: string[];
  iSeleccionada: number;
  domicilioSeleccionado: Domicilio;
  panelPrincipal: boolean = true;
  panelMercancia: boolean = false;
  panelImportar: boolean = false;
  panelImportarXml: boolean = false;
  rfcRemitente: string;
  nombreRemitente: string;
  numRegIdTrib: string;
  residenciaFiscal: string;
  numEstacion: string;
  nombreEstacion: string;
  navegacionTrafico: string;
  fechaHoraSalida: string;
  rfcDestinatario: string;
  nombreDestinatario: string;
  fechaHoraProgLlegada: string;
  calle: string;
  numeroExterior: string;
  numeroInterior: string;
  colonia: string;
  localidad: string;
  referencia: string;
  municipio: string;
  estado: string;
  pais: string;
  codigoPostal: string;
  habilitaCarga: boolean = false;
  cargandoArchivo: boolean = false;
  archivoSeleccionado: File;
  indexMercancia: number;
  mercanciaDetallada: Mercancia;
  archivoXmlSeleccionado: File;
  habilitaCargaXml: boolean = false;

  constructor(
    public cartaPorteService: CartaPorteService,
    public enumsService: EnumsService,
    private cartasPorteService: CartasPorteService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.getPaises();
    this.getMonedas();
    this.getTiposPermiso();
    this.getConfigsVehicular();
    this.getSubtiposRem();
    this.getTiposContenedorM();
    this.getConfigMaritima();
    this.getTiposCarga();
    this.getNumerosAutorizacion();
    this.getCodigosTransportista();
    this.getTiposServicio();
    this.getTiposTrafico();
    this.getTiposDerecho();
    this.getTiposContenedorF();
    this.getTiposCarro();

    this.excelService.saveEmitter.subscribe(
      cartaPorteImp => {
        console.log(cartaPorteImp);
        this.cartaPorte = cartaPorteImp;
        this.cancelarImportar();
      }
    );

  }

  cancelar(): void {
    this.cartaPorteService.cerraModal();
  }

  getPaises(): void {
    this.enumsService.listPaises().subscribe(
      response => this.paises = response
    )
  }

  getMonedas(): void {
    this.enumsService.listMonedas().subscribe(
      response => this.monedas = response
    )
  }

  getTiposPermiso(): void {
    this.enumsService.listTiposPermiso().subscribe(
      response => this.tiposPermiso = response
    )
  }

  getConfigsVehicular(): void {
    this.enumsService.listConfigsAutotransporte().subscribe(
      response => this.configsVehicular = response
    )
  }

  getSubtiposRem(): void {
    this.enumsService.listSubsTipoRem().subscribe(
      response => this.subtiposRem = response
    )
  }

  getTiposContenedorM(): void {
    this.enumsService.listContenedoresMaritimo().subscribe(
      response => this.tiposContenedorM = response
    )
  }

  getConfigMaritima(): void {
    this.enumsService.listConfigsMaritima().subscribe(
      response => this.configsMaritima = response
    )
  }

  getTiposCarga(): void {
    this.enumsService.listClavesTipoCarga().subscribe(
      response => this.tiposCarga = response
    )
  }

  getNumerosAutorizacion(): void {
    this.enumsService.listNumsAutorizacionNaviero().subscribe(
      response => this.numerosAutorizacion = response
    )
  }

  getCodigosTransportista(): void {
    this.enumsService.listCodigosTransporteAereo().subscribe(
      response => this.codigosTransportista = response
    )
  }

  getTiposServicio(): void {
    this.enumsService.listTiposDeServicio().subscribe(
      response => this.tiposServicio = response
    )
  }

  getTiposTrafico(): void {
    this.enumsService.listTiposDeTrafico().subscribe(
      response => this.tiposTrafico = response
    )
  }

  getTiposDerecho(): void {
    this.enumsService.listDerechosDePaso().subscribe(
      response => this.tiposDerecho = response
    )
  }

  getTiposContenedorF(): void {
    this.enumsService.listContenedores().subscribe(
      response => this.tiposContenedorF = response
    )
  }

  getTiposCarro(): void {
    this.enumsService.listTiposCarro().subscribe(
      response => this.tiposCarro = response
    )
  }

  compararString(o1: String, o2: String) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1 === o2;
  }

  agregarUbicacion(): void {
    var ubicacion: UbicacionCp = new UbicacionCp();
    ubicacion.domicilio = new Domicilio();
    if (this.cartaPorte.ubicaciones == null) {
      this.cartaPorte.ubicaciones = [];
    }
    this.cartaPorte.ubicaciones.push(ubicacion);
  }

  eliminarUbicacion(ubicacion: UbicacionCp): void {
    const index: number = this.cartaPorte.ubicaciones.indexOf(ubicacion);
    if (index !== -1) {
      this.cartaPorte.ubicaciones.splice(index, 1);
      swal('Ubicación eliminada', 'Ubicación eliminada con exito', 'success');
    }
  }

  agregarMercancia(): void {
    var mercancia: Mercancia = new Mercancia();
    mercancia.cantidadesTransportadas = [];
    mercancia.detalleMercancia = new DetalleMercancia();
    if (this.cartaPorte.mercancias == null) {
      this.cartaPorte.mercancias = new Mercancias();
    }
    if (this.cartaPorte.mercancias.mercancia == null) {
      this.cartaPorte.mercancias.mercancia = [];
    }
    this.cartaPorte.mercancias.mercancia.push(mercancia);
  }

  agregarPedimento(mercancia: Mercancia): void {
    var pedimento: Pedimento = new Pedimento();
    if (mercancia.pedimentos == null) {
      mercancia.pedimentos = [];
    }
    mercancia.pedimentos.push(pedimento);
  }

  eliminarPedimento(mercancia: Mercancia, pedimento: Pedimento): void {
    const index: number = mercancia.pedimentos.indexOf(pedimento);
    if (index !== -1) {
      mercancia.pedimentos.splice(index, 1);
      swal('Pedimento eliminado', 'Pedimento eliminado con exito', 'success');
    }
  }

  agregarGuia(mercancia: Mercancia): void {
    var guia: GuiaIdentificacion = new GuiaIdentificacion();
    if (mercancia.guiasIdentificacion == null) {
      mercancia.guiasIdentificacion = [];
    }
    mercancia.guiasIdentificacion.push(guia);
  }

  eliminarGuia(mercancia: Mercancia, guia: GuiaIdentificacion): void {
    const index: number = mercancia.guiasIdentificacion.indexOf(guia);
    if (index !== -1) {
      mercancia.guiasIdentificacion.splice(index, 1);
      swal('Guia Identificación eliminada', 'Guia Identificación eliminada con exito', 'success');
    }
  }

  agregarCantidad(mercancia: Mercancia): void {
    var cantidadTrans: CantidadTransportada = new CantidadTransportada();
    if (mercancia.cantidadesTransportadas == null) {
      mercancia.cantidadesTransportadas = [];
    }
    mercancia.cantidadesTransportadas.push(cantidadTrans);
  }

  eliminarCantidad(mercancia: Mercancia, cantidad: CantidadTransportada): void {
    const index: number = mercancia.cantidadesTransportadas.indexOf(cantidad);
    if (index !== -1) {
      mercancia.cantidadesTransportadas.splice(index, 1);
      swal('Cantidad Transportada eliminada', 'Cantidad Transportada eliminada con exito', 'success');
    }
  }

  agregarSeguro(autotransporte: Autotransporte): void {
    var seguro: Seguro = new Seguro();
    if (autotransporte.seguros == null) {
      autotransporte.seguros = [];
    }
    autotransporte.seguros.push(seguro);
  }

  eliminarSeguro(seguro: Seguro): void {
    const index: number = this.cartaPorte.mercancias.autotransporte.seguros.indexOf(seguro);
    if (index !== -1) {
      this.cartaPorte.mercancias.autotransporte.seguros.splice(index, 1);
      swal('Seguro eliminado', 'Seguro eliminado con exito', 'success');
    }
  }

  agregarRemolque(autotransporte: Autotransporte): void {
    var remolque: Remolque = new Remolque();
    if (autotransporte.remolques == null) {
      autotransporte.remolques = [];
    }
    autotransporte.remolques.push(remolque);
  }

  eliminarRemolque(remolque: Remolque): void {
    const index: number = this.cartaPorte.mercancias.autotransporte.remolques.indexOf(remolque);
    if (index !== -1) {
      this.cartaPorte.mercancias.autotransporte.remolques.splice(index, 1);
      swal('Remolque eliminado', 'Remolque eliminado con exito', 'success');
    }
  }

  agregarContenedorM(transporteMaritimo: TransporteMaritimo): void {
    var contenedorM: ContenedorMaritimo = new ContenedorMaritimo();
    if (transporteMaritimo.contenedores == null) {
      transporteMaritimo.contenedores = [];
    }
    transporteMaritimo.contenedores.push(contenedorM);
  }

  eliminarContenedorM(contenedor: ContenedorMaritimo): void {
    const index: number = this.cartaPorte.mercancias.transporteMaritimo.contenedores.indexOf(contenedor);
    if (index !== -1) {
      this.cartaPorte.mercancias.transporteMaritimo.contenedores.splice(index, 1);
      swal('Contenedor Maritimo eliminado', 'Contenedor Maritimo eliminado con exito', 'success');
    }
  }

  agregarDerecho(transporteFerroviario: TransporteFerroviario): void {
    var derecho: DerechoDePaso = new DerechoDePaso();
    if (transporteFerroviario.derechosDePaso == null) {
      transporteFerroviario.derechosDePaso = [];
    }
    transporteFerroviario.derechosDePaso.push(derecho);
  }

  eliminarDerecho(derecho: DerechoDePaso): void {
    const index: number = this.cartaPorte.mercancias.transporteFerroviario.derechosDePaso.indexOf(derecho);
    if (index !== -1) {
      this.cartaPorte.mercancias.transporteFerroviario.derechosDePaso.splice(index, 1);
      swal('Derecho de Paso eliminado', 'Derecho de Paso eliminado con exito', 'success');
    }
  }

  agregarCarro(transporteFerroviario: TransporteFerroviario): void {
    var carro: Carro = new Carro();
    if (transporteFerroviario.carros == null) {
      transporteFerroviario.carros = [];
    }
    transporteFerroviario.carros.push(carro);
  }

  agregarContenedorF(carro: Carro): void {
    var contenedor: ContenedorFerroviario = new ContenedorFerroviario();
    if (carro.contenedores == null) {
      carro.contenedores = [];
    }
    carro.contenedores.push(contenedor);
  }

  eliminarContenedorF(carro: Carro, contenedor: ContenedorFerroviario): void {
    const index: number = carro.contenedores.indexOf(contenedor);
    if (index !== -1) {
      carro.contenedores.splice(index, 1);
      swal('Contenedor Ferroviario eliminado', 'Contenedor Ferroviario eliminado con exito', 'success');
    }
  }

  agregarTipoFigura(): void {
    var tipoFigura: TipoFigura = new TipoFigura();
    if (this.cartaPorte.figuraTransporte.tiposFigura == null) {
      this.cartaPorte.figuraTransporte.tiposFigura = [];
    }
    this.cartaPorte.figuraTransporte.tiposFigura.push(tipoFigura);
  }

  agregarParte(tipoFigura: TipoFigura) {
    var parte: ParteTransporte = new ParteTransporte();
    parte.domicilio = new Domicilio();
    if (tipoFigura.partesTransporte == null) {
      tipoFigura.partesTransporte = [];
    }
    tipoFigura.partesTransporte.push(parte);
  }

  eliminarParte(tipoFigura: TipoFigura, parte: ParteTransporte): void {
    const index: number = tipoFigura.partesTransporte.indexOf(parte);
    if (index !== -1) {
      tipoFigura.partesTransporte.splice(index, 1);
      swal('Parte Transporte eliminada', 'Parte Transporte eliminada con exito', 'success');
    }
  }

  guardarCP(): void {
    console.log(this.cartaPorte);
    if (this.cartaPorte.idCartaPorte == null) {
      this.cartasPorteService.createCartaPorte(this.cartaPorte).subscribe(
        cartaPorte => {
          this.cartaPorte.idCartaPorte = cartaPorte.idCartaPorte;
          this.cartasPorteService.updateServicioCartaPorte(this.idServicio, this.cartaPorte.idCartaPorte, this.tipoServicio).subscribe(
            () => {
              swal('Carta Porte guardado', 'Carta  porte guardado con exito', 'success');
              this.cartaPorteService.cerraModal();
            }
          );
        }
      );
    } else {
      this.cartasPorteService.updateCartaPorte(this.cartaPorte).subscribe(
        () => {
          this.cartasPorteService.updateServicioCartaPorte(this.idServicio, this.cartaPorte.idCartaPorte, this.tipoServicio).subscribe(
            () => {
              swal('Carta Porte actualizado', 'Carta  porte actualizado con exito', 'success');
              this.cartaPorteService.cerraModal();
            }
          );
        }
      );
    }
  }

  exportar(): void {
    this.excelService.title = 'Carta Porte';
    this.excelService.exportCartaPorte(this.cartaPorte);
  }

  importar(): void {
    this.panelPrincipal = false;
    this.panelImportar = true;
  }

  cancelarImportar(): void {
    this.panelPrincipal = true;
    this.panelImportar = false;
  }

  seleccionarArchivo(event: any) {
    if (event.target.files.length > 0) {
      this.archivoSeleccionado = event.target.files[0];
      if (this.archivoSeleccionado.type.indexOf('sheet') < 0) {
        swal('Error Tipo Archivo', 'Debe seleccionar un archivo excel', 'error');
        this.archivoSeleccionado = null;
        this.habilitaCarga = false;
      } else {
        this.habilitaCarga = true;
        this.cargandoArchivo = false;
      }
    }
  }

  cargarArchivo(): void {
    this.cargandoArchivo = true;
    this.excelService.cargarCartaPorte(this.cartaPorte, this.archivoSeleccionado);
  }

  detalleMercancia(mercancia: Mercancia): void {
    this.indexMercancia = this.cartaPorte.mercancias.mercancia.indexOf(mercancia);
    this.mercanciaDetallada = Object.assign({}, mercancia);
    this.panelMercancia = true;
  }

  eliminarMercancia(mercancia: Mercancia): void {
    const index: number = this.cartaPorte.mercancias.mercancia.indexOf(mercancia);
    if (index !== -1) {
      this.cartaPorte.mercancias.mercancia.splice(index, 1);
      swal('Mercancia eliminada', 'Mercancia eliminada con exito', 'success');
    }
  }

  cerrarDetalle(): void {
    this.indexMercancia = null;
    this.mercanciaDetallada = null;
    this.panelMercancia = false;
  }

  guardarDetalle(mercanciaDet: Mercancia): void {
    this.cartaPorte.mercancias.mercancia[this.indexMercancia] = Object.assign({}, mercanciaDet);
    this.cerrarDetalle();
  }

  importarXml(): void {
    this.panelPrincipal = false;
    this.panelImportarXml = true;
  }

  seleccionarArchivoXml(event: any) {
    if (event.target.files.length > 0) {
      this.archivoXmlSeleccionado = event.target.files[0];
      console.log(this.archivoXmlSeleccionado);
      if (this.archivoXmlSeleccionado.type.indexOf('xml') < 0) {
        swal('Error Tipo Archivo', 'Debe seleccionar un archivo xml', 'error');
        this.archivoXmlSeleccionado = null;
        this.habilitaCargaXml = false;
      } else {
        this.habilitaCargaXml = true;
      }
    }
  }

  cargarArchivoXml(): void {
    if (!this.archivoXmlSeleccionado) {
      swal('Error al Seleccionar', 'Debe seleccionar un archivo XML', 'error');
    } else {
      this.cartasPorteService.uploadXml(this.archivoXmlSeleccionado, this.cartaPorte.idCartaPorte).subscribe(
        event => {
          if (event.type === HttpEventType.Response) {
            let response: any = event.body;
            //this.usuario = response.usuario as Usuario;
            swal('Carga completa!', response.mensaje, 'success');
          }
        }
      )
    }
  }

  cancelarImportarXml(): void {
    this.panelPrincipal = true;
    this.panelImportarXml = false;
  }

}
