import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { Router } from '@angular/router';
import { UsuariosService } from '../../../services/usuarios.service';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { GraphService } from '../../../services/graph.service';
import { Rol } from '../../../model/rol';

const swal = require('sweetalert');

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  totalGrupos: number = 0;
  contadorGrupo: number = 0;
  isIframe = false;
  roles: Rol[] = [];
  rolSeleccionado: Rol;
  showRoles: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    public authService: AuthService,
    public usuariosService: UsuariosService,
    private router: Router,
    private graphService: GraphService
  ) {
  }

  login() {
    if (this.msalGuardConfig.authRequest){
      this.msalService.loginPopup({...this.msalGuardConfig.authRequest} as RedirectRequest).subscribe(
        {
          next: (result) => {
            this.authService.guardarAccount(result.account);
            this.getGruposUsuario();
          },
          error: (error) => console.log(error)
        }
      );
    } else {
      this.msalService.loginPopup().subscribe(
        {
          next: (result) => {
            this.authService.guardarAccount(result.account);
            this.getGruposUsuario();
          },
          error: (error) => console.log(error)
        }
      );
    }
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    if (this.authService.isAuthenticated()) {
      swal('Login', `Hola ${this.authService.account.name} ya estas logeado`, 'info');
      this.router.navigate(['/dashboard']);
    }
  }

  getGruposUsuario(): void {

    this.graphService.getUserGroups().subscribe(
      userGroups => {
        if(userGroups.value) {
          this.totalGrupos = userGroups.value.length;
          userGroups.value.map( grupo => {
            this.graphService.getGrupo(grupo.id).subscribe(group => {
              this.contadorGrupo++;
              if(group.displayName.indexOf("ROLE_") > -1) {
                var newRol: Rol = new Rol();
                newRol.nombre = group.displayName;
                switch(group.displayName) {
                  case 'ROLE_ADMIN':
                    newRol.descripcion = 'Administrador';
                    break;
                  case 'ROLE_ADMIN_PROVEEDORES':
                    newRol.descripcion = 'Administrador de Proveedores';
                    break;
                  case 'ROLE_ANALISTA':
                    newRol.descripcion = 'Analista';
                    break;
                  case 'ROLE_CUENTAS_COBRAR':
                    newRol.descripcion = 'Cuentas por Cobrar';
                    break;
                  case 'ROLE_CUENTAS_PAGAR':
                    newRol.descripcion = 'Cuentas por Pagar';
                    break;
                  case 'ROLE_EJECUTIVO':
                    newRol.descripcion = 'Ejecutivo';
                    break;
                  case 'ROLE_FACTURACION':
                    newRol.descripcion = 'Facturación';
                    break;
                  case 'ROLE_SUPERADMIN':
                    newRol.descripcion = 'Super Administrador';
                    break;
                  case 'ROLE_TRAFICO_AEREO_EXPO':
                    newRol.descripcion = 'Tráfico Aéreo Exportación';
                    break;
                  case 'ROLE_TRAFICO_AEREO_IMPO':
                    newRol.descripcion = 'Tráfico Aéreo Importación';
                    break;
                  case 'ROLE_TRAFICO_TERRESTRE':
                    newRol.descripcion = 'Tráfico Terrestre';
                    break;
                }
                ///Mostrar roles disponibles si es mas de 1 mostrar combo a seleccionar sino hacer login con el unico
                this.roles.push(newRol);
              }
              if(this.totalGrupos == this.contadorGrupo) {
                if(this.roles.length == 1) {
                  this.rolSeleccionado = this.roles[0];
                  this.logeaUsuario();
                } else if(this.roles.length > 1) {
                  this.showRoles = true;
                }
              }
            });
          });
        }
      }
    );
  }

  //Hacer login con rol seleccionado
  logeaUsuario(): void {
    if(this.rolSeleccionado != null && this.rolSeleccionado != undefined) {
      this.authService.login(this.rolSeleccionado.nombre).subscribe(
        response => {
          this.authService.guardarUsuario(response);
          this.authService.guardarToken(response.access_token);
          this.graphService.getPhoto();
          swal('Login!', `Hola ${this.authService.account.name}, has iniciado sesion correctamente`, 'success');
          this.router.navigate(['/dashboard']);
        },
        err => {
          if (err.status == 400) {
            swal('Error Login!', 'Usuario y/o Password incorrectos', 'error');
          }
        }
      );
    } else {
      swal('Rol no seleccionado!', 'Se debe seleccionar un rol', 'warning');
    }
  }

}
