import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { Consignatario } from '../../model/consignatario';
import { Agente } from '../../model/agente';
import { ServicioInter } from '../../model/servicio-inter';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConsignatariosService } from '../../services/consignatarios.service';
import { AgentesService } from '../../services/agentes.service';
import { ServiciosInterService } from '../../services/servicios-inter.service';
import { AuthService } from '../../auth/auth.service';
import { DetalleReasignarInterService } from './detalle-reasignar-inter/detalle-reasignar-inter.service';

@Component({
  selector: 'app-reasignar-servicios-inter',
  templateUrl: './reasignar-servicios-inter.component.html',
  styleUrls: ['./reasignar-servicios-inter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReasignarServiciosInterComponent implements OnInit {

  guiaHouse: string;
  guiaMaster: string;
  listaConsignatarios: Consignatario[];
  listAgentes: Agente[];
  listaServicios: ServicioInter[];
  searching = false;
  _onDestroy = new Subject<void>();
  consignatario: Consignatario;
  consignatarioCtrl: FormControl = new FormControl();
  consignatarioFilterCtrl: FormControl = new FormControl();
  consignatariosFiltrados: ReplaySubject<Consignatario[]> = new ReplaySubject<Consignatario[]>(1);
  agente: Agente;
  agenteCtrl: FormControl = new FormControl();
  agenteFilterCtrl: FormControl = new FormControl();
  agentesFiltrados: ReplaySubject<Agente[]> = new ReplaySubject<Agente[]>(1);
  fechaDesde: NgbDateStruct;
  fechaHasta: NgbDateStruct;
  servicioSeleccionado: ServicioInter

  constructor(
    public pt: PagetitleService,
    private consignatariosService: ConsignatariosService,
    private agentesService: AgentesService,
    private serviciosService: ServiciosInterService,
    public authService: AuthService,
    private detalleReasignarInterService: DetalleReasignarInterService
  ) { }

  ngOnInit() {
    this.cargaConsignatarios();
    this.cargarAgentes();
  }

  private cargaConsignatarios(): void {
    this.consignatariosService.listConsignatarios().subscribe(
      response => {
        this.listaConsignatarios = response;
        this.filtrarConsignatarios();
      }
    );
  }

  private filtrarConsignatarios(): void {
    this.consignatarioFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listaConsignatarios) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listaConsignatarios.filter(consignatario => consignatario.nombre.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(consignatariosFiltrados => {
        this.searching = false;
        this.consignatariosFiltrados.next(consignatariosFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private cargarAgentes(): void {
    this.agentesService.listAgentes().subscribe(
      response => {
        this.listAgentes = response;
        this.filtrarAgentes();
      }
    );
  }

  private filtrarAgentes(): void {
    this.agenteFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listAgentes) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listAgentes.filter(agente => agente.descCorta.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(agentesFiltrados => {
        this.searching = false;
        this.agentesFiltrados.next(agentesFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  limpiar(): void {
    this.guiaHouse = undefined;
    this.guiaMaster = undefined;
    this.consignatario = undefined;
    this.agente = undefined;
    this.fechaDesde = undefined;
    this.fechaHasta = undefined;
  }

  buscar(): void {
    let guiaHouse = null;
    let guiaMaster = null;
    let idConsignatario = 0;
    let idAgente = 0;
    let fechaDesde = null;
    let fechaHasta = null;

    if (this.guiaHouse) {
      guiaHouse = this.guiaHouse;
    }
    if (this.guiaMaster) {
      guiaMaster = this.guiaMaster;
    }
    if (this.consignatario) {
      idConsignatario = this.consignatario.idConsignatario;
    }
    if (this.agente) {
      idAgente = this.agente.idAgente;
    }
    if (this.fechaDesde) {
      fechaDesde = this.fechaDesde.year + '/' +
        (this.fechaDesde.month < 10 ? '0' + this.fechaDesde.month : this.fechaDesde.month) + '/' +
        (this.fechaDesde.day < 10 ? '0' + this.fechaDesde.day : this.fechaDesde.day);
    }
    if (this.fechaHasta) {
      fechaHasta = this.fechaHasta.year + '/' +
        (this.fechaHasta.month < 10 ? '0' + this.fechaHasta.month : this.fechaHasta.month) + '/' +
        (this.fechaHasta.day < 10 ? '0' + this.fechaHasta.day : this.fechaHasta.day);
    }

    var idUsuarioBuscar = null;
      var rolBuscar = null;
    if(!this.authService.hasRole('ROLE_SUPERADMIN') && !this.authService.hasRole('ROLE_CUENTAS_PAGAR') && !this.authService.hasRole('ROLE_ANALISTA')
      && !this.authService.hasRole('ROLE_CUENTAS_COBRAR')) {
      idUsuarioBuscar = this.authService.account.localAccountId;
      rolBuscar = this.authService.rolName;
    }

    this.serviciosService.searchServicio(null, idUsuarioBuscar, guiaHouse, guiaMaster, idConsignatario, idAgente,
      fechaDesde, fechaHasta,rolBuscar,false).subscribe(
        response => { 
          this.listaServicios = response;
        }
      );
  }

  reasignar(servicio: ServicioInter) {
    this.servicioSeleccionado = servicio;
    this.detalleReasignarInterService.abrirModal();
  }

}
