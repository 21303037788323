import { Component, OnInit, Input } from '@angular/core';
import { Incoterm } from '../../../../model/incoterm';
import { DetalleIncotermService } from './detalle-incoterm.service';
import { IncotermsService } from '../../../../services/incoterms.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-incoterm',
  templateUrl: './detalle-incoterm.component.html',
  styleUrls: ['./detalle-incoterm.component.scss']
})
export class DetalleIncotermComponent implements OnInit {

  @Input()
  incoterm: Incoterm;

  constructor(
    public detalleService: DetalleIncotermService,
    private incotermsService: IncotermsService
  ) { }

  ngOnInit() {
  }

  cancelar(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.incotermsService.createIncoterm(this.incoterm).subscribe(
      incoterm => {
        this.detalleService.cerraModal();
        swal('Incoterm Creado', `Incoterm ${incoterm.iniciales} creado con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.incotermsService.updateIncoterm(this.incoterm).subscribe(
      incoterm => {
        this.detalleService.cerraModal();
        swal('Incoterm Actualizado', `Incoterm ${incoterm.iniciales} actualizado con exito`, 'success');
      }
    );
  }

}
