import { Equipo } from './equipo';
import { Contacto } from './contacto';

export class Evaluacion{
  idEvaluacion: number;
  esProveedor: boolean;
  parentId: string;
  comentarios: string;
  equipos: Equipo[];
  contactos: Contacto[];
  diasCredito: number;
  usuario: string;
  giro: string[];
  sociosComerciales: string[];
  coberturaNacional: string[];
  patiosOperacion: string[];
  codigosHazmat: string[];
  servicios: string[];
  unidadesAutAero: string[];
  puertosArrastre: string[];
  fronterasCruce: string[];
  recursosSeguridad: string[];
  certificacionesEmpresa: string[];
  certificadoOperadores: string[];
}
