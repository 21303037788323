import { Component, OnInit, Input } from '@angular/core';
import { Ejecutivo } from '../../../../model/ejecutivo';
import { EjecutivosService } from '../../../../services/ejecutivos.service';
import { DetalleEjecutivoService } from './detalle-ejecutivo.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-ejecutivo',
  templateUrl: './detalle-ejecutivo.component.html',
  styleUrls: ['./detalle-ejecutivo.component.scss']
})
export class DetalleEjecutivoComponent implements OnInit {

  @Input()
  ejecutivo: Ejecutivo;

  constructor(
    private ejecutivosService: EjecutivosService,
    private detalleService: DetalleEjecutivoService
  ) { }

  ngOnInit() {
  }

  cancelar(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.ejecutivosService.createEjecutivo(this.ejecutivo).subscribe(
      ejecutivo => {
        this.detalleService.cerraModal();
        swal('Ejecutivo Creado', `Ejecutivo ${ejecutivo.nombre} ${ejecutivo.apellidoPaterno} creado con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.ejecutivosService.updateEjecutivo(this.ejecutivo).subscribe(
      ejecutivo => {
        this.detalleService.cerraModal();
        swal('Ejecutivo Actualizado', `Ejecutivo ${ejecutivo.nombre} ${ejecutivo.apellidoPaterno} actualizado con exito`, 'success');
      }
    );
  }
}
