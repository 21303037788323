import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { AuthService } from '../../auth/auth.service';
import { GraphService } from '../../services/graph.service';
import { MessageType } from '../../model/graph/message-type';

const swal = require('sweetalert');

@Component({
  selector: 'mensajes-servicio',
  templateUrl: './mensajes-servicio.component.html',
  styleUrls: ['./mensajes-servicio.component.scss']
})
export class MensajesServicioComponent implements OnInit {

  @ViewChild('msgViewModal', { static: true })
  public msgViewModal: ModalDirective;

  @Input()
  idChat: string;

  listMensajes: MessageType[];
  respuesta: string;

  constructor(
    public authService: AuthService,
    public graphService: GraphService
  ) { }

  ngOnInit() {
    this.msgViewModal.onShown.subscribe(() => {
      if(this.idChat) {
        this.consultaMensajes();
      }
    });
  }

  consultaMensajes(): void {
    this.graphService.getChatMessages(this.idChat).subscribe(
      chatMessages => {
        this.listMensajes = chatMessages.value;
        /*if(this.listMensajes != null) {
          this.listMensajes.forEach(mensaje => {
            this.graphService.getMessageReplies(this.idCanal, mensaje.id).subscribe(
              messagesReplies => {
                mensaje.replies = messagesReplies.value;
              }
            );
          });
        }*/
      }
    );
  }

  responderMensaje(): void {
    this.graphService.createChatMessage(this.idChat, this.respuesta).subscribe(
      () => {
        swal('Mensaje Enviado', `Mensaje enviado con exito`, 'success');
        this.consultaMensajes();
      }
    );
  }

}
