import { Component, OnInit, Input } from '@angular/core';
import { TotalesService } from './totales.service';

@Component({
  selector: 'totales',
  templateUrl: './totales.component.html',
  styleUrls: ['./totales.component.scss']
})
export class TotalesComponent implements OnInit {

  @Input()
  detalleTotales = {
    costoTotalOrigenUsd : 0.00,
    costoOrigenUsd : 0.00,
    costoOrigenEur : 0.00,
    costoOrigenGbp : 0.00,
    costoOrigenJpy : 0.00,
    costoOrigenMxn : 0.00,
    ventaTotalOrigenUsd : 0.00,
    ventaOrigenUsd : 0.00,
    ventaOrigenEur : 0.00,
    ventaOrigenGbp : 0.00,
    ventaOrigenJpy : 0.00,
    ventaOrigenMxn : 0.00,
    costoTotalDestinoUsd : 0.00,
    costoDestinoUsd : 0.00,
    costoDestinoEur : 0.00,
    costoDestinoGbp : 0.00,
    costoDestinoJpy : 0.00,
    costoDestinoMxn : 0.00,
    ventaTotalDestinoUsd : 0.00,
    ventaDestinoUsd : 0.00,
    ventaDestinoEur : 0.00,
    ventaDestinoGbp : 0.00,
    ventaDestinoJpy : 0.00,
    ventaDestinoMxn : 0.00
  };

  @Input()
  tipoTotales: string;

  constructor(
    public totalesService: TotalesService
  ) { }

  ngOnInit() {
  }

  cerrar(): void {
    this.totalesService.cerraModal();
  }

}
