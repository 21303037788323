import { Pedimento } from './pedimento';
import { GuiaIdentificacion } from './guia-identificacion';
import { CantidadTransportada } from './cantidad-transportada';
import { DetalleMercancia } from './detalle-mercancia';

export class Mercancia {
  idMercancia: number;
  pedimentos: Pedimento[];
  guiasIdentificacion: GuiaIdentificacion[];
  cantidadesTransportadas: CantidadTransportada[];
	detalleMercancia: DetalleMercancia;
  bienesTransp: string;
  claveSTCC: string;
  descripcion: string;
  cantidad: number;
  claveUnidad: string;
  unidad: string;
  dimensiones: string;
  materialPeligroso: string;
  cveMaterialPeligroso: string;
	embalaje: string;
  descripEmbalaje: string;
  pesoEnKg: number;
  valorMercancia: number;
  moneda: string;
  fraccionArancelaria: string;
  uuidComercioExt: string;
}
