import { CargoAereo } from './cargo-aereo';
import { Moneda } from './moneda';
import { Proveedor } from './proveedor';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

export class CargoDestino {
  idCargoDestino: number;
  cargoAereo: CargoAereo;
  cargoAereoRequerido: boolean = false;
  compraTarifa: number;
  compraMonto: number;
  ventaTarifa: number;
  ventaMonto: number;
  profit: number;
  monedaCosto: Moneda;
  monedaCostoRequerida: boolean = false;
  monedaVenta: Moneda;
  monedaVentaRequerida: boolean = false;
  proveedor: Proveedor;
  proveedorRequerido: boolean = false;
  factura: string;
  montoMXP: number;
  tipoCambio: number;
  equivUSD: number;
  itemID: number;
  pedidoCliente: string;
  proveedorCtrl: FormControl;
  proveedorFilterCtrl: FormControl;
  proveedoresFiltrados: ReplaySubject<Proveedor[]>;
}
