import { Ejecutivo } from './ejecutivo';

export class Cliente{
  idCliente: number;
  nombre1: string;
  nombre2: string;
  terminoBusqueda: string;
  bloqueado: boolean;
  motivoBloqueo: string;
  rfc: string;
  ejecutivos: Ejecutivo[];
  borrado: boolean;
  aereo: boolean;
  clientePotencial: boolean;
  numeroClienteSAP: string;
}
