import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SearchComponent } from './header/search/search.component';
import { FooterComponent } from './footer/footer.component';
import { SettingsComponent } from './settings/settings.component';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        ToasterModule,
        NgxDatatableModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        NgbModule,
        BrowserModule,
        FormsModule
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        SearchComponent,
        FooterComponent,
        SettingsComponent,
    ],
    providers: [ToasterService],
    exports: [
        LayoutComponent,
        SidebarComponent,
        HeaderComponent,
        SearchComponent,
        SettingsComponent,
    ]
})
export class LayoutModule { }
