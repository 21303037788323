import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Consignatario } from '../../../model/consignatario';
import { ConsignatariosService } from '../../../services/consignatarios.service';
import { DetalleConsignatarioService } from './detalle-consignatario/detalle-consignatario.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'app-consignatarios',
  templateUrl: './consignatarios.component.html',
  styleUrls: ['./consignatarios.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsignatariosComponent implements OnInit {
  public listaConsignatarios: Consignatario[];
  public consignatariosTemp: Consignatario[];
  public consignatarioSeleccionado: Consignatario;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private consignatariosService: ConsignatariosService,
    private detalleService: DetalleConsignatarioService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Consignatarios');
  }

  ngOnInit() {
    this.consignatarioSeleccionado = null;
    this.cargaConsignatarios();
  }

  private cargaConsignatarios(): void {
    this.consignatariosService.listConsignatarios().subscribe(
      response => {
        this.listaConsignatarios = response;
        this.consignatariosTemp = response;
      }
    );
  }

  agregar(): void {
    this.consignatarioSeleccionado = new Consignatario();
    this.detalleService.abrirModal();
  }

  editar(consignatario: Consignatario): void {
    this.consignatarioSeleccionado = consignatario;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.consignatariosTemp.filter(function(d) {
          return d.idConsignatario == val || d.nombre.toLowerCase().indexOf(val) !== -1  || !val;
      });

      // update the rows
      this.listaConsignatarios = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
