export class EmisorRequest {
  Rfc: string;
  Nombre: string;
  RegimenFiscal: string;
  CodigoPostal: string;
  Calle: string;
  Municipio: string;
  Estado: string;
  Pais: string;
}
