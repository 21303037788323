import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Factura } from '../model/factura';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class FacturasService {

  private urlEndPoint: string = URL_BACKEND + "/factura";

  constructor(
    private http: HttpClient
  ) { }

  getFacturasByProveedor(idProveedor: number): Observable<Factura[]> {
    return this.http.get<Factura[]>(this.urlEndPoint + '/proveedor/' + idProveedor).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

}
