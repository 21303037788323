import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Ejecutivo } from '../../../model/ejecutivo';
import { EjecutivosService } from '../../../services/ejecutivos.service';
import { DetalleEjecutivoService } from './detalle-ejecutivo/detalle-ejecutivo.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'ejecutivos',
  templateUrl: './ejecutivos.component.html',
  styleUrls: ['./ejecutivos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EjecutivosComponent implements OnInit {

  public listaEjecutivos: Ejecutivo[];
  public ejecutivosTemp: Ejecutivo[];
  public ejecutivoSeleccionado: Ejecutivo;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private ejecutivosService: EjecutivosService,
    private detalleService: DetalleEjecutivoService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Ejecutivos');
  }

  ngOnInit() {
    this.ejecutivoSeleccionado = null;
    this.cargaEjecutivos();
  }

  private cargaEjecutivos(): void {
    this.ejecutivosService.listEjecutivos().subscribe(
      response => {
        this.listaEjecutivos = response;
        this.ejecutivosTemp = response;
      }
    );
  }

  agregar(): void {
    this.ejecutivoSeleccionado = new Ejecutivo();
    this.detalleService.abrirModal();
  }

  editar(ejecutivo: Ejecutivo): void {
    this.ejecutivoSeleccionado = ejecutivo;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.ejecutivosTemp.filter(function(d) {
          return d.idEjecutivo == val || d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaEjecutivos = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
