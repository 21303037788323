import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Aeropuerto } from '../../../model/aeropuerto';
import { AeropuertosService } from '../../../services/aeropuertos.service';
import { DetalleAeropuertoService } from './detalle-aeropuerto/detalle-aeropuerto.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'app-aeropuertos',
  templateUrl: './aeropuertos.component.html',
  styleUrls: ['./aeropuertos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AeropuertosComponent implements OnInit {
  public listaAeropuertos: Aeropuerto[];
  public aeropuertosTemp: Aeropuerto[];
  public aeropuertoSeleccionado: Aeropuerto;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private aeropuertosService: AeropuertosService,
    private detalleService: DetalleAeropuertoService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Aeropuertos');
  }

  ngOnInit() {
    this.aeropuertoSeleccionado = null;
    this.cargaAeropuertos();
  }

  private cargaAeropuertos(): void {
    this.aeropuertosService.listAeropuertos().subscribe(
      response => {
        this.listaAeropuertos = response;
        this.aeropuertosTemp = response;
      }
    );
  }

  agregar(): void {
    this.aeropuertoSeleccionado = new Aeropuerto();
    this.detalleService.abrirModal();
  }

  editar(aeropuerto: Aeropuerto): void {
    this.aeropuertoSeleccionado = aeropuerto;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.aeropuertosTemp.filter(function(d) {
          return d.idAeropuerto == val || d.nombre.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaAeropuertos = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
