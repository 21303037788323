import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ServicioPorCliente } from '../../../model/servicio-cliente';
import { DetalleServicioService } from './detalle-servicio.service';
import { TiposEquipoService } from '../../../services/tipos-equipo.service';
import { TiposMovimientoService } from '../../../services/tipos-movimiento.service';
import { TipoEquipo } from '../../../model/tipo-equipo';
import { TipoMovimiento } from '../../../model/tipo-movimiento';
import { AuthService } from '../../../auth/auth.service';
import { ServiciosService } from '../../../services/servicios.service';
import { HttpEventType } from '@angular/common/http';
import { ArchivoBox } from '../../../model/archivo-box';
import { NgxSpinnerService } from "ngx-spinner";

const swal = require('sweetalert');
const FileSaver = require('file-saver');

@Component({
	selector: 'detalle-servicio',
	templateUrl: './detalle-servicio.component.html',
	styleUrls: ['./detalle-servicio.component.scss']
})
export class DetalleServicioComponent implements OnInit, OnChanges {

	@Input()
	ruta: ServicioPorCliente;
	@Input()
	fechaCarga: Date;
	@Input()
	fechaAgenteAduanal: Date;
	@Input()
	fechaCruce: Date;
	@Input()
	fechaDestino: Date;
	@Input()
	idServicio: number;

	fechaFactura: Date;
	listTiposEquipo: TipoEquipo[];
	tipoEquipo: TipoEquipo;
	tipoEquipoRequerido: boolean;
	listTiposMovimiento: TipoMovimiento[];
	tipoMovimiento: TipoMovimiento;
	tipoMovimientoRequerido: boolean;
	numFactura: string;
	podId: number;
	podRequerido: boolean;
	archivoEvidencia: File;
	archivoPod: ArchivoBox;
	entregado: boolean;
	comentarios: string;
	numEquipoTipoEquipo: string;
	numEquipoTipoEquipoRequerido: boolean;
	numEquipoTipoMovimiento: string;
	numEquipoTipoMovimientoRequerido: boolean;
	referenciaCliente: string;

	constructor(
		public detalleService: DetalleServicioService,
		private tiposEquipoService: TiposEquipoService,
		private tiposMovimientoService: TiposMovimientoService,
		public authService: AuthService,
		private serviciosService: ServiciosService,
		private spinnerService: NgxSpinnerService
	) {}

	ngOnInit() {
		this.cargarTiposEquipo();
		this.cargarTiposMovimiento();
	}

	cargarTiposEquipo(): void {
		this.tiposEquipoService.listTiposEquipo().subscribe(
			response => {
				this.listTiposEquipo = response;
			}
		);
	}

	cargarTiposMovimiento(): void {
		this.tiposMovimientoService.listTiposMovimiento().subscribe(
			response => {
				this.listTiposMovimiento = response;
			}
		);
	}


	ngOnChanges(changes: SimpleChanges) {

		if (this.ruta.idServicioCliente) {
			if (this.ruta.tipoRuta && this.ruta.tipoRuta.idTipoRuta == 1) {

				this.fechaCarga = new Date(this.ruta.fechaCarga);
				var os = this.fechaCarga.getTimezoneOffset();
				this.fechaCarga = new Date(this.fechaCarga.getTime() + os * 60 * 1000);

				//console.log("FECHA CARGA -> " + this.fechaCarga);
			} else if (this.ruta.tipoRuta && this.ruta.tipoRuta.idTipoRuta == 2) {

				this.fechaAgenteAduanal = new Date(this.ruta.fechaAgenteAduanal);
				this.fechaCruce = new Date(this.ruta.fechaCruce);

				var os = this.fechaAgenteAduanal.getTimezoneOffset();
				this.fechaAgenteAduanal = new Date(this.fechaAgenteAduanal.getTime() + os * 60 * 1000);
				//console.log("FECHA AGENTE ADUANAL -> " + this.fechaAgenteAduanal);

				os = this.fechaCruce.getTimezoneOffset();
				this.fechaCruce = new Date(this.fechaCruce.getTime() + os * 60 * 1000);


			} else {

				this.fechaDestino = new Date(this.ruta.fechaDestino);
				var os = this.fechaDestino.getTimezoneOffset();
				this.fechaDestino = new Date(this.fechaDestino.getTime() + os * 60 * 1000);
				this.podId = this.ruta.podId;
				this.archivoPod = this.ruta.archivoPod;
				this.entregado = this.ruta.entregado;
				this.comentarios = this.ruta.comentarios;
				this.tipoEquipo = this.ruta.tipoEquipo;
				this.numEquipoTipoEquipo = this.ruta.numEquipoTipoEquipo;
				this.tipoMovimiento = this.ruta.tipoMovimiento;
				this.numEquipoTipoMovimiento = this.ruta.numEquipoTipoMovimiento;
				this.referenciaCliente = this.ruta.referenciaCliente;
			}
		} else {
			//console.log("<-- CREACION -->");

			if (this.ruta.tipoRuta && this.ruta.tipoRuta.idTipoRuta == 1) {

				if (this.fechaCarga) {

				} else {
					//console.log("EXISTE FECHA CARGA, CREA NUEVA");
					this.fechaCarga = new Date();
				}


			}


			if (this.ruta.tipoRuta && this.ruta.tipoRuta.idTipoRuta == 2) {

				if (this.fechaAgenteAduanal) {
					/*
					var os = this.fechaAgenteAduanal.getTimezoneOffset();
					this.fechaAgenteAduanal = new Date(this.fechaAgenteAduanal.getTime() + os * 60 * 1000);*/
				} else {
					this.fechaAgenteAduanal = new Date();
				}


				if (this.fechaCruce) {

				} else {
					this.fechaCruce = new Date();
				}

			}

			if (this.ruta.tipoRuta && this.ruta.tipoRuta.idTipoRuta == 3) {

				if (this.fechaDestino) {
					/*
					var os = this.fechaDestino.getTimezoneOffset();
					this.fechaDestino = new Date(this.fechaDestino.getTime() + os * 60 * 1000);*/
				} else {
					this.fechaDestino = new Date();
				}

			}

		}
	}

	cancelar(): void {
		this.detalleService.cerraModal();
	}

	guardar(): void {
		//console.log("***** GUARDAR ***** ");
		if (this.ruta.tipoRuta.idTipoRuta == 1) {
			this.ruta.fechaCarga = this.fechaCarga;
			/*
        console.log("ORIGEN-> fechaCarga["+this.fechaCarga+"] fechaAgenteAduanal["+this.fechaAgenteAduanal+"] fechaCruce["+this.fechaCruce+"]");
        */
			if (this.fechaAgenteAduanal && this.fechaCruce) {

			} else {
				//console.log("* HAY AGENTE Y FECHA CRUCE ");
				this.ruta.fechaCarga = this.fechaCarga;
			}

			if (this.fechaDestino) {

			} else {
				//  console.log("* HAY FECHA DESTINO "+this.fechaDestino);
				this.ruta.fechaCarga = this.fechaCarga;
			}


		} else if (this.ruta.tipoRuta.idTipoRuta == 2) {
			this.ruta.fechaAgenteAduanal = this.fechaAgenteAduanal;
			this.ruta.fechaCruce = this.fechaCruce;

		} else if (this.ruta.tipoRuta.idTipoRuta == 3) {
			/*
			if(this.fechaCarga && this.fechaDestino < this.fechaCarga){
			    swal('Fecha invalida', 'La fecha destino debe ser mayor a la fecha de carga ', 'error');
			    console.log("La fecha destino debe ser mayor a la fecha de carga");
			    return;
			}
			*/
			/*	if ((this.fechaAgenteAduanal && this.fechaDestino <= this.fechaAgenteAduanal) || (this.fechaCruce && this.fechaDestino < this.fechaCruce)) {
					swal('Fecha invalida', 'La fecha destino debe ser mayor a la fecha agente aduanal y fecha cruce ', 'error');
					console.log("La fecha destino debe ser mayor a la fecha agente aduanal y fecha cruce");
					return;
				} else {*/
			this.ruta.fechaDestino = this.fechaDestino;
			this.ruta.fechaFactura = this.fechaFactura;
			this.ruta.numFactura = this.numFactura;
			this.ruta.podId = this.podId;

			if (!this.podId) {
				this.podRequerido = true;
			} else {
				this.podRequerido = false;
				if (this.podId == 2) {
					if (this.archivoPod) {
						this.ruta.archivoPod = this.archivoPod;
					}
				} else if (this.podId == 3) {
					if (this.entregado) {
						this.ruta.entregado = this.entregado;
					}
					if (this.comentarios) {
						this.ruta.comentarios = this.comentarios;
					}
				}
			}
			if (!this.tipoEquipo) {
				this.tipoEquipoRequerido = true;
			} else {
				this.tipoEquipoRequerido = false;
				this.ruta.tipoEquipo = this.tipoEquipo;
				if (!this.numEquipoTipoEquipo) {
					this.numEquipoTipoEquipoRequerido = true;
				} else {
					this.numEquipoTipoEquipoRequerido = false;
					this.ruta.numEquipoTipoEquipo = this.numEquipoTipoEquipo;
				}
				if (this.tipoEquipo.idTipoEquipo == 1 || this.tipoEquipo.idTipoEquipo == 3) {
					if (!this.tipoMovimiento) {
						this.tipoMovimientoRequerido = true;
					} else {
						this.tipoMovimientoRequerido = false;
						this.ruta.tipoMovimiento = this.tipoMovimiento;
						if (this.tipoMovimiento.idTipoMovimiento == 1) {
							if (!this.numEquipoTipoMovimiento) {
								this.numEquipoTipoMovimientoRequerido = true;
							} else {
								this.numEquipoTipoMovimientoRequerido = false;
								this.ruta.numEquipoTipoMovimiento = this.numEquipoTipoMovimiento;
							}
						}
					}
				}
			}
			if (this.referenciaCliente) {
				this.ruta.referenciaCliente = this.referenciaCliente;
			}
			if (this.podRequerido || this.tipoEquipoRequerido || this.numEquipoTipoEquipoRequerido ||
				this.tipoMovimientoRequerido || this.numEquipoTipoMovimientoRequerido) {
				swal('Datos incompletos', 'Se deben capturar todos los campos', 'warning');
				return;
			}
			//}
		}

		this.detalleService.cerraModal();
	}

	compararTipoEquipo(o1: TipoEquipo, o2: TipoEquipo) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idTipoEquipo === o2.idTipoEquipo;
	}

	compararTipoMovimiento(o1: TipoMovimiento, o2: TipoMovimiento) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idTipoMovimiento === o2.idTipoMovimiento;
	}

	compararPod(o1: any, o2: any) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1 === o2;
	}

	seleccionaFechaCarga(value: any): void {
		this.fechaCarga = value;
		
	}

	seleccionaFechaAgente(value: any): void {
		this.fechaAgenteAduanal = value;
	}

	seleccionaFechaCruce(value: any): void {
		this.fechaCruce = value;
	}

	seleccionaFechaDestino(value: any): void {
		this.fechaDestino = value;
	}

	seleccionaFechaFactura(value: any): void {
		this.fechaFactura = value;
	}

	seleccionarPOD(event: any) {
		this.archivoEvidencia = event.target.files[0];
		if (this.archivoEvidencia.type.indexOf('pdf') < 0 && this.archivoEvidencia.type.indexOf('image')) {
			swal('Error Tipo Archivo', 'Debe seleccionar un pdf o imagen', 'error');
			this.archivoEvidencia = null;
		} else {
			this.spinnerService.show();
			this.serviciosService.uploadPod(this.archivoEvidencia, this.idServicio).subscribe(
				event => {
					if (event.type === HttpEventType.Response) {
						let response: any = event.body;
						this.archivoPod = response.archivoBox;
						this.spinnerService.hide();
						swal('Carga completa!', response.mensaje, 'success');
					}
				}
			)
		}
	}

	downloadArchivoPod(): void {
		window.open(this.archivoPod.url, '_blank', '', true);
	}

	public isReadOnly(): boolean {
		return this.authService.hasRole('ROLE_CUENTAS_PAGAR') || this.authService.hasRole('ROLE_CUENTAS_COBRAR');
	}

}
