import { Component, OnInit, Input } from '@angular/core';
import { Proveedor } from '../../../../model/proveedor';
import { DetalleProveedorService } from './detalle-proveedor.service';
import { ProveedoresService } from '../../../../services/proveedores.service';
import { PaisesService } from '../../../../services/paises.service';
import { Pais } from '../../../../model/pais';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-proveedor',
  templateUrl: './detalle-proveedor.component.html',
  styleUrls: ['./detalle-proveedor.component.scss']
})
export class DetalleProveedorComponent implements OnInit {

  @Input()
  proveedor: Proveedor;

  listaPaises: Pais[];

  constructor(
    public detalleService: DetalleProveedorService,
    private proveedoresService: ProveedoresService,
    private paisesService: PaisesService
  ) { }

  ngOnInit() {
    this.cargaPaises();
  }

  private cargaPaises(): void {
    this.paisesService.listPaises().subscribe(
      response => {
        this.listaPaises = response;
      }
    );
  }

  public cancel(): void{
    this.detalleService.cerraModal();
  }

  public update(): void{
    this.proveedoresService.updateProveedor(this.proveedor).subscribe(
      proveedor => {
        this.detalleService.cerraModal();
        swal('Proveedor Actualizado', `Proveedor ${proveedor.razonSocial} actualizado con exito`, 'success');
      }
    );
  }

  compararPais(o1: Pais, o2: Pais){
    if(o1 === undefined && o2 === undefined){
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idPais === o2.idPais;
  }

}
