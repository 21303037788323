import { Proveedor } from './proveedor';
import { Cargo } from './cargo';
import { Moneda } from './moneda';
import { Direccion } from './direccion';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

export class CargoPorServicio {
  idCargoServicio: number;
  posicion: number;
  proveedor: Proveedor;
  proveedorRequerido: boolean;
  cargo: Cargo;
  cargoRequerido: boolean;
  monedaCosto: Moneda;
  monedaCostoRequerida: boolean;
  costoRequerida: boolean;
  costo: number;
  monedaVenta: Moneda;
  monedaVentaRequerida: boolean;
  ventaRequerida: boolean;
  venta: number;
  observaciones: string;
  observacionesRequeridas: boolean;
  direccionStop: Direccion;
  consignatarioStop: string;
  itemId: number;
  pedidoCliente: string;
  crmId: number;
  proveedorCtrl: FormControl;
  proveedorFilterCtrl: FormControl;
  proveedoresFiltrados: ReplaySubject<Proveedor[]>;
}
