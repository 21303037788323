import { ComprobanteRequest } from './comprobante-request';
import { EmisorRequest } from './emisor-request';
import { ReceptorRequest } from './receptor-request';
import { ConceptosRequest } from './conceptos-request';
import { ComplementoRequest } from './complemento-request';
import { ImpuestoTotalRequest } from './impuesto-total-request';

export class DatosRequest {
  Comprobante: ComprobanteRequest;
	Emisor: EmisorRequest;
	Receptor: ReceptorRequest;
	Conceptos: ConceptosRequest[];
  ImpuestosTotales: ImpuestoTotalRequest[];
	Complemento: ComplementoRequest;
}
