import { Component, OnInit, Input } from '@angular/core';
import { CatalogoService } from './catalogo.service';
import { Shipper } from '../../../model/shipper';
import { Consignatario } from '../../../model/consignatario';
import { Aeropuerto } from '../../../model/aeropuerto';
import { Pais } from '../../../model/pais';
import { Incoterm } from '../../../model/incoterm';
import { ShippersService } from '../../../services/shippers.service';
import { ConsignatariosService } from '../../../services/consignatarios.service';
import { AeropuertosService } from '../../../services/aeropuertos.service';
import { PaisesService } from '../../../services/paises.service';
import { IncotermsService } from '../../../services/incoterms.service';

const swal = require('sweetalert');

@Component({
  selector: 'catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit {

  @Input()
  tipo: string;

  nombre: string;
  listaPaises: Pais[];
  pais: Pais;
  estado: string;
  iniciales: string;

  constructor(
    public catalogoService: CatalogoService,
    private shippersService: ShippersService,
    private consignatariosService: ConsignatariosService,
    private aeropuertosService: AeropuertosService,
    private paisesService: PaisesService,
    private incotermsService: IncotermsService
  ) { }

  ngOnInit() {
    this.cargaPaises();
  }

  private cargaPaises(): void {
    this.paisesService.listPaises().subscribe(
      response => {
        this.listaPaises = response;
      }
    );
  }

  compararPais(o1: Pais, o2: Pais) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idPais === o2.idPais;
  }

  cerrar(): void {
    this.nombre = '';
    this.catalogoService.cerraModal();
  }

  guardar(): void {
    if (this.tipo == "Shipper") {
      const shipper: Shipper = new Shipper();
      shipper.nombre = this.nombre;
      this.shippersService.createShipper(shipper).subscribe(
        nuevoShipper => {
          this.catalogoService.saveEmitter.emit(nuevoShipper);
          swal('Shipper Creado', 'El nuevo Shipper fue creado', 'success');
          this.cerrar();
        }
      );
    } else if (this.tipo == "Consignatario") {
      const consignatario: Consignatario = new Consignatario();
      consignatario.nombre = this.nombre;
      this.consignatariosService.createConsignatario(consignatario).subscribe(
        nuevoConsignatario => {
          this.catalogoService.saveEmitter.emit(nuevoConsignatario);
          swal('Consignatario Creado', 'El nuevo Consignatario fue creado', 'success');
          this.cerrar();
        }
      );
    } else if (this.tipo == "Aeropuerto Origen" || this.tipo == "Aeropuerto Destino") {
      const aeropuerto: Aeropuerto = new Aeropuerto();
      aeropuerto.pais = this.pais;
      aeropuerto.estado = this.estado;
      aeropuerto.nombre = this.nombre;
      aeropuerto.iniciales = this.iniciales;
      this.aeropuertosService.createAeropuerto(aeropuerto).subscribe(
        nuevoAeropuerto => {
          this.catalogoService.saveEmitter.emit(nuevoAeropuerto);
          swal('Aeropuerto Creado', 'El nuevo Aeropuerto fue creado', 'success');
          this.cerrar();
        }
      );
    } else if (this.tipo == "Incoterm Origen" || this.tipo == "Incoterm Destino") {
      const incoterm: Incoterm = new Incoterm();
      incoterm.descripcion = this.nombre;
      incoterm.iniciales = this.iniciales;
      this.incotermsService.createIncoterm(incoterm).subscribe(
        incotermNuevo => {
          this.catalogoService.saveEmitter.emit(incotermNuevo);
          swal('Incoterm Creado', 'El nuevo Incoterm fue creado', 'success');
          this.cerrar();
        }
      );
    }
  }

}
