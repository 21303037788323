import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { Proveedor } from '../../model/proveedor';
import { Factura } from '../../model/factura';
import { ProveedoresService } from '../../services/proveedores.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { FacturasService } from '../../services/facturas.service';

@Component({
  selector: 'app-valida-facturas',
  templateUrl: './valida-facturas.component.html',
  styleUrls: ['./valida-facturas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ValidaFacturasComponent implements OnInit {

  listProveedores: Proveedor[];
  proveedor: Proveedor;
  proveedorCtrl: FormControl = new FormControl();
  proveedorFilterCtrl: FormControl = new FormControl();
  proveedoresFiltrados: ReplaySubject<Proveedor[]> = new ReplaySubject<Proveedor[]>(1);
  searching = false;
  _onDestroy = new Subject<void>();
  listaFacturas: Factura[];

  constructor(
    public pt: PagetitleService,
    private proveedoresService: ProveedoresService,
    private facturasService: FacturasService
  ) {
    pt.setTitle('Validar Facturas');
  }

  ngOnInit() {
    this.cargarProveedores();
  }

  private cargarProveedores(): void {
    this.proveedoresService.listProveedores().subscribe(
      response => {
        this.listProveedores = response;
        this.filtrarProveedores();
      }
    );
  }

  private filtrarProveedores(): void {
    this.proveedorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listProveedores) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listProveedores.filter(proveedor => proveedor.razonSocial.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(proveedoresFiltrados => {
        this.searching = false;
        this.proveedoresFiltrados.next(proveedoresFiltrados);
      },
      error => {
        // no errors in our simulated example
        this.searching = false;
        // handle error...
      });
  }

  buscar(): void {
    let idProveedor = 0;

    if (this.proveedor) {
      idProveedor = this.proveedor.idProveedor;
      this.facturasService.getFacturasByProveedor(idProveedor).subscribe(
        response => {
          this.listaFacturas = response;
        }
      );
    }
  }

}
