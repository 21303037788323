export class Seguro {
  idSeguro: number;
	aseguraRespCivil: string;
  polizaRespCivil: string;
  aseguraMedAmbiente: string;
  polizaMedAmbiente: string;
  aseguraCarga: string;
  polizaCarga: string;
  primaSeguro: string;
}
