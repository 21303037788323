import { Injectable, EventEmitter } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Servicio } from '../model/servicio';
import { CartaPorte } from '../model/cartaporte/carta-porte';
import { UbicacionCp } from '../model/cartaporte/ubicacion-cp';
import { Domicilio } from '../model/cartaporte/domicilio';
import { Mercancia } from '../model/cartaporte/mercancia';
import { DetalleMercancia } from '../model/cartaporte/detalle-mercancia';
import { Pedimento } from '../model/cartaporte/pedimento';
import { LOGO } from './logo';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  title: string;
  header: string[];
  colServicio: number = 0;
  colRuta: number = 0;
  colCargo: number = 0;
  servicios: Servicio[];
  private _saveEmitter = new EventEmitter<any>();

  constructor() { }

  get saveEmitter(): EventEmitter<any> {
    return this._saveEmitter;
  }

  generateExcel(): void {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Servicios Exportados');

    // Add new row
    let titleRow = worksheet.addRow([this.title]);

    // Set font, size and style in title row.
    titleRow.font = { name: 'Times New Roman', family: 4, size: 16, underline: 'double', bold: true };

    // Blank Row
    worksheet.addRow([]);

    //Add row with current date
    worksheet.addRow(['Fecha : ' + new Date()]);

    // Blank Row
    worksheet.addRow([]);

    //Add Header Row
    let headerRow = worksheet.addRow(this.header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });

    let renglonVerde: boolean = false;

    // Add Data and Conditional Formatting
    //worksheet.addRows(this.data);
    this.servicios.forEach(registro => {
      let renglonServicio: any[] = [];

      if (this.header.indexOf('Número de Servicio') > -1) {
        renglonServicio.push(registro.idServicioStr ? registro.idServicioStr : '');
      }
      if (this.header.indexOf('Tráfico') > -1) {
        renglonServicio.push(registro.nombreTrafico);
      }
      if (this.header.indexOf('Fecha de Servicio') > -1) {
        renglonServicio.push(registro.fechaServicio);
      }
      if (this.header.indexOf('Cliente') > -1) {
        renglonServicio.push(registro.cliente ? registro.cliente.nombre1 : '');
      }
      if (this.header.indexOf('MPC') > -1) {
        renglonServicio.push(registro.mpc ? registro.mpc.descripcion : '');
      }
      if (this.header.indexOf('Completo') > -1) {
        renglonServicio.push(registro.notasCompletas ? 'SI' : 'NO');
      }
      if (this.header.indexOf('Notas') > -1) {
        renglonServicio.push(registro.notas ? registro.notas : '');
      }
      let rowServicio = worksheet.addRow(renglonServicio);

      if (renglonVerde) {
        for (let i = 1; i <= (this.colServicio + this.colRuta + this.colCargo); i++) {
          rowServicio.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFCCFFE5' }
          };
        }
      }

      if (registro.serviciosPorCliente && registro.serviciosPorCliente.length > 0) {
        registro.serviciosPorCliente.forEach(ruta => {
          let renglonRuta: any[] = [];

          for (let i = 1; i <= this.colServicio; i++) {
            renglonRuta.push('');
          }

          if (this.header.indexOf('Tipo Ruta') > -1) {
            renglonRuta.push(ruta.tipoRuta ? ruta.tipoRuta.descripcion : '');
          }
          if (this.header.indexOf('Ubicación') > -1) {
            renglonRuta.push(ruta.tipoRuta.idTipoRuta == 2 ? ruta.ubicacion.labelCruce : ruta.ubicacion.label);
          }
          if (this.header.indexOf('Consignatario') > -1) {
            renglonRuta.push(ruta.consignatario);
          }
          if (this.header.indexOf('Proveedor Ruta') > -1) {
            renglonRuta.push(ruta.proveedor ? ruta.proveedor.razonSocial : '');
          }
          if (this.header.indexOf('Costo Ruta') > -1) {
            renglonRuta.push(ruta.costo);
          }
          if (this.header.indexOf('Venta Ruta') > -1) {
            renglonRuta.push(ruta.venta);
          }
          let rowRuta = worksheet.addRow(renglonRuta);

          if (renglonVerde) {
            for (let i = 1; i <= (this.colServicio + this.colRuta + this.colCargo); i++) {
              rowRuta.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCFFE5' }
              };
            }
          }
        });
      }

      if (registro.cargosPorServicio && registro.cargosPorServicio.length > 0) {

        registro.cargosPorServicio.forEach(cargo => {
          let renglonCargo: any[] = [];

          for (let i = 1; i <= (this.colServicio + this.colRuta); i++) {
            renglonCargo.push('');
          }

          if (this.header.indexOf('Proveedor Cargo') > -1) {
            renglonCargo.push(cargo.proveedor ? cargo.proveedor.razonSocial : '');
          }
          if (this.header.indexOf('Cargo') > -1) {
            renglonCargo.push(cargo.cargo ? cargo.cargo.descCorta : '');
          }
          if (this.header.indexOf('Observaciones') > -1) {
            renglonCargo.push(cargo.observaciones);
          }
          if (this.header.indexOf('Costo Cargo') > -1) {
            renglonCargo.push(cargo.costo);
          }
          if (this.header.indexOf('Venta Cargo') > -1) {
            renglonCargo.push(cargo.venta);
          }
          let rowCargo = worksheet.addRow(renglonCargo);

          if (renglonVerde) {
            for (let i = 1; i <= (this.colServicio + this.colRuta + this.colCargo); i++) {
              rowCargo.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCFFE5' }
              };
            }
          }
        });
      }

      if (renglonVerde) {
        renglonVerde = false;
      } else {
        renglonVerde = true;
      }

    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ServiciosExportados.xlsx');
    });
  }

  exportCartaPorte(cartaPorte: CartaPorte): void {
    let workbook = new Workbook();

    let principalWs = workbook.addWorksheet('PropuestaClientes');

    var imageID = workbook.addImage({
      base64: LOGO,
      extension: 'png',
    });

    principalWs.addImage(imageID, {
      tl: { col: 0, row: 0 },
      ext: { width: 400, height: 140 }
    });

    principalWs.columns = [
      { width: 30 },
      { width: 50 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 }
    ];

    principalWs.getCell('C4').value = 'INFORMACIÓN REQUERIDA DEL CLIENTE PARA COMPLEMENTO DE CARTA PORTE';
    principalWs.getCell('C4').font = { name: 'Calibri', family: 4, size: 18, bold: true };

    principalWs.getCell('A8').value = 'Clave Transporte';
    principalWs.getCell('B8').value = 'Transporte Internacional';
    principalWs.getCell('C8').value = 'Entrada, Salida mercancía';
    principalWs.getCell('D8').value = 'Vía entrada salida';
    principalWs.getCell('E8').value = 'Total Distancia recorrida';

    var header1 = ['A8', 'B8', 'C8', 'D8', 'E8'];
    header1.forEach(celda => {
      principalWs.getCell(celda).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF196808' } };
      principalWs.getCell(celda).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      principalWs.getCell(celda).font = { name: 'Calibri', family: 4, size: 12, bold: true, color: { argb: 'FFFFFF' } };
      principalWs.getCell(celda).alignment = { horizontal: 'center' };
    });

    principalWs.getCell('A9').value = '';
    principalWs.getCell('B9').value = cartaPorte.transpInternac;
    principalWs.getCell('C9').value = cartaPorte.entradaSalidaMerc;
    principalWs.getCell('D9').value = cartaPorte.viaEntradaSalida;
    principalWs.getCell('E9').value = cartaPorte.totalDistRec;

    var datos1 = ['A9', 'B9', 'C9', 'D9', 'E9'];
    datos1.forEach(celda => {
      principalWs.getCell(celda).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    principalWs.getCell('B12').value = 'Concepto';
    principalWs.getCell('C12').value = 'Descripción';
    principalWs.getCell('D12').value = 'Clave SAT';

    var header2 = ['A12', 'B12', 'C12', 'D12'];
    header2.forEach(celda => {
      principalWs.getCell(celda).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF196808' } };
      principalWs.getCell(celda).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      principalWs.getCell(celda).font = { name: 'Calibri', family: 4, size: 12, bold: true, color: { argb: 'FFFFFF' } };
      principalWs.getCell(celda).alignment = { horizontal: 'center' };
    });

    principalWs.mergeCells('A13:A24');
    principalWs.getCell('A13').value = 'REMITENTE ORIGEN';

    principalWs.getCell('B13').value = 'Razón Social';
    principalWs.getCell('B14').value = 'RFC Remitente';
    principalWs.getCell('B15').value = 'Número de Identificación (En caso de ser extranjero)';
    principalWs.getCell('B16').value = 'País';
    principalWs.getCell('B17').value = 'Estado';
    principalWs.getCell('B18').value = 'Localidad';
    principalWs.getCell('B19').value = 'Municipio';
    principalWs.getCell('B20').value = 'Colonia';
    principalWs.getCell('B21').value = 'Calle';
    principalWs.getCell('B22').value = 'Número Exterior';
    principalWs.getCell('B23').value = 'Número Interior';
    principalWs.getCell('B24').value = 'Codigo Postal';

    principalWs.getCell('C13').value = cartaPorte.ubicaciones[0].nombreRemitenteDestinatario;
    principalWs.getCell('C14').value = cartaPorte.ubicaciones[0].rfcRemitenteDestinatario;
    principalWs.getCell('C15').value = cartaPorte.ubicaciones[0].numRegIdTrib;
    principalWs.getCell('D16').value = cartaPorte.ubicaciones[0].domicilio.pais;
    principalWs.getCell('D17').value = cartaPorte.ubicaciones[0].domicilio.estado;
    principalWs.getCell('D18').value = cartaPorte.ubicaciones[0].domicilio.localidad;
    principalWs.getCell('D19').value = cartaPorte.ubicaciones[0].domicilio.municipio;
    principalWs.getCell('C20').value = cartaPorte.ubicaciones[0].domicilio.colonia;
    principalWs.getCell('C21').value = cartaPorte.ubicaciones[0].domicilio.calle;
    principalWs.getCell('C22').value = cartaPorte.ubicaciones[0].domicilio.numeroExterior;
    principalWs.getCell('C23').value = cartaPorte.ubicaciones[0].domicilio.numeroInterior;
    principalWs.getCell('C24').value = cartaPorte.ubicaciones[0].domicilio.codigoPostal;

    principalWs.mergeCells('A25:A36');
    principalWs.getCell('A25').value = 'DESTINATARIO DESTINO';

    principalWs.getCell('B25').value = 'Razón Social';
    principalWs.getCell('B26').value = 'RFC Remitente';
    principalWs.getCell('B27').value = 'Número de Identificación (En caso de ser extranjero)';
    principalWs.getCell('B28').value = 'País';
    principalWs.getCell('B29').value = 'Estado';
    principalWs.getCell('B30').value = 'Localidad';
    principalWs.getCell('B31').value = 'Municipio';
    principalWs.getCell('B32').value = 'Colonia';
    principalWs.getCell('B33').value = 'Calle';
    principalWs.getCell('B34').value = 'Número Exterior';
    principalWs.getCell('B35').value = 'Número Interior';
    principalWs.getCell('B36').value = 'Codigo Postal';

    principalWs.getCell('C25').value = cartaPorte.ubicaciones[0].nombreRemitenteDestinatario;
    principalWs.getCell('C26').value = cartaPorte.ubicaciones[0].rfcRemitenteDestinatario;
    principalWs.getCell('C27').value = cartaPorte.ubicaciones[0].numRegIdTrib;
    principalWs.getCell('D28').value = cartaPorte.ubicaciones[0].domicilio.pais;
    principalWs.getCell('D29').value = cartaPorte.ubicaciones[0].domicilio.estado;
    principalWs.getCell('D30').value = cartaPorte.ubicaciones[0].domicilio.localidad;
    principalWs.getCell('D31').value = cartaPorte.ubicaciones[0].domicilio.municipio;
    principalWs.getCell('C32').value = cartaPorte.ubicaciones[0].domicilio.colonia;
    principalWs.getCell('C33').value = cartaPorte.ubicaciones[0].domicilio.calle;
    principalWs.getCell('C34').value = cartaPorte.ubicaciones[0].domicilio.numeroExterior;
    principalWs.getCell('C35').value = cartaPorte.ubicaciones[0].domicilio.numeroInterior;
    principalWs.getCell('C36').value = cartaPorte.ubicaciones[0].domicilio.codigoPostal;

    for (let i = 13; i <= 41; i++) {
      principalWs.getCell('B' + i).font = { name: 'Calibri', family: 4, size: 11, bold: true };
      principalWs.getCell('B' + i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      principalWs.getCell('C' + i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      principalWs.getCell('D' + i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    }

    principalWs.mergeCells('A37:A41');
    principalWs.getCell('A37').value = 'MERCANCÍAS';

    var header3 = ['A13', 'A25', 'A37'];
    header3.forEach(celda => {
      principalWs.getCell(celda).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF196808' } };
      principalWs.getCell(celda).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      principalWs.getCell(celda).font = { name: 'Calibri', family: 4, size: 18, bold: true, color: { argb: 'FFFFFF' } };
      principalWs.getCell(celda).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    });

    principalWs.getCell('B37').value = 'Número Total Mercancías';
    principalWs.getCell('B38').value = 'Unidad Peso';
    principalWs.getCell('B39').value = 'Peso Bruto Total';
    principalWs.getCell('B40').value = 'Peso Neto Total';
    principalWs.getCell('B41').value = 'Cargo por Tasación';

    principalWs.getCell('C37').value = cartaPorte.mercancias.numTotalMercancias;
    principalWs.getCell('D38').value = cartaPorte.mercancias.unidadPeso;
    principalWs.getCell('C39').value = cartaPorte.mercancias.pesoBrutoTotal;
    principalWs.getCell('C40').value = cartaPorte.mercancias.pesoNetoTotal;
    principalWs.getCell('C41').value = cartaPorte.mercancias.cargoPorTasacion;

    let headerMercancias = principalWs.addRow([
      'Bienes Transportados (Código Catálogo del SAT)',
      'Descripción',
      'Cantidad',
      'Clave Unidad',
      'Unidad',
      'Dimensiones',
      'Material Peligroso',
      'Clave Material Peligroso',
      'Embalaje',
      'Descripción Embalaje',
      'Peso en Kg',
      'Fracción Arancelaria',
      'UUIDComercioExt',
      'Número de Pedimento',
      'Unidad Peso',
      'Descripción Unidad Peso',
      'Peso Bruto',
      'Peso Neto',
      'Peso Tara (Peso Bruto menos Peso Neto)',
      'Número de Piezas'
    ]);

    headerMercancias.eachCell((cell, number) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF196808' } };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { name: 'Calibri', family: 4, size: 12, bold: true, color: { argb: 'FFFFFF' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    });

    if (cartaPorte != null && cartaPorte.mercancias.mercancia != null && cartaPorte.mercancias.mercancia.length > 0) {
      cartaPorte.mercancias.mercancia.forEach(registro => {
        let renglon: any[] = [];

        renglon.push(registro.bienesTransp != null ? registro.bienesTransp : '');
        renglon.push(registro.descripcion != null ? registro.descripcion : '');
        renglon.push(registro.cantidad != null ? registro.cantidad : '');
        renglon.push(registro.claveUnidad != null ? registro.claveUnidad : '');
        renglon.push(registro.unidad != null ? registro.unidad : '');
        renglon.push(registro.dimensiones != null ? registro.dimensiones : '');
        renglon.push(registro.materialPeligroso != null ? registro.materialPeligroso : '');
        renglon.push(registro.cveMaterialPeligroso != null ? registro.cveMaterialPeligroso : '');
        renglon.push(registro.embalaje != null ? registro.embalaje : '');
        renglon.push(registro.descripEmbalaje != null ? registro.descripEmbalaje : '');
        renglon.push(registro.pesoEnKg != null ? registro.pesoEnKg : '');
        renglon.push(registro.fraccionArancelaria != null ? registro.fraccionArancelaria : '');
        renglon.push(registro.uuidComercioExt != null ? registro.uuidComercioExt : '');

        if (registro.pedimentos != null && registro.pedimentos.length > 0) {
          renglon.push(registro.pedimentos[0].pedimento);
        } else {
          renglon.push('');
        }

        renglon.push(registro.detalleMercancia.unidadPesoMerc != null ? registro.detalleMercancia.unidadPesoMerc : '');
        renglon.push('');
        renglon.push(registro.detalleMercancia.pesoBruto != null ? registro.detalleMercancia.pesoBruto : '');
        renglon.push(registro.detalleMercancia.pesoNeto != null ? registro.detalleMercancia.pesoNeto : '');
        renglon.push(registro.detalleMercancia.pesoTara != null ? registro.detalleMercancia.pesoTara : '');
        renglon.push(registro.detalleMercancia.numPiezas != null ? registro.detalleMercancia.numPiezas : '');

        let rowAdded = principalWs.addRow(renglon);
        rowAdded.eachCell((cell, number) => {
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });

      });
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'CartaPorte.xlsx');

    });
  }

  cargarCartaPorte(cartaPorte: CartaPorte, file: File): void {
    try {
      let reader = new FileReader();
      reader.onload = async function(e) {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        let worksheet = workbook.Sheets[workbook.SheetNames[0]];
        let sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        cartaPorte = await this.parseFileContent(cartaPorte, sheet);
        this.saveEmitter.emit(cartaPorte);
      }.bind(this);
      reader.readAsArrayBuffer(file);
    } catch (exception) {
      console.log('Error al leer el archivo');
    }
  }

  parseFileContent = async (cartaPorte: CartaPorte, content) => {
    return new Promise((resolve) => {
      var ubicacionOrigen: UbicacionCp = new UbicacionCp();
      ubicacionOrigen.tipoUbicacion = "Origen";
      var domicilioOrigen = new Domicilio();

      var ubicacionDestino: UbicacionCp = new UbicacionCp();
      ubicacionDestino.tipoUbicacion = "Destino";
      var domicilioDestino = new Domicilio();

      cartaPorte.mercancias.mercancia = [];

      let rowNum = 1;
      content.forEach((line) => {
        // line contiene cada "linea" del archivo Excel que estamos parseando
        // accedemos a cada celda como si fuera un elemento en un array
        switch (rowNum) {
          case 9:
            cartaPorte.transpInternac = line[1];
            cartaPorte.entradaSalidaMerc = line[2];
            cartaPorte.viaEntradaSalida = line[3];
            cartaPorte.totalDistRec = line[4];
            break;
          case 13:
            ubicacionOrigen.nombreRemitenteDestinatario = line[2];
            break;
          case 14:
            ubicacionOrigen.rfcRemitenteDestinatario = line[2];
            break;
          case 15:
            ubicacionOrigen.numRegIdTrib = line[2];
            break;
          case 16:
            domicilioOrigen.pais = line[3];
            break;
          case 17:
            domicilioOrigen.estado = line[3];
            break;
          case 18:
            domicilioOrigen.localidad = line[3];
            break;
          case 19:
            domicilioOrigen.municipio = line[3];
            break;
          case 20:
            domicilioOrigen.colonia = line[2];
            break;
          case 21:
            domicilioOrigen.calle = line[2];
            break;
          case 22:
            domicilioOrigen.numeroExterior = line[2];
            break;
          case 23:
            domicilioOrigen.numeroInterior = line[2];
            break;
          case 24:
            domicilioOrigen.codigoPostal = line[2];
            break;
          case 25:
            ubicacionDestino.nombreRemitenteDestinatario = line[2];
            break;
          case 26:
            ubicacionDestino.rfcRemitenteDestinatario = line[2];
            break;
          case 27:
            ubicacionDestino.numRegIdTrib = line[2];
            break;
          case 28:
            domicilioDestino.pais = line[3];
            break;
          case 29:
            domicilioDestino.estado = line[3];
            break;
          case 30:
            domicilioDestino.localidad = line[3];
            break;
          case 31:
            domicilioDestino.municipio = line[3];
            break;
          case 32:
            domicilioDestino.colonia = line[2];
            break;
          case 33:
            domicilioDestino.calle = line[2];
            break;
          case 34:
            domicilioDestino.numeroExterior = line[2];
            break;
          case 35:
            domicilioDestino.numeroInterior = line[2];
            break;
          case 36:
            domicilioDestino.codigoPostal = line[2];
            break;
          case 37:
            cartaPorte.mercancias.numTotalMercancias = line[2];
            break;
          case 38:
            cartaPorte.mercancias.unidadPeso = line[3];
            break;
          case 39:
            cartaPorte.mercancias.pesoBrutoTotal = line[2];
            break;
          case 40:
            cartaPorte.mercancias.pesoNetoTotal = line[2];
            break;
          case 41:
            cartaPorte.mercancias.cargoPorTasacion = line[2];
            break;
        }

        if (rowNum > 42) {
          if (line[0] != null && line[0] != undefined) {
            var mercancia: Mercancia = new Mercancia();
            mercancia.cantidadesTransportadas = [];
            mercancia.bienesTransp = line[0];
            mercancia.cantidad = line[2];
            mercancia.claveUnidad = line[3];
            mercancia.unidad = line[4];
            mercancia.dimensiones = line[5];
            mercancia.materialPeligroso = line[6];
            mercancia.cveMaterialPeligroso = line[7];
            mercancia.embalaje = line[8];
            mercancia.descripEmbalaje = line[9];
            mercancia.pesoEnKg = line[10];
            mercancia.fraccionArancelaria = line[11];
            mercancia.uuidComercioExt = line[12];

            if (line[13] != null && line[13] != undefined) {
              var pedimento: Pedimento = new Pedimento();
              pedimento.pedimento = line[13];
              mercancia.pedimentos = [];
              mercancia.pedimentos.push(pedimento);
            }

            mercancia.detalleMercancia = new DetalleMercancia();
            mercancia.detalleMercancia.unidadPesoMerc = line[14];
            mercancia.detalleMercancia.pesoBruto = line[16];
            mercancia.detalleMercancia.pesoNeto = line[17];
            mercancia.detalleMercancia.pesoTara = line[18];
            mercancia.detalleMercancia.numPiezas = line[19];
            cartaPorte.mercancias.mercancia.push(mercancia);
          }
        }
        // esta variable nos indica el número de línea que estamos procesando
        rowNum++;
      });
      ubicacionOrigen.domicilio = domicilioOrigen;
      ubicacionDestino.domicilio = domicilioDestino;

      cartaPorte.ubicaciones = [];
      cartaPorte.ubicaciones.push(ubicacionOrigen);
      cartaPorte.ubicaciones.push(ubicacionDestino);

      // una vez hemos parseado todo el archivo, devolvemos lo que hayamos procesado
      resolve(cartaPorte);
    });
  }

}
