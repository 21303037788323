import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Aeropuerto } from '../model/aeropuerto';
import { map, catchError } from 'rxjs/operators';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class AeropuertosService {

  private urlEndPoint: string = URL_BACKEND + "/aeropuerto";

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  listAeropuertos(): Observable<Aeropuerto[]> {
    return this.http.get<Aeropuerto[]>(this.urlEndPoint + '/list');
  }

  pageAeropuertos(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getAeropuerto(idAeropuerto: number): Observable<Aeropuerto> {
    return this.http.get<Aeropuerto>(this.urlEndPoint + '/' + idAeropuerto).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  createAeropuerto(aeropuerto: Aeropuerto): Observable<Aeropuerto> {
    return this.http.post(this.urlEndPoint, aeropuerto).pipe(
      map(
        (response: any) => response.aeropuerto as Aeropuerto
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

  updateAeropuerto(aeropuerto: Aeropuerto): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, aeropuerto).pipe(
      map(
        (response: any) => response.aeropuerto as Aeropuerto
      ),
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    )
  }

}
