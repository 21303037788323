import { Component, OnInit, Input } from '@angular/core';
import { Cliente } from '../../../../model/cliente';
import { DetalleClienteService } from './detalle-cliente.service';
import { ClientesService } from '../../../../services/clientes.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-cliente',
  templateUrl: './detalle-cliente.component.html',
  styleUrls: ['./detalle-cliente.component.scss']
})
export class DetalleClienteComponent implements OnInit {

  @Input()
  cliente: Cliente;

  constructor(
    public detalleService: DetalleClienteService,
    private clientesService: ClientesService
  ) { }

  ngOnInit() {
  }

  cancel(): void {
    this.detalleService.cerraModal();
  }

  actualizar(): void {
    this.clientesService.updateCliente(this.cliente).subscribe(
      cliente => {
        this.detalleService.cerraModal();
        swal('Cliente Actualizado', `Cliente ${cliente.nombre1} ${cliente.nombre2} actualizado con exito`, 'success');
      }
    );
  }

}
