import { Component, OnInit, Injector, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Usuario } from '../../model/usuario';
import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../shared/settings/settings.service';
import { AuthService } from '../../auth/auth.service';
import { GraphService } from '../../services/graph.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  menu: Array<any>;
  router: Router;
  usuario: Usuario;
  menusSeleccionados: number[] = []
  photo: any;

  constructor(
    private menuService: MenuService,
    public settings: SettingsService,
    private injector: Injector,
    public authService: AuthService,
    public graphService: GraphService
  ) {
    this.usuario = authService.usuario;
    this.menuService.clearMenu();
    if(this.usuario.menu) {
      this.menuService.addMenu(this.usuario.menu);
      this.menu = menuService.getMenuSorted();
    }
    this.settings.setSetting('theme', 'theme-gradient-sidebar-6');
    $('body')
      .removeClass((index, css) => (css.match(/(^|\s)theme-\S+/g) || []).join(' '))
      .addClass(this.settings.getSetting('theme'));
  }

  ngOnInit() {
    this.router = this.injector.get(Router);
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.settings.app.sidebar.visible = false;
        this.settings.app.sidebar.coverModeVisible = false;
      });
  }

  closeSidebar() {
    this.settings.app.sidebar.coverModeVisible = false;
    this.settings.app.sidebar.visible = false;
  }

  handleSidebar(event) {
    let item = this.getItemElement(event);
    // check click is on a tag
    if (!item) return;

    let ele = $(item),
      liparent = ele.parent()[0];

    let lis = ele.parent().parent().children(); // markup: ul > li > a
    // remove .active from childs
    lis.find('li').removeClass('active');
    // remove .active from siblings ()
    $.each(lis, function(key, li) {
      if (li !== liparent)
        $(li).removeClass('active');
    });
    let next = ele.next();
    if (next.length && next[0].tagName === 'UL') {
      ele.parent().toggleClass('active');
      event.preventDefault();
    }
  }

  // find the a element in click context
  // doesn't check deeply, asumens two levels only
  getItemElement(event) {
    let element = event.target,
      parent = element.parentNode;
    if (element.tagName.toLowerCase() === 'a') {
      return element;
    }
    if (parent.tagName.toLowerCase() === 'a') {
      return parent;
    }
    if (parent.parentNode.tagName.toLowerCase() === 'a') {
      return parent.parentNode;
    }
  }

  muestraHijos(item: any) {
    const index = this.menusSeleccionados.indexOf(item.idMenu);
    if (index !== -1) {
      this.menusSeleccionados.splice(index, 1);
    } else {
      this.menusSeleccionados.push(item.idMenu);
    }
  }

  muestraSubmenu(item: any): boolean {
    const index = this.menusSeleccionados.indexOf(item.idMenuPadre);
    if (index !== -1 && !item.href && !item.heading) {
      return false;
    }
    return true;
  }

}
