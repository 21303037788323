import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ServicioInter } from '../../model/servicio-inter';
import { Cliente } from '../../model/cliente';
import { Mpc } from '../../model/mpc';
import { TipoCambio } from '../../model/tipo-cambio';
import { Proveedor } from '../../model/proveedor';
import { Moneda } from '../../model/moneda';
import { EstatusServicio } from '../../model/estatus-servicio';
import { Shipper } from '../../model/shipper';
import { Consignatario } from '../../model/consignatario';
import { Aeropuerto } from '../../model/aeropuerto';
import { Incoterm } from '../../model/incoterm';
import { Aerolinea } from '../../model/aerolinea';
import { CargoOrigen } from '../../model/cargo-origen';
import { CargoDestino } from '../../model/cargo-destino';
import { CargoAereo } from '../../model/cargo-aereo';
import { Agente } from '../../model/agente';
import { ClientesService } from '../../services/clientes.service';
import { MpcsService } from '../../services/mpcs.service';
import { AuthService } from '../../auth/auth.service';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { TiposCambioService } from '../../services/tipos-cambio.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { MonedasService } from '../../services/monedas.service';
import { ShippersService } from '../../services/shippers.service';
import { ConsignatariosService } from '../../services/consignatarios.service';
import { AeropuertosService } from '../../services/aeropuertos.service';
import { AerolineasService } from '../../services/aerolineas.service';
import { IncotermsService } from '../../services/incoterms.service';
import { CargosAereosService } from '../../services/cargos-aereos.service';
import { ServiciosInterService } from '../../services/servicios-inter.service';
import { AgentesService } from '../../services/agentes.service';
import { TotalesService } from './totales/totales.service';
import { CatalogoService } from './catalogo/catalogo.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { GraphService } from '../../services/graph.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CancelarServicioInterService } from './cancelar-servicio-inter/cancelar-servicio-inter.service';
import { BitacoraInterSapService } from './bitacora-inter-sap/bitacora-inter-sap.service';
import { Router } from '@angular/router';

const swal = require('sweetalert');

@Component({
  selector: 'app-servicios-inter',
  templateUrl: './servicios-inter.component.html',
  styleUrls: ['./servicios-inter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiciosInterComponent implements OnInit {

  servicio: ServicioInter;
  tipo: number;
  listTiposCambio: TipoCambio[];
  listaMpcs: Mpc[];
  listaShippers: Shipper[];
  listaConsignatarios: Consignatario[];
  listAgentes: Agente[];
  listMonedas: Moneda[];
  listAeropuertos: Aeropuerto[];
  listIncoterms: Incoterm[];
  listAerolineas: Aerolinea[];
  listCargosAereos: CargoAereo[];
  tipoDolar: TipoCambio;
  tipoEuro: TipoCambio;
  tipoLibra: TipoCambio;
  tipoYen: TipoCambio;
  tipoRequerido: boolean = false;
  clienteRequerido: boolean = false;
  referenciaFacturacionRequerida: boolean = false;
  mpcRequerido: boolean = false;
  shipperRequerido: boolean = false;
  consignatarioRequerido: boolean = false;
  notasRequeridas: boolean = false;
  aeropuertoOrigenRequerido: boolean = false;
  aeropuertoDestinoRequerido: boolean = false;
  incotermOrigenRequerido: boolean = false;
  incotermDestinoRequerido: boolean = false;
  fechaSalida: NgbDateStruct;
  fechaSalidaRequerida: boolean = false;
  fechaDocumentacion: NgbDateStruct;
  fechaArribo1: NgbDateStruct;
  fechaArribo2: NgbDateStruct;
  fechaArribo3: NgbDateStruct;
  fechaArribo3Requerida: boolean = false;
  numeroPiezasRequerido: boolean = false;
  pesoBrutoRequerido: boolean = false;
  pesoCargableRequerido: boolean = false;
  detalleTotales = {
    costoTotalOrigenUsd: 0.00,
    costoOrigenUsd: 0.00,
    costoOrigenEur: 0.00,
    costoOrigenGbp: 0.00,
    costoOrigenJpy: 0.00,
    costoOrigenMxn: 0.00,
    ventaTotalOrigenUsd: 0.00,
    ventaOrigenUsd: 0.00,
    ventaOrigenEur: 0.00,
    ventaOrigenGbp: 0.00,
    ventaOrigenJpy: 0.00,
    ventaOrigenMxn: 0.00,
    costoTotalDestinoUsd: 0.00,
    costoDestinoUsd: 0.00,
    costoDestinoEur: 0.00,
    costoDestinoGbp: 0.00,
    costoDestinoJpy: 0.00,
    costoDestinoMxn: 0.00,
    ventaTotalDestinoUsd: 0.00,
    ventaDestinoUsd: 0.00,
    ventaDestinoEur: 0.00,
    ventaDestinoGbp: 0.00,
    ventaDestinoJpy: 0.00,
    ventaDestinoMxn: 0.00
  };
  tipoTotales: string = '';
  clienteCtrl: FormControl = new FormControl();
  clienteFilterCtrl: FormControl = new FormControl();
  clientesFiltrados: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  pagadorCtrl: FormControl = new FormControl();
  pagadorFilterCtrl: FormControl = new FormControl();
  pagadoresFiltrados: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  shipperCtrl: FormControl = new FormControl();
  shipperFilterCtrl: FormControl = new FormControl();
  shippersFiltrados: ReplaySubject<Shipper[]> = new ReplaySubject<Shipper[]>(1);
  consignatarioCtrl: FormControl = new FormControl();
  consignatarioFilterCtrl: FormControl = new FormControl();
  consignatariosFiltrados: ReplaySubject<Consignatario[]> = new ReplaySubject<Consignatario[]>(1);
  aeropuertoOrigenCtrl: FormControl = new FormControl();
  aeropuertoOrigenFilterCtrl: FormControl = new FormControl();
  aeropuertosOrigenFiltrados: ReplaySubject<Aeropuerto[]> = new ReplaySubject<Aeropuerto[]>(1);
  aeropuertoDestinoCtrl: FormControl = new FormControl();
  aeropuertoDestinoFilterCtrl: FormControl = new FormControl();
  aeropuertosDestinoFiltrados: ReplaySubject<Aeropuerto[]> = new ReplaySubject<Aeropuerto[]>(1);
  incotermOrigenCtrl: FormControl = new FormControl();
  incotermOrigenFilterCtrl: FormControl = new FormControl();
  incotermsOrigenFiltrados: ReplaySubject<Incoterm[]> = new ReplaySubject<Incoterm[]>(1);
  incotermDestinoCtrl: FormControl = new FormControl();
  incotermDestinoFilterCtrl: FormControl = new FormControl();
  incotermsDestinoFiltrados: ReplaySubject<Incoterm[]> = new ReplaySubject<Incoterm[]>(1);
  agenteCtrl: FormControl = new FormControl();
  agenteFilterCtrl: FormControl = new FormControl();
  agentesFiltrados: ReplaySubject<Agente[]> = new ReplaySubject<Agente[]>(1);
  idServicioSeleccionado: number;
  idServicioStrSeleccionado: string;
  idChatSeleccionado: string;
  tipoCatalogo: string;
  members = [];
  uniqueMembers: string[] = [];
  estatusRespaldo: EstatusServicio = null;
  itemId: number = 100;
  itemIdDestino: number = 200;
  pagadorAlt: boolean = false;
  pagadorRequerido: boolean = false;

  public searching = false;
  protected _onDestroy = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientesService: ClientesService,
    private tiposCambioService: TiposCambioService,
    private mpcsService: MpcsService,
    public pt: PagetitleService,
    public authService: AuthService,
    private proveedoresService: ProveedoresService,
    private monedasServices: MonedasService,
    private shippersServices: ShippersService,
    private consignatariosService: ConsignatariosService,
    private aeropuertosService: AeropuertosService,
    private aerolineasService: AerolineasService,
    private incotermsService: IncotermsService,
    private cargosAereosService: CargosAereosService,
    private serviciosInterService: ServiciosInterService,
    private agentesService: AgentesService,
    public totalesService: TotalesService,
    public catalogoService: CatalogoService,
    private graphService: GraphService,
    private spinnerService: NgxSpinnerService,
    public cancelarServicioInterService: CancelarServicioInterService,
    private bitacoraInterSapService: BitacoraInterSapService,
    private router: Router
  ) {
    pt.setTitle('Servicios');
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(
      params => {
        let anioActual = new Date().getFullYear();
        let mesActual = new Date().getMonth() + 1;
        let idServicioTC: number = +params.get('idServicio');
        if (idServicioTC == 0) {
          anioActual = new Date().getFullYear();
          mesActual = new Date().getMonth() + 1;
          this.tiposCambioService.getTiposCambioByAnioMes(anioActual, mesActual).subscribe(
            response => {
              this.listTiposCambio = response;
              if (this.listTiposCambio != null && this.listTiposCambio.length > 0) {
                this.seleccionaMonedas();
                this.cargarCatalogos();

                let idServicio: number = +params.get('idServicio');
                if (idServicio == 0) {
                  this.servicio = new ServicioInter();
                  this.servicio.idServicio = null;
                  this.servicio.idServicioStr = '';
                  this.servicio.refFacturacion = '';
                  this.servicio.anio = anioActual;
                  this.servicio.trafico = this.authService.account.localAccountId;
                  this.servicio.nombreTrafico = this.authService.account.name;
                  this.servicio.notasCompletas = false;
                  this.servicio.fechaServicio = new Date();
                  this.servicio.ghNumGuia = '';
                  this.servicio.gmNumGuia = '';
                  this.servicio.totalprofitCargosOrigen = 0.00;
                  this.servicio.totalprofitCargosDestino = 0.00;
                  this.servicio.totalprofit = 0.00;
                  this.servicio.ordenCompra = '';
                  const estatusServicio = new EstatusServicio();
                  estatusServicio.idEstatusServicio = 1;//En Transito
                  estatusServicio.descripcion = 'En Tránsito';
                  this.servicio.estatus = estatusServicio;
                } else {
                  this.serviciosInterService.getServicio(idServicio).subscribe(
                    servicio => {
                      console.log(servicio);
                      this.servicio = servicio;
                      this.tipo = this.servicio.tipo == 'Aereo' ? 1 : 2;

                      if (this.servicio.cliente) {
                        this.filtrarClientes();
                        this.clienteFilterCtrl.setValue(this.servicio.cliente.terminoBusqueda);
                        this.clienteCtrl.setValue(this.servicio.cliente);
                      }

                      if (this.servicio.pagador) {
                        this.pagadorAlt = true;
                        this.filtrarPagadores();
                        this.pagadorFilterCtrl.setValue(this.servicio.pagador.terminoBusqueda);
                        this.pagadorCtrl.setValue(this.servicio.pagador);
                      }

                      if (this.servicio.shipper) {
                        console.log("TRAE SHIPPER");
                        this.filtrarShippers();
                        this.shipperFilterCtrl.setValue(this.servicio.shipper.nombre);
                        this.shipperCtrl.setValue(this.servicio.shipper);
                      }

                      if (this.servicio.consignatario) {
                        this.filtrarConsignatarios();
                        this.consignatarioFilterCtrl.setValue(this.servicio.consignatario.nombre);
                        this.consignatarioCtrl.setValue(this.servicio.consignatario);
                      }

                      if (this.servicio.aeropuertoOrigen) {
                        this.filtrarAeropuertosOrigen();
                        this.aeropuertoOrigenFilterCtrl.setValue(this.servicio.aeropuertoOrigen.nombre);
                        this.aeropuertoOrigenCtrl.setValue(this.servicio.aeropuertoOrigen);
                      }

                      if (this.servicio.aeropuertoDestino) {
                        this.filtrarAeropuertosDestino();
                        this.aeropuertoDestinoFilterCtrl.setValue(this.servicio.aeropuertoDestino.nombre);
                        this.aeropuertoDestinoCtrl.setValue(this.servicio.aeropuertoDestino);
                      }

                      if (this.servicio.incotermOrigen) {
                        this.filtrarIncotermsOrigen();
                        this.incotermOrigenFilterCtrl.setValue(this.servicio.incotermOrigen.descripcion);
                        this.incotermOrigenCtrl.setValue(this.servicio.incotermOrigen);
                      }

                      if (this.servicio.incotermDestino) {
                        this.filtrarIncotermsDestino();
                        this.incotermDestinoFilterCtrl.setValue(this.servicio.incotermDestino.descripcion);
                        this.incotermDestinoCtrl.setValue(this.servicio.incotermDestino);
                      }

                      if (this.servicio.ghPrefijoAgente) {
                        this.filtrarAgentes();
                        this.agenteFilterCtrl.setValue(this.servicio.ghPrefijoAgente.descCorta);
                        this.agenteCtrl.setValue(this.servicio.ghPrefijoAgente);
                      }

                      if (this.servicio.fechaSalida) {
                        let fechaSalida: string = JSON.stringify(this.servicio.fechaSalida);
                        this.fechaSalida = {
                          day: parseInt(fechaSalida.substring(9, 11)),
                          month: parseInt(fechaSalida.substring(6, 8)),
                          year: parseInt(fechaSalida.substring(1, 5))
                        };
                      }

                      if (this.servicio.fechaDocumentacion) {
                        let fechaDocumentacion: string = JSON.stringify(this.servicio.fechaDocumentacion);
                        this.fechaDocumentacion = {
                          day: parseInt(fechaDocumentacion.substring(9, 11)),
                          month: parseInt(fechaDocumentacion.substring(6, 8)),
                          year: parseInt(fechaDocumentacion.substring(1, 5))
                        };
                      }

                      if (this.servicio.fechaArribo1) {
                        let fechaArribo1: string = JSON.stringify(this.servicio.fechaArribo1);
                        this.fechaArribo1 = {
                          day: parseInt(fechaArribo1.substring(9, 11)),
                          month: parseInt(fechaArribo1.substring(6, 8)),
                          year: parseInt(fechaArribo1.substring(1, 5))
                        };
                      }

                      if (this.servicio.fechaArribo2) {
                        let fechaArribo2: string = JSON.stringify(this.servicio.fechaArribo2);
                        this.fechaArribo2 = {
                          day: parseInt(fechaArribo2.substring(9, 11)),
                          month: parseInt(fechaArribo2.substring(6, 8)),
                          year: parseInt(fechaArribo2.substring(1, 5))
                        };
                      }

                      if (this.servicio.fechaArribo3) {
                        let fechaArribo3: string = JSON.stringify(this.servicio.fechaArribo3);
                        this.fechaArribo3 = {
                          day: parseInt(fechaArribo3.substring(9, 11)),
                          month: parseInt(fechaArribo3.substring(6, 8)),
                          year: parseInt(fechaArribo3.substring(1, 5))
                        };
                      }

                      if (this.servicio.cargosOrigen) {
                        this.servicio.cargosOrigen.map(
                          origen => {
                            origen.cargoAereoRequerido = false;
                            origen.monedaCostoRequerida = false;
                            origen.monedaVentaRequerida = false;
                            origen.proveedorRequerido = false;

                            origen.proveedorCtrl = new FormControl();
                            origen.proveedorFilterCtrl = new FormControl();
                            this.filtrarProveedoresOrigen(origen);
                            origen.proveedorCtrl.setValue(origen.proveedor);
                            origen.proveedorFilterCtrl.setValue(origen.proveedor.razonComercial);
                            this.itemId = origen.itemID + 1;
                          }
                        );
                      }

                      if (this.servicio.cargosDestino) {
                        this.servicio.cargosDestino.map(
                          destino => {
                            destino.cargoAereoRequerido = false;
                            destino.monedaCostoRequerida = false;
                            destino.monedaVentaRequerida = false;
                            destino.proveedorRequerido = false;

                            destino.proveedorCtrl = new FormControl();
                            destino.proveedorFilterCtrl = new FormControl();
                            this.filtrarProveedoresDestino(destino);
                            destino.proveedorCtrl.setValue(destino.proveedor);
                            destino.proveedorFilterCtrl.setValue(destino.proveedor.razonComercial);
                            this.itemId = destino.itemID + 1;
                          }
                        );
                      }

                      this.actualizaTotales();
                    }
                  );
                }
              } else {
                swal('Tipos de Cambio', `No existen Tipos de Cambio configurados para ${anioActual}/${mesActual}`, 'error');
                //this.router.navigate(['/tiposCambio']);
              }
            }
          );

        }else{
          this.serviciosInterService.getServicio(idServicioTC).subscribe(
            servicio => {
              anioActual = Number(servicio.fechaServicio.toString().substring(0,4));
              mesActual = Number(servicio.fechaServicio.toString().substring(5,7));
              this.tiposCambioService.getTiposCambioByAnioMes(anioActual, mesActual).subscribe(
                response => {
                  this.listTiposCambio = response;
                  if (this.listTiposCambio != null && this.listTiposCambio.length > 0) {
                    this.seleccionaMonedas();
                    this.cargarCatalogos();

                    let idServicio: number = +params.get('idServicio');
                    if (idServicio == 0) {
                      this.servicio = new ServicioInter();
                      this.servicio.idServicio = null;
                      this.servicio.idServicioStr = '';
                      this.servicio.refFacturacion = '';
                      this.servicio.anio = anioActual;
                      this.servicio.trafico = this.authService.account.localAccountId;
                      this.servicio.nombreTrafico = this.authService.account.name;
                      this.servicio.notasCompletas = false;
                      this.servicio.fechaServicio = new Date();
                      this.servicio.ghNumGuia = '';
                      this.servicio.gmNumGuia = '';
                      this.servicio.totalprofitCargosOrigen = 0.00;
                      this.servicio.totalprofitCargosDestino = 0.00;
                      this.servicio.totalprofit = 0.00;
                      this.servicio.ordenCompra = '';
                      const estatusServicio = new EstatusServicio();
                      estatusServicio.idEstatusServicio = 1;//En Transito
                      estatusServicio.descripcion = 'En Tránsito';
                      this.servicio.estatus = estatusServicio;
                    } else {
                      this.serviciosInterService.getServicio(idServicio).subscribe(
                        servicio => {
                          console.log(servicio);
                          this.servicio = servicio;
                          this.tipo = this.servicio.tipo == 'Aereo' ? 1 : 2;

                          if (this.servicio.cliente) {
                            this.filtrarClientes();
                            this.clienteFilterCtrl.setValue(this.servicio.cliente.terminoBusqueda);
                            this.clienteCtrl.setValue(this.servicio.cliente);
                          }

                          if (this.servicio.pagador) {
                            this.pagadorAlt = true;
                            this.filtrarPagadores();
                            this.pagadorFilterCtrl.setValue(this.servicio.pagador.terminoBusqueda);
                            this.pagadorCtrl.setValue(this.servicio.pagador);
                          }

                          if (this.servicio.shipper) {
                            console.log("TRAE SHIPPER");
                            this.filtrarShippers();
                            this.shipperFilterCtrl.setValue(this.servicio.shipper.nombre);
                            this.shipperCtrl.setValue(this.servicio.shipper);
                          }

                          if (this.servicio.consignatario) {
                            this.filtrarConsignatarios();
                            this.consignatarioFilterCtrl.setValue(this.servicio.consignatario.nombre);
                            this.consignatarioCtrl.setValue(this.servicio.consignatario);
                          }

                          if (this.servicio.aeropuertoOrigen) {
                            this.filtrarAeropuertosOrigen();
                            this.aeropuertoOrigenFilterCtrl.setValue(this.servicio.aeropuertoOrigen.nombre);
                            this.aeropuertoOrigenCtrl.setValue(this.servicio.aeropuertoOrigen);
                          }

                          if (this.servicio.aeropuertoDestino) {
                            this.filtrarAeropuertosDestino();
                            this.aeropuertoDestinoFilterCtrl.setValue(this.servicio.aeropuertoDestino.nombre);
                            this.aeropuertoDestinoCtrl.setValue(this.servicio.aeropuertoDestino);
                          }

                          if (this.servicio.incotermOrigen) {
                            this.filtrarIncotermsOrigen();
                            this.incotermOrigenFilterCtrl.setValue(this.servicio.incotermOrigen.descripcion);
                            this.incotermOrigenCtrl.setValue(this.servicio.incotermOrigen);
                          }

                          if (this.servicio.incotermDestino) {
                            this.filtrarIncotermsDestino();
                            this.incotermDestinoFilterCtrl.setValue(this.servicio.incotermDestino.descripcion);
                            this.incotermDestinoCtrl.setValue(this.servicio.incotermDestino);
                          }

                          if (this.servicio.ghPrefijoAgente) {
                            this.filtrarAgentes();
                            this.agenteFilterCtrl.setValue(this.servicio.ghPrefijoAgente.descCorta);
                            this.agenteCtrl.setValue(this.servicio.ghPrefijoAgente);
                          }

                          if (this.servicio.fechaSalida) {
                            let fechaSalida: string = JSON.stringify(this.servicio.fechaSalida);
                            this.fechaSalida = {
                              day: parseInt(fechaSalida.substring(9, 11)),
                              month: parseInt(fechaSalida.substring(6, 8)),
                              year: parseInt(fechaSalida.substring(1, 5))
                            };
                          }

                          if (this.servicio.fechaDocumentacion) {
                            let fechaDocumentacion: string = JSON.stringify(this.servicio.fechaDocumentacion);
                            this.fechaDocumentacion = {
                              day: parseInt(fechaDocumentacion.substring(9, 11)),
                              month: parseInt(fechaDocumentacion.substring(6, 8)),
                              year: parseInt(fechaDocumentacion.substring(1, 5))
                            };
                          }

                          if (this.servicio.fechaArribo1) {
                            let fechaArribo1: string = JSON.stringify(this.servicio.fechaArribo1);
                            this.fechaArribo1 = {
                              day: parseInt(fechaArribo1.substring(9, 11)),
                              month: parseInt(fechaArribo1.substring(6, 8)),
                              year: parseInt(fechaArribo1.substring(1, 5))
                            };
                          }

                          if (this.servicio.fechaArribo2) {
                            let fechaArribo2: string = JSON.stringify(this.servicio.fechaArribo2);
                            this.fechaArribo2 = {
                              day: parseInt(fechaArribo2.substring(9, 11)),
                              month: parseInt(fechaArribo2.substring(6, 8)),
                              year: parseInt(fechaArribo2.substring(1, 5))
                            };
                          }

                          if (this.servicio.fechaArribo3) {
                            let fechaArribo3: string = JSON.stringify(this.servicio.fechaArribo3);
                            this.fechaArribo3 = {
                              day: parseInt(fechaArribo3.substring(9, 11)),
                              month: parseInt(fechaArribo3.substring(6, 8)),
                              year: parseInt(fechaArribo3.substring(1, 5))
                            };
                          }

                          if (this.servicio.cargosOrigen) {
                            this.servicio.cargosOrigen.map(
                              origen => {
                                origen.cargoAereoRequerido = false;
                                origen.monedaCostoRequerida = false;
                                origen.monedaVentaRequerida = false;
                                origen.proveedorRequerido = false;

                                origen.proveedorCtrl = new FormControl();
                                origen.proveedorFilterCtrl = new FormControl();
                                this.filtrarProveedoresOrigen(origen);
                                origen.proveedorCtrl.setValue(origen.proveedor);
                                origen.proveedorFilterCtrl.setValue(origen.proveedor.razonComercial);
                                this.itemId = origen.itemID + 1;
                              }
                            );
                          }

                          if (this.servicio.cargosDestino) {
                            this.servicio.cargosDestino.map(
                              destino => {
                                destino.cargoAereoRequerido = false;
                                destino.monedaCostoRequerida = false;
                                destino.monedaVentaRequerida = false;
                                destino.proveedorRequerido = false;

                                destino.proveedorCtrl = new FormControl();
                                destino.proveedorFilterCtrl = new FormControl();
                                this.filtrarProveedoresDestino(destino);
                                destino.proveedorCtrl.setValue(destino.proveedor);
                                destino.proveedorFilterCtrl.setValue(destino.proveedor.razonComercial);
                                this.itemId = destino.itemID + 1;
                              }
                            );
                          }

                          this.actualizaTotales();
                        }
                      );
                    }
                  } else {
                    swal('Tipos de Cambio', `No existen Tipos de Cambio configurados para ${anioActual}/${mesActual}`, 'error');
                    //this.router.navigate(['/tiposCambio']);
                  }
                }
              );
              }
            );
        }

      }
    );


    this.catalogoService.saveEmitter.subscribe(
      nuevoCatalogo => {
        if (this.tipoCatalogo == "Shipper") {
          this.cargarShippers();
          var nuevoShipper = nuevoCatalogo as Shipper;
          this.shipperFilterCtrl.setValue(nuevoShipper.nombre);
          this.shipperCtrl.setValue(nuevoShipper);
        } else if (this.tipoCatalogo == "Consignatario") {
          this.cargarConsignatarios();
          var nuevoConsignatario = nuevoCatalogo as Consignatario;
          this.consignatarioFilterCtrl.setValue(nuevoConsignatario.nombre);
          this.consignatarioCtrl.setValue(nuevoConsignatario);
        } else if (this.tipoCatalogo == "Aeropuerto Origen" || this.tipoCatalogo == "Aeropuerto Destino") {
          this.cargarAeropuertos();
          var nuevoAeropuerto = nuevoCatalogo as Aeropuerto;
          if (this.tipoCatalogo == "Aeropuerto Origen") {
            this.aeropuertoOrigenFilterCtrl.setValue(nuevoAeropuerto.nombre);
            this.aeropuertoOrigenCtrl.setValue(nuevoAeropuerto);
          } else if (this.tipoCatalogo == "Aeropuerto Destino") {
            this.aeropuertoDestinoFilterCtrl.setValue(nuevoAeropuerto.nombre);
            this.aeropuertoDestinoCtrl.setValue(nuevoAeropuerto);
          }
        } else if (this.tipoCatalogo == "Incoterm Origen" || this.tipoCatalogo == "Incoterm Destino") {
          this.cargarIncoterms();
          var nuevoIncoterm = nuevoCatalogo as Incoterm;
          if (this.tipoCatalogo == "Incoterm Origen") {
            this.incotermOrigenFilterCtrl.setValue(nuevoIncoterm.descripcion);
            this.incotermOrigenCtrl.setValue(nuevoIncoterm);
          } else if (this.tipoCatalogo == "Incoterm Destino") {
            this.incotermDestinoFilterCtrl.setValue(nuevoIncoterm.descripcion);
            this.incotermDestinoCtrl.setValue(nuevoIncoterm);
          }
        }
      }
    );

  }

  public isReadOnly(): boolean {
    if (this.authService.hasRole('ROLE_SUPERADMIN')) {
      return false;
    }
    return this.authService.hasRole('ROLE_CUENTAS_PAGAR') || this.authService.hasRole('ROLE_CUENTAS_COBRAR')||this.authService.hasRole('ROLE_ANALISTA') || this.authService.hasRole('ROLE_EJECUTIVO')
      || this.servicio.estatus.idEstatusServicio == 3 || this.servicio.estatus.idEstatusServicio == 4;
  }

  private seleccionaMonedas(): void {
    this.listTiposCambio.map(tipo => {
      switch (tipo.moneda.idMoneda) {
        case 1:
          this.tipoDolar = tipo;
          break;
        case 2:
          this.tipoEuro = tipo;
          break;
        case 3:
          this.tipoLibra = tipo;
          break;
        case 4:
          this.tipoYen = tipo;
          break;
      }
    });
  }

  private cargarCatalogos(): void {
    this.filtrarClientes();
    this.filtrarPagadores();
    this.cargarMpcs();
    this.cargarShippers();
    this.cargarConsignatarios();
    this.cargarAgentes();
    this.cargarMonedas();
    this.cargarAeropuertos();
    this.cargarIncoterms();
    this.cargarAerolineas();
    this.cargarCargosAereos();
    this.cargarUsuariosChat();
  }

  private filtrarClientes(): void {
    this.clienteFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        search => {
          this.clientesService.listFirstClientesByBusqueda(search).subscribe(
            clientesFiltrados => {
              this.searching = false;
              if (this.clientesFiltrados == null) {
                this.clientesFiltrados = new ReplaySubject<Cliente[]>(1);
              }
              this.clientesFiltrados.next(clientesFiltrados);
            }
          );
        }
      );
  }

  private filtrarPagadores(): void {
    this.pagadorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        search => {
          this.clientesService.listFirstClientesByBusqueda(search).subscribe(
            pagadoresFiltrados => {
              this.searching = false;
              if (this.pagadoresFiltrados == null) {
                this.pagadoresFiltrados = new ReplaySubject<Cliente[]>(1);
              }
              this.pagadoresFiltrados.next(pagadoresFiltrados);
            }
          );
        }
      );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private cargarMpcs(): void {
    this.mpcsService.listMpcs().subscribe(
      response => {
        this.listaMpcs = response;
      }
    );
  }

  private cargarShippers(): void {
    this.shippersServices.listShippers().subscribe(
      response => {
        this.listaShippers = response;
        this.filtrarShippers();
      }
    );
  }

  private filtrarShippers(): void {
    this.shipperFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listaShippers) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listaShippers.filter(shipper => shipper.nombre.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(shippersFiltrados => {
        this.searching = false;
        this.shippersFiltrados.next(shippersFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private cargarConsignatarios(): void {
    this.consignatariosService.listConsignatarios().subscribe(
      response => {
        this.listaConsignatarios = response;
        this.filtrarConsignatarios();
      }
    );
  }

  private filtrarConsignatarios(): void {
    this.consignatarioFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listaConsignatarios) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listaConsignatarios.filter(consignatario => consignatario.nombre.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(consignatariosFiltrados => {
        this.searching = false;
        this.consignatariosFiltrados.next(consignatariosFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private cargarAgentes(): void {
    this.agentesService.listAgentes().subscribe(
      response => {
        this.listAgentes = response;
        this.filtrarAgentes();
      }
    );
  }

  private filtrarAgentes(): void {
    this.agenteFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listAgentes) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listAgentes.filter(agente => agente.descCorta.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(agentesFiltrados => {
        this.searching = false;
        if (this.agentesFiltrados == null) {
          this.agentesFiltrados = new ReplaySubject<Agente[]>(1);
        }
        this.agentesFiltrados.next(agentesFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private cargarMonedas(): void {
    this.monedasServices.listMonedas().subscribe(
      response => {
        this.listMonedas = response;
      }
    );
  }

  private cargarAeropuertos(): void {
    this.aeropuertosService.listAeropuertos().subscribe(
      response => {
        this.listAeropuertos = response;
        this.filtrarAeropuertosOrigen();
        this.filtrarAeropuertosDestino();
      }
    );
  }

  private filtrarAeropuertosOrigen(): void {
    this.aeropuertoOrigenFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listAeropuertos) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listAeropuertos.filter(aeropuerto => aeropuerto.nombre.toLowerCase().indexOf(search.toLowerCase()) > -1 || aeropuerto.iniciales.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(aeropuertosFiltrados => {
        this.searching = false;
        if (this.aeropuertosOrigenFiltrados == null) {
          this.aeropuertosOrigenFiltrados = new ReplaySubject<Aeropuerto[]>(1);
        }
        this.aeropuertosOrigenFiltrados.next(aeropuertosFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private filtrarAeropuertosDestino(): void {
    this.aeropuertoDestinoFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listAeropuertos) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listAeropuertos.filter(aeropuerto => aeropuerto.nombre.toLowerCase().indexOf(search.toLowerCase()) > -1 || aeropuerto.iniciales.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(aeropuertosFiltrados => {
        this.searching = false;
        if (this.aeropuertosDestinoFiltrados == null) {
          this.aeropuertosDestinoFiltrados = new ReplaySubject<Aeropuerto[]>(1);
        }
        this.aeropuertosDestinoFiltrados.next(aeropuertosFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private cargarIncoterms(): void {
    this.incotermsService.listIncoterms().subscribe(
      response => {
        this.listIncoterms = response;
        this.filtrarIncotermsOrigen();
        this.filtrarIncotermsDestino();
      }
    );
  }

  private filtrarIncotermsOrigen(): void {
    this.incotermOrigenFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listIncoterms) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listIncoterms.filter(incoterm => incoterm.descripcion.toLowerCase().indexOf(search.toLowerCase()) > -1 || incoterm.iniciales.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(incotermsFiltrados => {
        this.searching = false;
        if (this.incotermsOrigenFiltrados == null) {
          this.incotermsOrigenFiltrados = new ReplaySubject<Incoterm[]>(1);
        }
        this.incotermsOrigenFiltrados.next(incotermsFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private filtrarIncotermsDestino(): void {
    this.incotermDestinoFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.listIncoterms) {
            return [];
          }

          // simulate server fetching and filtering data
          return this.listIncoterms.filter(incoterm => incoterm.descripcion.toLowerCase().indexOf(search.toLowerCase()) > -1 || incoterm.iniciales.toLowerCase().indexOf(search.toLowerCase()) > -1);
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(incotermsFiltrados => {
        this.searching = false;
        if (this.incotermsDestinoFiltrados == null) {
          this.incotermsDestinoFiltrados = new ReplaySubject<Incoterm[]>(1);
        }
        this.incotermsDestinoFiltrados.next(incotermsFiltrados);
      },
        error => {
          console.log(error);
          this.searching = false;
        });
  }

  private cargarAerolineas(): void {
    this.aerolineasService.listAerolineas().subscribe(
      response => {
        this.listAerolineas = response;
      }
    );
  }

  private cargarCargosAereos(): void {
    this.cargosAereosService.listCargosAereos().subscribe(
      response => {
        this.listCargosAereos = response;
      }
    );
  }

  private cargarUsuariosChat(): void {
    //Se agrega al usuario de tráfico al chatType
    let user_trafico = {
      '@odata.type': '#microsoft.graph.aadUserConversationMember',
      'roles': ['owner'],
      'user@odata.bind': 'https://graph.microsoft.com/v1.0/users(\'' + this.authService.account.localAccountId + '\')'
    };
    this.members.push(user_trafico);
    this.uniqueMembers.push(this.authService.account.localAccountId);

    this.graphService.getMembersSuperAdmin().subscribe(
      response => {
        response.value.map(
          usuario => {
            var existe: boolean = false;
            this.uniqueMembers.map(
              existente => {
                if (existente == usuario.id) {
                  existe = true;
                }
              }
            );
            if (!existe) {
              let user = {
                '@odata.type': '#microsoft.graph.aadUserConversationMember',
                'roles': ['owner'],
                'user@odata.bind': 'https://graph.microsoft.com/v1.0/users(\'' + usuario.id + '\')'
              };
              this.members.push(user);
              this.uniqueMembers.push(usuario.id);
            }
          }
        );

        this.graphService.getMembersCuentasPagar().subscribe(
          response => {
            response.value.map(
              usuario => {
                var existe: boolean = false;
                this.uniqueMembers.map(
                  existente => {
                    if (existente == usuario.id) {
                      existe = true;
                    }
                  }
                );
                if (!existe) {
                  let user = {
                    '@odata.type': '#microsoft.graph.aadUserConversationMember',
                    'roles': ['owner'],
                    'user@odata.bind': 'https://graph.microsoft.com/v1.0/users(\'' + usuario.id + '\')'
                  };
                  this.members.push(user);
                  this.uniqueMembers.push(usuario.id);
                }
              }
            );
          }
        );
      }
    );
  }

  compararCliente(o1: Cliente, o2: Cliente) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idCliente === o2.idCliente;
  }

  compararPagador(o1: Cliente, o2: Cliente) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idCliente === o2.idCliente;
  }

  compararMpc(o1: Mpc, o2: Mpc) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idMpc === o2.idMpc;
  }

  compararTipo(o1: String, o2: String) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1 === o2;
  }

  compararShipper(o1: Shipper, o2: Shipper) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idShipper === o2.idShipper;
  }

  compararConsignatario(o1: Consignatario, o2: Consignatario) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idConsignatario === o2.idConsignatario;
  }

  compararAeropuerto(o1: Aeropuerto, o2: Aeropuerto) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idAeropuerto === o2.idAeropuerto;
  }

  compararAerolinea(o1: Aerolinea, o2: Aerolinea) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idAerolinea === o2.idAerolinea;
  }

  compararIncoterm(o1: Incoterm, o2: Incoterm) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idIncoterm === o2.idIncoterm;
  }

  compararAgente(o1: Agente, o2: Agente) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idAgente === o2.idAgente;
  }

  compararProveedor(o1: Proveedor, o2: Proveedor) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idProveedor === o2.idProveedor;
  }

  compararCargoAereo(o1: CargoAereo, o2: CargoAereo) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idCargoAereo === o2.idCargoAereo;
  }

  compararMoneda(o1: Moneda, o2: Moneda) {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1.idMoneda === o2.idMoneda;
  }

  agregarCargoOrigen(): void {
    if (this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
      if (this.servicio.cargosOrigen == null) {
        this.servicio.cargosOrigen = [];
      }
      let cargoOrigen = new CargoOrigen();
      cargoOrigen.compraTarifa = 0.00;
      cargoOrigen.compraMonto = 0.00;
      cargoOrigen.ventaTarifa = 0.00;
      cargoOrigen.ventaMonto = 0.00;
      cargoOrigen.proveedorCtrl = new FormControl();
      cargoOrigen.proveedorFilterCtrl = new FormControl();
      cargoOrigen.proveedoresFiltrados = new ReplaySubject<Proveedor[]>(1);
      cargoOrigen.pedidoCliente = '';
      cargoOrigen.itemID = this.itemId;
      this.filtrarProveedoresOrigen(cargoOrigen);
      this.servicio.cargosOrigen.push(cargoOrigen);
      this.itemId = this.itemId + 1;
    } else {
      swal('Peso Cargable', 'El peso cargable debe ser mayor a 0', 'warning');
    }
  }

  eliminarCargoOrigen(origen: CargoOrigen, guardado: boolean): void {
    swal({
      title: 'Eliminar cargo origen',
      text: 'Esta seguro que desea eliminar el cargo origen?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar.',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, eliminar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }).then((isConfirm: boolean) => {
      if (isConfirm) {
        const index: number = this.servicio.cargosOrigen.indexOf(origen);
        if (index !== -1) {
          if (guardado) {
            this.spinnerService.show();
            let origenCopiado = Object.assign({}, origen);
            origenCopiado.proveedorCtrl = null;
            origenCopiado.proveedorFilterCtrl = null;
            origenCopiado.proveedoresFiltrados = null;
            this.serviciosInterService.deleteOrigen(this.servicio.idServicio, origenCopiado).subscribe(
              origenEliminado => {
                console.log(origenEliminado);
                this.servicio.cargosOrigen.splice(index, 1);
                this.actualizaTotales();
                swal('Cargo Origen Eliminado', 'Se ha eliminado el cargo con exito', 'success');
                this.spinnerService.hide();
              }
            );
          } else {
            this.servicio.cargosOrigen.splice(index, 1);
            this.actualizaTotales();
          }
        }
      }
    });
  }

  actualizarCargoOrigen(origen: CargoOrigen): void {
    swal({
      title: origen.pedidoCliente && origen.pedidoCliente != '' ? 'Actualizar cargo origen' : 'Crear cargo origen',
      text: 'Esta seguro que desea ' + (origen.pedidoCliente && origen.pedidoCliente != '' ? 'actualizar el cargo origen?' : 'crear el cargo origen?'),
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar.',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, actualizar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }).then((isConfirm: boolean) => {
      if (isConfirm) {
        this.spinnerService.show();
        let origenCopiado = Object.assign({}, origen);
        origenCopiado.proveedorCtrl = null;
        origenCopiado.proveedorFilterCtrl = null;
        origenCopiado.proveedoresFiltrados = null;
        this.serviciosInterService.updateOrigen(this.servicio.idServicio, origenCopiado).subscribe(
          origenActualizado => {
            console.log(origenActualizado);
            swal('Cargo Origen Actualizado', 'Se ha actualizado el cargo con exito', 'success');
            this.spinnerService.hide();
          }
        );
      }
    });
  }

  private filtrarProveedoresOrigen(origen: CargoOrigen): void {
    origen.proveedorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(search => {
        this.proveedoresService.listFirstProveedoresByRazonComercial(search).subscribe(
          proveedoresFiltrados => {
            this.searching = false;
            if (origen.proveedoresFiltrados == null) {
              origen.proveedoresFiltrados = new ReplaySubject<Proveedor[]>(1);
            }
            origen.proveedoresFiltrados.next(proveedoresFiltrados);
          }
        );
      },
        error => {
          console.log(error);
          this.searching = false;
        }
      );
  }

  agregarCargoDestino(): void {
    if (this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
      if (this.servicio.cargosDestino == null) {
        this.servicio.cargosDestino = [];
      }
      let cargoDestino = new CargoDestino();
      cargoDestino.compraTarifa = 0.00;
      cargoDestino.compraMonto = 0.00;
      cargoDestino.ventaTarifa = 0.00;
      cargoDestino.ventaMonto = 0.00;
      cargoDestino.proveedorCtrl = new FormControl();
      cargoDestino.proveedorFilterCtrl = new FormControl();
      cargoDestino.proveedoresFiltrados = new ReplaySubject<Proveedor[]>(1);
      cargoDestino.pedidoCliente = '';
      cargoDestino.itemID = this.itemIdDestino;
      this.filtrarProveedoresDestino(cargoDestino);
      this.servicio.cargosDestino.push(cargoDestino);
      this.itemIdDestino = this.itemIdDestino + 1;
    } else {
      swal('Peso Cargable', 'El peso cargable debe ser mayor a 0', 'warning');
    }
  }

  eliminarCargoDestino(destino: CargoDestino, guardado: boolean): void {
    swal({
      title: 'Eliminar cargo destino',
      text: 'Esta seguro que desea eliminar el cargo destino?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar.',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, eliminar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }).then((isConfirm: boolean) => {
      if (isConfirm) {
        const index: number = this.servicio.cargosDestino.indexOf(destino);
        if (index !== -1) {
          if (guardado) {
            this.spinnerService.show();
            let destinoCopiado = Object.assign({}, destino);
            destinoCopiado.proveedorCtrl = null;
            destinoCopiado.proveedorFilterCtrl = null;
            destinoCopiado.proveedoresFiltrados = null;
            this.serviciosInterService.updateDestino(this.servicio.idServicio, destinoCopiado).subscribe(
              destinoEliminado => {
                console.log(destinoEliminado);
                this.servicio.cargosDestino.splice(index, 1);
                this.actualizaTotales();
                swal('Cargo Destino Eliminado', 'Se ha eliminado el cargo con exito', 'success');
                this.spinnerService.hide();
              }
            );
          } else {
            this.servicio.cargosDestino.splice(index, 1);
            this.actualizaTotales();
          }
        }
      }
    });
  }

  actualizarCargoDestino(destino: CargoDestino): void {
    swal({
      title: destino.pedidoCliente && destino.pedidoCliente != '' ? 'Actualizar cargo destino' : 'Crear cargo destino',
      text: 'Esta seguro que desea ' + (destino.pedidoCliente && destino.pedidoCliente != '' ? 'actualizar el cargo destino?' : 'crear el cargo destino?'),
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar.',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, eliminar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }).then((isConfirm: boolean) => {
      if (isConfirm) {
        this.spinnerService.show();
        let destinoCopiado = Object.assign({}, destino);
        destinoCopiado.proveedorCtrl = null;
        destinoCopiado.proveedorFilterCtrl = null;
        destinoCopiado.proveedoresFiltrados = null;
        this.serviciosInterService.updateDestino(this.servicio.idServicio, destinoCopiado).subscribe(
          destinoActualizado => {
            console.log(destinoActualizado);
            swal('Cargo Destino Actualizado', 'Se ha actualizado el cargo con exito', 'success');
            this.spinnerService.hide();
          }
        );
      }
    });
  }

  private filtrarProveedoresDestino(destino: CargoDestino): void {
    destino.proveedorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(search => {
        this.proveedoresService.listFirstProveedoresByRazonComercial(search).subscribe(
          proveedoresFiltrados => {
            this.searching = false;
            if (destino.proveedoresFiltrados == null) {
              destino.proveedoresFiltrados = new ReplaySubject<Proveedor[]>(1);
            }
            destino.proveedoresFiltrados.next(proveedoresFiltrados);
          }
        );
      },
        error => {
          console.log(error);
          this.searching = false;
        }
      );
  }

  actualizaTotales(): void {
    this.detalleTotales = {
      costoTotalOrigenUsd: 0.00,
      costoOrigenUsd: 0.00,
      costoOrigenEur: 0.00,
      costoOrigenGbp: 0.00,
      costoOrigenJpy: 0.00,
      costoOrigenMxn: 0.00,
      ventaTotalOrigenUsd: 0.00,
      ventaOrigenUsd: 0.00,
      ventaOrigenEur: 0.00,
      ventaOrigenGbp: 0.00,
      ventaOrigenJpy: 0.00,
      ventaOrigenMxn: 0.00,

      costoTotalDestinoUsd: 0.00,
      costoDestinoUsd: 0.00,
      costoDestinoEur: 0.00,
      costoDestinoGbp: 0.00,
      costoDestinoJpy: 0.00,
      costoDestinoMxn: 0.00,
      ventaTotalDestinoUsd: 0.00,
      ventaDestinoUsd: 0.00,
      ventaDestinoEur: 0.00,
      ventaDestinoGbp: 0.00,
      ventaDestinoJpy: 0.00,
      ventaDestinoMxn: 0.00
    };

    this.servicio.totalprofitCargosOrigen = 0.00;
    this.servicio.totalprofitCargosDestino = 0.00;
    this.servicio.totalprofit = 0.00;

    if (this.servicio.cargosOrigen) {
      this.servicio.cargosOrigen.map(
        origen => {
          let costoDolares: number = 0.00;
          let ventaDolares: number = 0.00;

          if (origen.compraTarifa && origen.compraTarifa > 0 && this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
            origen.compraMonto = origen.compraTarifa * this.servicio.pesoCargable;
          }

          if (origen.ventaTarifa && origen.ventaTarifa > 0 && this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
            origen.ventaMonto = origen.ventaTarifa * this.servicio.pesoCargable;
          }

          if (origen.monedaCosto) {
            switch (origen.monedaCosto.idMoneda) {
              case 1:
                costoDolares = origen.compraMonto;
                this.detalleTotales.costoOrigenUsd = this.detalleTotales.costoOrigenUsd + origen.compraMonto;
                break;
              case 2:
                costoDolares = origen.compraMonto * this.tipoEuro.valor;
                this.detalleTotales.costoOrigenEur = this.detalleTotales.costoOrigenEur + origen.compraMonto;
                break;
              case 3:
                costoDolares = origen.compraMonto * this.tipoLibra.valor;
                this.detalleTotales.costoOrigenGbp = this.detalleTotales.costoOrigenGbp + origen.compraMonto;
                break;
              case 4:
                costoDolares = origen.compraMonto * this.tipoYen.valor;
                this.detalleTotales.costoOrigenJpy = this.detalleTotales.costoOrigenJpy + origen.compraMonto;
                break;
              case 5:
                costoDolares = origen.compraMonto / this.tipoDolar.valor;
                this.detalleTotales.costoOrigenMxn = this.detalleTotales.costoOrigenMxn + origen.compraMonto;
                break;
            }
            this.detalleTotales.costoTotalOrigenUsd = this.detalleTotales.costoTotalOrigenUsd + costoDolares;
          }

          if (origen.monedaVenta) {
            switch (origen.monedaVenta.idMoneda) {
              case 1:
                ventaDolares = origen.ventaMonto;
                this.detalleTotales.ventaOrigenUsd = this.detalleTotales.ventaOrigenUsd + origen.ventaMonto;
                break;
              case 2:
                ventaDolares = origen.ventaMonto * this.tipoEuro.valor;
                this.detalleTotales.ventaOrigenEur = this.detalleTotales.ventaOrigenEur + origen.ventaMonto;
                break;
              case 3:
                ventaDolares = origen.ventaMonto * this.tipoLibra.valor;
                this.detalleTotales.ventaOrigenGbp = this.detalleTotales.ventaOrigenGbp + origen.ventaMonto;
                break;
              case 4:
                ventaDolares = origen.ventaMonto * this.tipoYen.valor;
                this.detalleTotales.ventaOrigenJpy = this.detalleTotales.ventaOrigenJpy + origen.ventaMonto;
                break;
              case 5:
                ventaDolares = origen.ventaMonto / this.tipoDolar.valor;
                this.detalleTotales.ventaOrigenMxn = this.detalleTotales.ventaOrigenMxn + origen.ventaMonto;
                break;
            }
            this.detalleTotales.ventaTotalOrigenUsd = this.detalleTotales.ventaTotalOrigenUsd + ventaDolares;
          }
          origen.profit = ventaDolares - costoDolares;
        }
      );
    }
    if (this.servicio.cargosDestino) {
      this.servicio.cargosDestino.map(
        destino => {
          let costoDolares: number = 0.00;
          let ventaDolares: number = 0.00;

          if (destino.compraTarifa && destino.compraTarifa > 0 && this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
            destino.compraMonto = destino.compraTarifa * this.servicio.pesoCargable;
          }

          if (destino.ventaTarifa && destino.ventaTarifa > 0 && this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
            destino.ventaMonto = destino.ventaTarifa * this.servicio.pesoCargable;
          }

          if (destino.monedaCosto) {
            switch (destino.monedaCosto.idMoneda) {
              case 1:
                costoDolares = destino.compraMonto;
                this.detalleTotales.costoDestinoUsd = this.detalleTotales.costoDestinoUsd + destino.compraMonto;
                break;
              case 2:
                costoDolares = destino.compraMonto * this.tipoEuro.valor;
                this.detalleTotales.costoDestinoEur = this.detalleTotales.costoDestinoEur + destino.compraMonto;
                break;
              case 3:
                costoDolares = destino.compraMonto * this.tipoLibra.valor;
                this.detalleTotales.costoDestinoGbp = this.detalleTotales.costoDestinoGbp + destino.compraMonto;
                break;
              case 4:
                costoDolares = destino.compraMonto * this.tipoYen.valor;
                this.detalleTotales.costoDestinoJpy = this.detalleTotales.costoDestinoJpy + destino.compraMonto;
                break;
              case 5:
                costoDolares = destino.compraMonto / this.tipoDolar.valor;
                this.detalleTotales.costoDestinoMxn = this.detalleTotales.costoDestinoMxn + destino.compraMonto;
                break;
            }
            this.detalleTotales.costoTotalDestinoUsd = this.detalleTotales.costoTotalDestinoUsd + costoDolares;
          }

          if (destino.monedaVenta) {
            switch (destino.monedaVenta.idMoneda) {
              case 1:
                ventaDolares = destino.ventaMonto;
                this.detalleTotales.ventaDestinoUsd = this.detalleTotales.ventaDestinoUsd + destino.ventaMonto;
                break;
              case 2:
                ventaDolares = destino.ventaMonto * this.tipoEuro.valor;
                this.detalleTotales.ventaDestinoEur = this.detalleTotales.ventaDestinoEur + destino.ventaMonto;
                break;
              case 3:
                ventaDolares = destino.ventaMonto * this.tipoLibra.valor;
                this.detalleTotales.ventaDestinoGbp = this.detalleTotales.ventaDestinoGbp + destino.ventaMonto;
                break;
              case 4:
                ventaDolares = destino.ventaMonto * this.tipoYen.valor;
                this.detalleTotales.ventaDestinoJpy = this.detalleTotales.ventaDestinoJpy + destino.ventaMonto;
                break;
              case 5:
                ventaDolares = destino.ventaMonto / this.tipoDolar.valor;
                this.detalleTotales.ventaDestinoMxn = this.detalleTotales.ventaDestinoMxn + destino.ventaMonto;
                break;
            }
            this.detalleTotales.ventaTotalDestinoUsd = this.detalleTotales.ventaTotalDestinoUsd + ventaDolares;
          }
          destino.profit = ventaDolares - costoDolares;
        }
      );
    }
    this.servicio.totalprofitCargosOrigen = this.detalleTotales.ventaTotalOrigenUsd - this.detalleTotales.costoTotalOrigenUsd;
    this.servicio.totalprofitCargosDestino = this.detalleTotales.ventaTotalDestinoUsd - this.detalleTotales.costoTotalDestinoUsd;
    this.servicio.totalprofit = this.servicio.totalprofitCargosOrigen + this.servicio.totalprofitCargosDestino;
  }

  crear(borrador: boolean): void {
    this.spinnerService.show();
    const servicioGuardar: ServicioInter = new ServicioInter();

    if (this.servicio.idServicio) {
      servicioGuardar.idServicio = this.servicio.idServicio;
    }

    if (this.servicio.idServicioStr != '') {
      servicioGuardar.idServicioStr = this.servicio.idServicioStr;
    }

    servicioGuardar.estatus = this.servicio.estatus;
    servicioGuardar.anio = this.servicio.anio;
    servicioGuardar.trafico = this.servicio.trafico;
    servicioGuardar.nombreTrafico = this.servicio.nombreTrafico;
    servicioGuardar.fechaServicio = this.servicio.fechaServicio;
    servicioGuardar.totalprofitCargosOrigen = this.servicio.totalprofitCargosOrigen;
    servicioGuardar.totalprofitCargosDestino = this.servicio.totalprofitCargosDestino;
    servicioGuardar.totalprofit = this.servicio.totalprofit;
    servicioGuardar.subtipo = this.authService.usuario.rol.nombre == 'ROLE_TRAFICO_AEREO_IMPO' ? 'Impo' : 'Expo';
    servicioGuardar.idChat = this.servicio.idChat;
    servicioGuardar.consecutivo = this.servicio.consecutivo;
    servicioGuardar.ordenCompra = this.servicio.ordenCompra;

    if (this.tipo) {
      this.tipoRequerido = false;
      servicioGuardar.tipo = this.tipo == 1 ? "Aereo" : "Marítimo";
      if (this.tipo == 2) {
        servicioGuardar.lcl = this.servicio.lcl;
        servicioGuardar.contenedor = this.servicio.contenedor;
      }
    } else {
      this.tipoRequerido = true;
      swal('Tipo', 'Se debe seleccionar el Tipo', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.cliente) {
      this.clienteRequerido = false;
      servicioGuardar.cliente = this.servicio.cliente;
    } else if (!borrador) {
      this.clienteRequerido = true;
      swal('Cliente', 'Se debe seleccionar el Cliente', 'warning');
      this.spinnerService.hide();
      return;
    }

    if(this.pagadorAlt && this.servicio.pagador) {
      this.pagadorRequerido = false;
      servicioGuardar.pagador = this.servicio.pagador;
    } if(this.pagadorAlt && !this.servicio.pagador) {
      this.pagadorRequerido = true;
      swal('Pagador', 'Se debe seleccionar el Pagador', 'warning');
      this.spinnerService.hide();
      return;
    }

    //if (this.servicio.refFacturacion) {
      this.referenciaFacturacionRequerida = false;
      servicioGuardar.refFacturacion = this.servicio.refFacturacion;
    /*} else if (!borrador) {
      this.referenciaFacturacionRequerida = true;
      swal('Referencia', 'Se debe capturar la referencia', 'warning');
      this.spinnerService.hide();
      return;
    }*/

    if (this.servicio.mpc && this.servicio.mpc.idMpc == 2) {
      this.mpcRequerido = false;
      servicioGuardar.mpc = this.servicio.mpc;
    }
    /* else if (this.servicio.mpc && this.servicio.mpc.idMpc == 2) {
      this.mpcRequerido = true;
      swal('MPC', 'Se debe seleccionar "Final" en la MPC', 'warning');
      this.spinnerService.hide();
      return;
    } */
  /*  else  {
      this.mpcRequerido = true;
      swal('MPC', 'Se debe seleccionar la MPC', 'warning');
      this.spinnerService.hide();
      return;
    }*/

    if (this.servicio.shipper) {
      this.shipperRequerido = false;
      servicioGuardar.shipper = this.servicio.shipper;
    } else if (!borrador) {
      this.shipperRequerido = true;
      swal('Shipper', 'Se debe seleccionar el Shipper', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.consignatario) {
      this.consignatarioRequerido = false;
      servicioGuardar.consignatario = this.servicio.consignatario;
    } else if (!borrador) {
      this.consignatarioRequerido = true;
      swal('Consignatario', 'Se debe seleccionar el Consignatario', 'warning');
      this.spinnerService.hide();
      return;
    }

    servicioGuardar.notasCompletas = this.servicio.notasCompletas;
    servicioGuardar.notas = this.servicio.notas;
/*
    if (this.servicio.notasCompletas || (!this.servicio.notasCompletas && this.servicio.notas && this.servicio.notas.length > 0)) {
      this.notasRequeridas = false;
      servicioGuardar.notasCompletas = this.servicio.notasCompletas;
      servicioGuardar.notas = this.servicio.notas;
    } else if (!borrador) {
      this.notasRequeridas = true;
      swal('Completo', 'Se deben capturar notas sino es completo', 'warning');
      this.spinnerService.hide();
      return;
    }
*/
    if (this.servicio.aeropuertoOrigen) {
      this.aeropuertoOrigenRequerido = false;
      servicioGuardar.aeropuertoOrigen = this.servicio.aeropuertoOrigen;
    } else if (!borrador) {
      this.aeropuertoOrigenRequerido = true;
      swal('Aeropuerto Origen', 'Se debe seleccionar el Aeropuerto Origen', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.aeropuertoDestino) {
      this.aeropuertoDestinoRequerido = false;
      servicioGuardar.aeropuertoDestino = this.servicio.aeropuertoDestino;
    } else if (!borrador) {
      this.aeropuertoDestinoRequerido = true;
      swal('Aeropuerto Destino', 'Se debe seleccionar el Aeropuerto Destino', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.incotermOrigen) {
      this.incotermOrigenRequerido = false;
      servicioGuardar.incotermOrigen = this.servicio.incotermOrigen;
    } else if (!borrador) {
      this.incotermOrigenRequerido = true;
      swal('Incoterm Origen', 'Se debe seleccionar el Incoterm Origen', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.incotermDestino) {
      this.incotermDestinoRequerido = false;
      servicioGuardar.incotermDestino = this.servicio.incotermDestino;
    } else if (!borrador) {
      this.incotermDestinoRequerido = true;
      swal('Incoterm Destino', 'Se debe seleccionar el Incoterm Destino', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.ghPrefijoAgente) {
      servicioGuardar.ghPrefijoAgente = this.servicio.ghPrefijoAgente;
    }

    if (this.servicio.ghNumGuia) {
      servicioGuardar.ghNumGuia = this.servicio.ghNumGuia;
    }

    if (this.servicio.gmPrefijoAerolinea) {
      servicioGuardar.gmPrefijoAerolinea = this.servicio.gmPrefijoAerolinea;
    }

    if (this.servicio.gmNumGuia) {
      servicioGuardar.gmNumGuia = this.servicio.gmNumGuia;
    }

    if (this.fechaSalida) {
      this.fechaSalidaRequerida = false;
      servicioGuardar.sFechaSalida = this.fechaSalida.year + '/' +
        (this.fechaSalida.month < 10 ? '0' + this.fechaSalida.month : this.fechaSalida.month) + '/' +
        this.fechaSalida.day;
    } else if (!borrador) {
      this.fechaSalidaRequerida = true;
      swal('Fecha ETD', 'Se debe capturar la Fecha ETD', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.fechaDocumentacion) {
      servicioGuardar.sFechaDocumentacion = this.fechaDocumentacion.year + '/' +
        (this.fechaDocumentacion.month < 10 ? '0' + this.fechaDocumentacion.month : this.fechaDocumentacion.month) + '/' +
        this.fechaDocumentacion.day;
    }

    if (this.fechaArribo1) {
      servicioGuardar.sFechaArribo1 = this.fechaArribo1.year + '/' +
        (this.fechaArribo1.month < 10 ? '0' + this.fechaArribo1.month : this.fechaArribo1.month) + '/' +
        this.fechaArribo1.day;
    }

    if (this.fechaArribo2) {
      servicioGuardar.sFechaArribo2 = this.fechaArribo2.year + '/' +
        (this.fechaArribo2.month < 10 ? '0' + this.fechaArribo2.month : this.fechaArribo2.month) + '/' +
        this.fechaArribo2.day;
    }

    if (this.fechaArribo3) {
      this.fechaArribo3Requerida = false;
      servicioGuardar.sFechaArribo3 = this.fechaArribo3.year + '/' +
        (this.fechaArribo3.month < 10 ? '0' + this.fechaArribo3.month : this.fechaArribo3.month) + '/' +
        this.fechaArribo3.day;
    } else if (!borrador) {
      this.fechaArribo3Requerida = true;
      swal('Fecha ETA', 'Se debe capturar la Fecha ETA', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.numPiezas && this.servicio.numPiezas > 0) {
      this.numeroPiezasRequerido = false;
      servicioGuardar.numPiezas = this.servicio.numPiezas;
    } else if (!borrador) {
      this.numeroPiezasRequerido = true;
      swal('Número Piezas', 'El número de piezas debe ser mayor a 0', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.pesoBruto && this.servicio.pesoBruto > 0) {
      this.pesoBrutoRequerido = false;
      servicioGuardar.pesoBruto = this.servicio.pesoBruto;
    } else if (!borrador) {
      this.pesoBrutoRequerido = true;
      swal('Peso Bruto', 'El peso bruto debe ser mayor a 0', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.pesoCargable && this.servicio.pesoCargable > 0) {
      this.pesoCargableRequerido = false;
      servicioGuardar.pesoCargable = this.servicio.pesoCargable;
    } else if (!borrador) {
      this.pesoCargableRequerido = true;
      swal('Peso Bruto', 'El peso cargable debe ser mayor a 0', 'warning');
      this.spinnerService.hide();
      return;
    }

    if (this.servicio.cargosOrigen && this.servicio.cargosOrigen.length > 0) {
      const origenes: CargoOrigen[] = [];
      let origenIncorrecto: boolean = false;

      this.servicio.cargosOrigen.map(
        origen => {
          if (!origenIncorrecto) {
            const origenGuardar: CargoOrigen = new CargoOrigen();

            if (origen.cargoAereo) {
              origen.cargoAereoRequerido = false;
              origenGuardar.cargoAereo = origen.cargoAereo;
            } else {
              origen.cargoAereoRequerido = true;
            }

            if (origen.proveedor) {
              origen.proveedorRequerido = false;
              origenGuardar.proveedor = origen.proveedor;
            } else {
              origen.proveedorRequerido = true;
            }

            origenGuardar.compraTarifa = origen.compraTarifa;
            origenGuardar.compraMonto = origen.compraMonto;

            if (origen.monedaCosto) {
              origen.monedaCostoRequerida = false;
              origenGuardar.monedaCosto = origen.monedaCosto;
            } else {
              origen.monedaCostoRequerida = true;
            }

            origenGuardar.ventaTarifa = origen.ventaTarifa;
            origenGuardar.ventaMonto = origen.ventaMonto;

            if (origen.monedaVenta) {
              origen.monedaVentaRequerida = false;
              origenGuardar.monedaVenta = origen.monedaVenta;
            } else {
              origen.monedaVentaRequerida = true;
            }

            if (origen.cargoAereoRequerido || origen.proveedorRequerido ||
              origen.monedaCostoRequerida || origen.monedaVentaRequerida) {
              if (!borrador) {
                origenIncorrecto = true;
                this.spinnerService.hide();
                return;
              }
            }

            origenGuardar.itemID = origen.itemID;
            origenGuardar.pedidoCliente = origen.pedidoCliente;
            origenIncorrecto = false;
            origenes.push(origenGuardar);
          }
        }
      );

      if (!borrador && origenIncorrecto) {
        swal('Cargo Origen Incorrecto', 'Se debe llenar la información completa del cargo origen', 'warning');
        this.spinnerService.hide();
        return;
      }

      servicioGuardar.cargosOrigen = origenes;
    }

    if (this.servicio.cargosDestino && this.servicio.cargosDestino.length > 0) {
      const destinos: CargoDestino[] = [];
      let destinoIncorrecto: boolean = false;

      this.servicio.cargosDestino.map(
        destino => {
          if (!destinoIncorrecto) {
            const destinoGuardar: CargoDestino = new CargoDestino();

            if (destino.cargoAereo) {
              destino.cargoAereoRequerido = false;
              destinoGuardar.cargoAereo = destino.cargoAereo;
            } else {
              destino.cargoAereoRequerido = true;
            }

            if (destino.proveedor) {
              destino.proveedorRequerido = false;
              destinoGuardar.proveedor = destino.proveedor;
            } else {
              destino.proveedorRequerido = true;
            }

            destinoGuardar.compraTarifa = destino.compraTarifa;
            destinoGuardar.compraMonto = destino.compraMonto;

            if (destino.monedaCosto) {
              destino.monedaCostoRequerida = false;
              destinoGuardar.monedaCosto = destino.monedaCosto;
            } else {
              destino.monedaCostoRequerida = true;
            }

            destinoGuardar.ventaTarifa = destino.ventaTarifa;
            destinoGuardar.ventaMonto = destino.ventaMonto;

            if (destino.monedaVenta) {
              destino.monedaVentaRequerida = false;
              destinoGuardar.monedaVenta = destino.monedaVenta;
            } else {
              destino.monedaVentaRequerida = true;
            }

            if (destino.cargoAereoRequerido || destino.proveedorRequerido ||
              destino.monedaCostoRequerida || destino.monedaVentaRequerida) {
              if (!borrador) {
                destinoIncorrecto = true;
                this.spinnerService.hide();
                return;
              }
            }

            destinoGuardar.itemID = destino.itemID;
            destinoGuardar.pedidoCliente = destino.pedidoCliente;
            destinoIncorrecto = false;
            destinos.push(destinoGuardar);
          }
        }
      );

      if (!borrador && destinoIncorrecto) {
        swal('Cargo Destino Incorrecto', 'Se debe llenar la información completa del cargo destino', 'warning');
        this.spinnerService.hide();
        return;
      }

      servicioGuardar.cargosDestino = destinos;
    }

    servicioGuardar.borrador = borrador;

    if (this.servicio.idServicioStr && this.servicio.idServicioStr.indexOf('Borrador') == -1) {
      if (this.servicio.idChat == null) {
        this.graphService.createChat(servicioGuardar.idServicioStr, this.members).subscribe(
          chat => {
            this.servicio.idChat = chat.id;
            servicioGuardar.idChat = chat.id;
            this.serviciosInterService.updateServicio(servicioGuardar).subscribe(
              servicio => {
                this.servicio.idServicio = servicio.idServicio;
                this.servicio.idServicioStr = servicio.idServicioStr;
                this.servicio.consecutivo = servicio.consecutivo;
                this.servicio.ordenCompra = servicio.ordenCompra;
                this.spinnerService.hide();
                swal({
                  title: 'Servicio actualizado',
                  text: 'Servicio ' + servicio.idServicioStr + ' actualizado con exito, Desea volver al Inicio?',
                  icon: 'success',
                  buttons: {
                    cancel: {
                      text: 'No',
                      value: false,
                      visible: true,
                      className: "bg-danger",
                      closeModal: true
                    },
                    confirm: {
                      text: 'Si',
                      value: true,
                      visible: true,
                      className: "bg-success",
                      closeModal: true
                    }
                  }
                }).then((isConfirm: boolean) => {
                  if (isConfirm) {
                    this.router.navigate(['/dashboard/']);
                  } else {
                    this.router.navigate(['/servicio/' + servicio.idServicio]);
                  }
                });
              },
              error => {
                console.log(error);
                this.spinnerService.hide();
                if (this.estatusRespaldo != null) {
                  this.servicio.estatus = this.estatusRespaldo;
                  this.estatusRespaldo = null;
                }
              }
            );
          }
        );
      } else {
        this.serviciosInterService.updateServicio(servicioGuardar).subscribe(
          servicio => {
            this.servicio.idServicio = servicio.idServicio;
            this.servicio.idServicioStr = servicio.idServicioStr;
            this.servicio.consecutivo = servicio.consecutivo;
            this.servicio.ordenCompra = servicio.ordenCompra;
            this.spinnerService.hide();
            swal({
              title: 'Servicio actualizado',
              text: 'Servicio ' + servicio.idServicioStr + ' actualizado con exito, Desea volver al Inicio?',
              icon: 'success',
              buttons: {
                cancel: {
                  text: 'No',
                  value: false,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
                },
                confirm: {
                  text: 'Si',
                  value: true,
                  visible: true,
                  className: "bg-success",
                  closeModal: true
                }
              }
            }).then((isConfirm: boolean) => {
              if (isConfirm) {
                this.router.navigate(['/dashboard/']);
              } else {
                this.router.navigate(['/servicio/' + servicio.idServicio]);
              }
            });
          },
          error => {
            console.log(error);
            this.spinnerService.hide();
            if (this.estatusRespaldo != null) {
              this.servicio.estatus = this.estatusRespaldo;
              this.estatusRespaldo = null;
            }
          }
        );
      }
    } else {
      console.log(servicioGuardar);
      this.serviciosInterService.createServicio(servicioGuardar).subscribe(
        servicio => {
          this.servicio.idServicio = servicio.idServicio;
          this.servicio.idServicioStr = servicio.idServicioStr;
          this.servicio.consecutivo = servicio.consecutivo;
          this.servicio.ordenCompra = servicio.ordenCompra;
          this.graphService.createChat(servicio.idServicioStr, this.members).subscribe(
            chat => {
              this.servicio.idChat = chat.id;
              servicioGuardar.idChat = chat.id;
              servicioGuardar.idServicio = servicio.idServicio;
              servicioGuardar.idServicioStr = servicio.idServicioStr;
              servicioGuardar.consecutivo = servicio.consecutivo;
              servicioGuardar.ordenCompra = servicio.ordenCompra;
              servicioGuardar.motivoCancelacion = 'IDCHAT';
              this.serviciosInterService.updateServicio(servicioGuardar).subscribe(
                servicio => {
                  this.spinnerService.hide();
                  swal({
                    title: 'Servicio creado',
                    text: 'Servicio ' + this.servicio.idServicioStr + ' creado con exito, Desea volver al Inicio?',
                    icon: 'success',
                    buttons: {
                      cancel: {
                        text: 'No',
                        value: false,
                        visible: true,
                        className: "bg-danger",
                        closeModal: true
                      },
                      confirm: {
                        text: 'Si',
                        value: true,
                        visible: true,
                        className: "bg-success",
                        closeModal: true
                      }
                    }
                  }).then((isConfirm: boolean) => {
                    if (isConfirm) {
                      this.router.navigate(['/dashboard/']);
                    } else {
                      this.router.navigate(['/servicio/' + servicio.idServicio]);
                    }
                  });
                },
                error => {
                  console.log(error);
                  this.spinnerService.hide();
                }
              );
            }
          );
        },
        error => {
          console.log(error);
          this.spinnerService.hide();
        }
      );
    }
  }

  verTotales(tipo: string) {
    this.tipoTotales = tipo;
    this.totalesService.abrirModal();
  }

  verMensajes() {
    this.idChatSeleccionado = this.servicio.idChat;
  }

  agregarShipper(): void {
    this.tipoCatalogo = "Shipper";
    this.catalogoService.abrirModal();
  }

  agregarConsignatario(): void {
    this.tipoCatalogo = "Consignatario";
    this.catalogoService.abrirModal();
  }

  agregarAeropuertoOri(): void {
    this.tipoCatalogo = "Aeropuerto Origen";
    this.catalogoService.abrirModal();
  }

  agregarAeropuertoDes(): void {
    this.tipoCatalogo = "Aeropuerto Destino";
    this.catalogoService.abrirModal();
  }

  agregarIncotermOri(): void {
    this.tipoCatalogo = "Incoterm Origen";
    this.catalogoService.abrirModal();
  }

  agregarIncotermDes(): void {
    this.tipoCatalogo = "Incoterm Destino";
    this.catalogoService.abrirModal();
  }

  cerrarServicio() {
    swal({
      title: 'Cerrar Servicio',
      text: 'Esta seguro que desea cerrar el servicio?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar.',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, confirmar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }).then((isConfirm: boolean) => {
      if (isConfirm) {

       if (this.servicio.mpc && this.servicio.mpc.idMpc == 2) {
          this.mpcRequerido = true;
          swal('MPC', 'Se debe seleccionar "Final" en la MPC', 'warning');
          this.spinnerService.hide();
          return;
        }
        if (this.servicio.notasCompletas || (!this.servicio.notasCompletas && this.servicio.notas && this.servicio.notas.length > 0)) {
          this.notasRequeridas = false;

        } else {
        this.notasRequeridas = true;
        swal('Completo', 'Se deben capturar notas para cerrar el servicio', 'warning');
        this.spinnerService.hide();
        return;
      }



        this.estatusRespaldo = this.servicio.estatus;
        const estatusServicio = new EstatusServicio();
        estatusServicio.idEstatusServicio = 3;//Cerrado
        estatusServicio.descripcion = 'Cerrado';
        this.servicio.estatus = estatusServicio;
        this.crear(false);
      }
    });
  }

  cancelarServicio() {
    swal({
      title: 'Cancelar Servicio',
      text: 'Esta seguro que desea cancelar el servicio?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'No, cancelar.',
          value: null,
          visible: true,
          className: "",
          closeModal: true
        },
        confirm: {
          text: 'Si, confirmar!',
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true
        }
      }
    }).then((isConfirm: boolean) => {
      if (isConfirm) {
        this.idServicioSeleccionado = this.servicio.idServicio;
        this.cancelarServicioInterService.abrirModal();
      }
    });
  }

  verBitacoraSap() {
    this.idServicioSeleccionado = this.servicio.idServicio;
    this.bitacoraInterSapService.abrirModal();
  }

}
