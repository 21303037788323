import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { EstatusServicio } from '../../model/estatus-servicio';
import { Trafico } from '../../model/trafico';
import { Cliente } from '../../model/cliente';
import { Proveedor } from '../../model/proveedor';
import { Servicio } from '../../model/servicio';
import { EstatusServicioService } from '../../services/estatus-servicio.service';
import { ClientesService } from '../../services/clientes.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ServiciosService } from '../../services/servicios.service';
import { ExportarService } from './exportar/exportar.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-consulta-servicios',
  templateUrl: './consulta-servicios.component.html',
  styleUrls: ['./consulta-servicios.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsultaServiciosComponent implements OnInit {

  numeroServicio: string;
  estatusServicio: EstatusServicio;
  listaEstatusServicio: EstatusServicio[];
  trafico: string;
  nombreTrafico: string;
  listaTraficos: Trafico[];
  listaClientes: Cliente[];
  listProveedores: Proveedor[];
  listaServicios: Servicio[];
  searching = false;
  _onDestroy = new Subject<void>();
  cliente: Cliente;
  clienteCtrl: FormControl = new FormControl();
  clienteFilterCtrl: FormControl = new FormControl();
  clientesFiltrados: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  proveedor: Proveedor;
  proveedorCtrl: FormControl = new FormControl();
  proveedorFilterCtrl: FormControl = new FormControl();
  proveedoresFiltrados: ReplaySubject<Proveedor[]> = new ReplaySubject<Proveedor[]>(1);
  numeroFactura: string;
  fechaDesde: NgbDateStruct;
  fechaHasta: NgbDateStruct;
  borradores: boolean = false;

  constructor(
    public pt: PagetitleService,
    public authService: AuthService,
    private estatusServicioService: EstatusServicioService,
    private clientesService: ClientesService,
    private proveedoresService: ProveedoresService,
    private serviciosService: ServiciosService,
    private router: Router,
    private exportarService: ExportarService,
    private spinnerService: NgxSpinnerService
  ) {
    pt.setTitle('Consulta Servicios Terrestres');
  }

  ngOnInit() {
    this.cargarEstatusServicios();
    this.cargarTraficos();
    this.filtrarClientes();
    this.filtrarProveedores();
    this,this.authService.hasRole
  }

  private cargarEstatusServicios(): void {
    this.estatusServicioService.listEstatusServicio().subscribe(
      response => {
        this.listaEstatusServicio = response;
      }
    )
  }

  private cargarTraficos(): void {
    if (this.authService.hasRole('ROLE_SUPERADMIN') || this.authService.hasRole('ROLE_CUENTAS_PAGAR') || this.authService.hasRole('ROLE_ANALISTA')
       || this.authService.hasRole('ROLE_CUENTAS_COBRAR')|| this.authService.hasRole('ROLE_ADMIN_PROVEEDORES')) {
      this.serviciosService.listTraficos().subscribe(
        response => {
          this.listaTraficos = response;
        }
      );
    } else {
      this.trafico = this.authService.account.localAccountId;
      this.nombreTrafico = this.authService.account.name;
    }
  }

  private filtrarClientes(): void {
    this.clienteFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        search => {
          this.clientesService.listFirstClientesByBusqueda(search).subscribe(
            clientesFiltrados => {
              this.searching = false;
              if (this.clientesFiltrados == null) {
                this.clientesFiltrados = new ReplaySubject<Cliente[]>(1);
              }
              this.clientesFiltrados.next(clientesFiltrados);
            }
          );
        }
      );
  }

  private filtrarProveedores(): void {
    this.proveedorFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          return search;
        }),
        delay(100),
        takeUntil(this._onDestroy)
      )
      .subscribe(search => {
        this.proveedoresService.listFirstProveedoresByRazonComercial(search).subscribe(
          proveedoresFiltrados => {
            this.searching = false;
            if (this.proveedoresFiltrados == null) {
              this.proveedoresFiltrados = new ReplaySubject<Proveedor[]>(1);
            }
            this.proveedoresFiltrados.next(proveedoresFiltrados);
          }
        );
      },
        error => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
  }

  limpiar(): void {
    this.numeroServicio = undefined;
    this.estatusServicio = undefined;
    this.trafico = undefined;
    this.cliente = undefined;
    this.proveedor = undefined;
    this.numeroFactura = undefined;
    this.fechaDesde = undefined;
    this.fechaHasta = undefined;
    this.borradores = undefined;
    this.cargarTraficos();
  }

  buscar(): void {
    //console.log("BUSCAR---");
    let numeroServicio = null;
    let idEstatus = 0;
    let idTrafico = null;
    let idCliente = 0;
    let idProveedor = 0;
    let numeroFactura = null;
    let fechaDesde = null;
    let fechaHasta = null;
    let borradores = null;

    if (this.numeroServicio) {
      numeroServicio = this.numeroServicio;
    }
    if (this.estatusServicio) {
      idEstatus = this.estatusServicio.idEstatusServicio;
    }
    if (this.trafico) {
      idTrafico = this.trafico;
    }
    if (this.cliente) {
      idCliente = this.cliente.idCliente;
    }
    if (this.proveedor) {
      idProveedor = this.proveedor.idProveedor;
    }
    if (this.numeroFactura) {
      numeroFactura = this.numeroFactura;
    }
    if (this.fechaDesde) {
      fechaDesde = this.fechaDesde.year + '/' +
        (this.fechaDesde.month < 10 ? '0' + this.fechaDesde.month : this.fechaDesde.month) + '/' +
        (this.fechaDesde.day < 10 ? '0' + this.fechaDesde.day : this.fechaDesde.day);
    }
    if (this.fechaHasta) {
      fechaHasta = this.fechaHasta.year + '/' +
        (this.fechaHasta.month < 10 ? '0' + this.fechaHasta.month : this.fechaHasta.month) + '/' +
        (this.fechaHasta.day < 10 ? '0' + this.fechaHasta.day : this.fechaHasta.day);
    }
    if (this.borradores) {
      borradores = 'true';
    } else {
      borradores = 'false';
    }
    //console.log("1");
    this.spinnerService.show();
    this.serviciosService.searchServicio(numeroServicio, idEstatus, idTrafico, idCliente,
      idProveedor, numeroFactura, fechaDesde, fechaHasta, borradores,this.authService.rolName).subscribe(
        response => {
          this.listaServicios = response;
          this.spinnerService.hide();
          //console.log("2");
          }
      );

  }

  detalles(servicio: Servicio) {
    this.router.navigate(['/servicio/' + servicio.idServicio]);
  }

  exportar(): void {
    this.exportarService.abrirModal();
  }

  exportarAdmin(): void {
    let numeroServicio = null;
    let idEstatus = 0;
    let idTrafico = null;
    let idCliente = 0;
    let idProveedor = 0;
    let numeroFactura = null;
    let fechaDesde = null;
    let fechaHasta = null;
    let borradores = null;

    if (this.numeroServicio) {
      numeroServicio = this.numeroServicio;
    }
    if (this.estatusServicio) {
      idEstatus = this.estatusServicio.idEstatusServicio;
    }
    if (this.trafico) {
      idTrafico = this.trafico;
    }
    if (this.cliente) {
      idCliente = this.cliente.idCliente;
    }
    if (this.proveedor) {
      idProveedor = this.proveedor.idProveedor;
    }
    if (this.numeroFactura) {
      numeroFactura = this.numeroFactura;
    }
    if (this.fechaDesde) {
      fechaDesde = this.fechaDesde.year + '/' +
        (this.fechaDesde.month < 10 ? '0' + this.fechaDesde.month : this.fechaDesde.month) + '/' +
        (this.fechaDesde.day < 10 ? '0' + this.fechaDesde.day : this.fechaDesde.day);
    }
    if (this.fechaHasta) {
      fechaHasta = this.fechaHasta.year + '/' +
        (this.fechaHasta.month < 10 ? '0' + this.fechaHasta.month : this.fechaHasta.month) + '/' +
        (this.fechaHasta.day < 10 ? '0' + this.fechaHasta.day : this.fechaHasta.day);
    }
    if (this.borradores) {
      borradores = 'true';
    } else {
      borradores = 'false';
    }

    this.serviciosService.searchServicioReporte(numeroServicio, idEstatus, idTrafico, idCliente,
      idProveedor, numeroFactura, fechaDesde, fechaHasta, borradores,this.authService.rolName)
      .subscribe(
        blob => saveAs(blob,"ReporteServiciosAdmin.xls")

      );

  }

}
