import { Component, OnInit, Input } from '@angular/core';
import { BitacoraInterSapService } from './bitacora-inter-sap.service';
import { BitacoraSap } from '../../../model/bitacora-sap';
import { BitacorasSapService } from '../../../services/bitacoras-sap.service';

@Component({
  selector: 'bitacora-inter-sap',
  templateUrl: './bitacora-inter-sap.component.html',
  styleUrls: ['./bitacora-inter-sap.component.scss']
})
export class BitacoraInterSapComponent implements OnInit {

  @Input()
  idServicio: number;
  bitacoras: BitacoraSap[];

  constructor(
    private bitacoraInterSapService: BitacoraInterSapService,
    private bitacorasSapService: BitacorasSapService
  ) { }

  ngOnInit() {
    this.cargaBitacora();
  }

  cancelar(): void {
    this.bitacoraInterSapService.cerraModal();
  }

  cargaBitacora(): void {
    this.bitacorasSapService.getBitacoraSapByIdServicio(this.idServicio).subscribe(
      response => {
        this.bitacoras = response;
      }
    )
  }

}
