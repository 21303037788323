import { Injectable } from '@angular/core';
import { URL_BACKEND } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ServicioInter } from '../model/servicio-inter';
import { map, catchError } from 'rxjs/operators';
import { CargoOrigen } from '../model/cargo-origen';
import { CargoDestino } from '../model/cargo-destino';
import { Trafico } from '../model/trafico';

const swal = require('sweetalert');

@Injectable({
  providedIn: 'root'
})
export class ServiciosInterService {

  private urlEndPoint: string = URL_BACKEND + "/servicioInter";

  constructor(
    private http: HttpClient
  ) { }

  listServicios(): Observable<ServicioInter[]> {
    return this.http.get<ServicioInter[]>(this.urlEndPoint + '/list');
  }

  pageServicios(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page);
  }

  getServicio(idServicio: number): Observable<ServicioInter> {
    return this.http.get<ServicioInter>(this.urlEndPoint + '/' + idServicio).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  searchServicio(numeroServicio: string, idTrafico: string, guiaHouse: string, guiaMaster: string, idConsignatario: number,
    idAgente: number, fechaDesde: string, fechaHasta: string, rol: string, reporteImpo: boolean): Observable<ServicioInter[]> {
    console.log("service reporteImpo "+reporteImpo);
    let filtros = '';
    if (numeroServicio && numeroServicio != null) {
      numeroServicio = numeroServicio.replace('&', '(amp)');
      filtros += 'numeroServicio=' + numeroServicio + '&';
    }
    if (idTrafico && idTrafico != null) {
      filtros += 'idTrafico=' + idTrafico + '&';
    }
    if (guiaHouse && guiaHouse != null) {
      filtros += 'guiaHouse=' + guiaHouse + '&';
    }
    if (guiaMaster && guiaMaster != null) {
      filtros += 'guiaMaster=' + guiaMaster + '&';
    }
    if (idConsignatario > 0) {
      filtros += 'idConsignatario=' + idConsignatario + '&';
    }
    if (idAgente > 0) {
      filtros += 'idAgente=' + idAgente + '&';
    }
    if (fechaDesde && fechaDesde != null) {
      filtros += 'fechaDesde=' + fechaDesde + '&';
    }
    if (fechaHasta && fechaHasta != null) {
      filtros += '&fechaHasta=' + fechaHasta + '&';
    }
    if (rol && rol != null) {
      filtros += '&rol=' + rol + '&';
    }
    //if (reporteImpo) {
      filtros += 'reporteImpo=' + reporteImpo + '&';
    //}
    if (filtros.endsWith('&')) {
      filtros = filtros.substring(0, filtros.length - 1);
    }

    return this.http.get<ServicioInter[]>(this.urlEndPoint + '/search?' + filtros).pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  searchServicioReporte(numeroServicio: string, idTrafico: string, guiaHouse: string, guiaMaster: string, idConsignatario: number,
    idAgente: number, fechaDesde: string, fechaHasta: string, rol: string, reporteImpo: boolean): Observable<Blob> {
    let filtros = '';
    if (numeroServicio && numeroServicio != null) {
      filtros += 'numeroServicio=' + numeroServicio + '&';
    }
    if (idTrafico && idTrafico != null) {
      filtros += 'idTrafico=' + idTrafico + '&';
    }
    if (guiaHouse && guiaHouse != null) {
      filtros += 'guiaHouse=' + guiaHouse + '&';
    }
    if (guiaMaster && guiaMaster != null) {
      filtros += 'guiaMaster=' + guiaMaster + '&';
    }
    if (idConsignatario > 0) {
      filtros += 'idConsignatario=' + idConsignatario + '&';
    }
    if (idAgente > 0) {
      filtros += 'idAgente=' + idAgente + '&';
    }
    if (fechaDesde && fechaDesde != null) {
      filtros += 'fechaDesde=' + fechaDesde + '&';
    }
    if (fechaHasta && fechaHasta != null) {
      filtros += '&fechaHasta=' + fechaHasta + '&';
    }
    if (rol && rol != null) {
      filtros += '&rol=' + rol + '&';
    }
    //if (reporteImpo) {
      filtros += 'reporteImpo=' + reporteImpo + '&';
    //}
    if (filtros.endsWith('&')) {
      filtros = filtros.substring(0, filtros.length - 1);
    }

    return this.http.get(this.urlEndPoint + '/searchReportInter?' + filtros, {
      responseType: 'blob'
    });
  }

  createServicio(servicio: ServicioInter): Observable<ServicioInter> {
    return this.http.post(this.urlEndPoint, servicio).pipe(
      map(
        (response: any) => response.servicio as ServicioInter
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateServicio(servicio: ServicioInter): Observable<any> {
    return this.http.put<any>(this.urlEndPoint, servicio).pipe(
      map(
        (response: any) => response.servicio as ServicioInter
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  cancelarServicio(idServicio: number, motivo: string): Observable<any> {
    return this.http.delete<any>(this.urlEndPoint + '/cancel/' + idServicio + '/' + motivo).pipe(
      map(
        (response: any) => response.servicio as ServicioInter
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateOrigen(idServicio: number, origen: CargoOrigen): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/origen/update/' + idServicio, origen).pipe(
      map(
        (response: any) => response.cargo as CargoOrigen
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  deleteOrigen(idServicio: number, origen: CargoOrigen): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/origen/delete/' + idServicio, origen).pipe(
      map(
        (response: any) => response.cargo as CargoOrigen
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateDestino(idServicio: number, destino: CargoDestino): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/destino/update/' + idServicio, destino).pipe(
      map(
        (response: any) => response.cargo as CargoDestino
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  deleteDestino(idServicio: number, destino: CargoDestino): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + '/destino/delete/' + idServicio, destino).pipe(
      map(
        (response: any) => response.cargo as CargoDestino
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  listTraficos(): Observable<Trafico[]> {
    return this.http.get<Trafico[]>(this.urlEndPoint + '/traficos').pipe(
      catchError(e => {
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

  updateTrafico(idServicio: number, idTrafico: string, nombreTrafico: string): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + '/trafico/' + idServicio + '/' + idTrafico + '/' + nombreTrafico).pipe(
      map(
        (response: any) => response.servicio as ServicioInter
      ),
      catchError(e => {
        console.log(e);
        swal(e.error.mensaje, e.error.error, 'error');
        return throwError(e);
      })
    );
  }

}
