import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Proveedor } from '../../../model/proveedor';
import { ProveedoresService } from '../../../services/proveedores.service';
import { EvaluacionProveedorService } from '../../evaluacion-proveedor/evaluacion-proveedor.service';
import { DetalleProveedorService } from './detalle-proveedor/detalle-proveedor.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProveedoresComponent implements OnInit {
  public listaProveedores: Proveedor[];
  public proveedoresTemp: Proveedor[];
  public proveedorSeleccionado: Proveedor;
  public idEvaluacionSeleccionada: number;
  public idProveedorSeleccionado: number;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private proveedoresService: ProveedoresService,
    private detalleService: DetalleProveedorService,
    private evaluacionProveedorService: EvaluacionProveedorService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Proveedores');
  }

  ngOnInit() {
    this.proveedorSeleccionado = null;
    this.idEvaluacionSeleccionada = null;
    this.idProveedorSeleccionado = null;
    this.cargaProveedores();

    this.evaluacionProveedorService.saveEmitter.subscribe(
      () => {
        this.cargaProveedores();
      }
    );
  }

  private cargaProveedores(): void {
    this.proveedoresService.listProveedores().subscribe(
      response => {
        this.listaProveedores = response;
        this.proveedoresTemp = response;
      }
    );
  }

  abrirEvaluacion(proveedor: Proveedor): void {
    this.idEvaluacionSeleccionada = proveedor.idEvaluacion;
    this.idProveedorSeleccionado = proveedor.idProveedor;
    this.evaluacionProveedorService.abrirModal();
  }

  editar(proveedor: Proveedor): void {
    this.proveedorSeleccionado = proveedor;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.proveedoresTemp.filter(function(d) {
          return d.motivoBloqueo.toLowerCase().indexOf(val) !== -1 || d.razonComercial.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaProveedores = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
