export class ComprobanteRequest {
  Sucursal: string;
  Subtotal: string;
  Moneda: string;
  Total: string;
  TipoDeComprobante: string;
  LugarExpedicion: string;
  MetodoDePago: string;
  CondicionesDePago: string;
  FormaDePago: string;
  TotalEnLetra: string;
}
