import { Component, OnInit, Input } from '@angular/core';
import { Consignatario } from '../../../../model/consignatario';
import { DetalleConsignatarioService } from './detalle-consignatario.service';
import { ConsignatariosService } from '../../../../services/consignatarios.service';

const swal = require('sweetalert');

@Component({
  selector: 'detalle-consignatario',
  templateUrl: './detalle-consignatario.component.html',
  styleUrls: ['./detalle-consignatario.component.scss']
})
export class DetalleConsignatarioComponent implements OnInit {

  @Input()
  consignatario: Consignatario;

  constructor(
    public detalleService: DetalleConsignatarioService,
    private consignatariosService: ConsignatariosService
  ) { }

  ngOnInit() {
  }

  cancel(): void {
    this.detalleService.cerraModal();
  }

  crear(): void {
    this.consignatariosService.createConsignatario(this.consignatario).subscribe(
      consignatario => {
        this.detalleService.cerraModal();
        swal('Consignatario Creado', `Consignatario ${consignatario.nombre} creado con exito`, 'success');
      }
    );
  }

  actualizar(): void {
    this.consignatariosService.updateConsignatario(this.consignatario).subscribe(
      consignatario => {
        this.detalleService.cerraModal();
        swal('Consignatario Actualizado', `Consignatario ${consignatario.nombre} actualizado con exito`, 'success');
      }
    );
  }

}
