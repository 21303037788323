import { Component, OnInit, Input } from '@angular/core';
import { BitacoraSapService } from './bitacora-sap.service';
import { BitacoraSap } from '../../../model/bitacora-sap';
import { BitacorasSapService } from '../../../services/bitacoras-sap.service';

@Component({
  selector: 'bitacora-sap',
  templateUrl: './bitacora-sap.component.html',
  styleUrls: ['./bitacora-sap.component.scss']
})
export class BitacoraSapComponent implements OnInit {

  @Input()
  idServicio: number;
  bitacoras: BitacoraSap[];

  constructor(
    private bitacoraSapService: BitacoraSapService,
    private bitacorasSapService: BitacorasSapService
  ) { }

  ngOnInit() {
    this.cargaBitacora();
  }

  cancelar(): void {
    this.bitacoraSapService.cerraModal();
  }

  cargaBitacora(): void {
    this.bitacorasSapService.getBitacoraSapByIdServicio(this.idServicio).subscribe(
      response => {
        this.bitacoras = response;
      }
    )
  }

}
