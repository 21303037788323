import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Servicio } from '../../model/servicio';
import { Cliente } from '../../model/cliente';
import { Mpc } from '../../model/mpc';
import { TipoRuta } from '../../model/tipo-ruta';
import { Ubicacion } from '../../model/ubicacion';
import { Proveedor } from '../../model/proveedor';
import { ServicioPorCliente } from '../../model/servicio-cliente';
import { CargoPorServicio } from '../../model/cargo-servicio';
import { Moneda } from '../../model/moneda';
import { TipoCambio } from '../../model/tipo-cambio';
import { Cargo } from '../../model/cargo';
import { EstatusServicio } from '../../model/estatus-servicio';
import { CartaPorte } from '../../model/cartaporte/carta-porte';
import { Mercancias } from '../../model/cartaporte/mercancias';
import { Autotransporte } from '../../model/cartaporte/autotransporte';
import { IdentificacionVehicular } from '../../model/cartaporte/identificacion-vehicular';
import { TransporteMaritimo } from '../../model/cartaporte/transporte-maritimo';
import { TransporteAereo } from '../../model/cartaporte/transporte-aereo';
import { TransporteFerroviario } from '../../model/cartaporte/transporte-ferroviario';
import { FiguraTransporte } from '../../model/cartaporte/figura-transporte';
import { ClientesService } from '../../services/clientes.service';
import { MpcsService } from '../../services/mpcs.service';
import { TiposRutaService } from '../../services/tipos-ruta.service';
import { UbicacionesService } from '../../services/ubicaciones.service';
import { ProveedoresService } from '../../services/proveedores.service';
import { MonedasService } from '../../services/monedas.service';
import { TiposCambioService } from '../../services/tipos-cambio.service';
import { CargosService } from '../../services/cargos.service';
import { ServiciosService } from '../../services/servicios.service';
import { PagetitleService } from '../../core/pagetitle/pagetitle.service';
import { DetalleServicioService } from './detalle-servicio/detalle-servicio.service';
import { AuthService } from '../../auth/auth.service';
import { CartaPorteService } from '../carta-porte/carta-porte.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import {ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { TimbradoService } from '../../services/timbrado.service';
import { GraphService } from '../../services/graph.service';
import { BitacoraSapService } from './bitacora-sap/bitacora-sap.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CancelarServicioService } from './cancelar-servicio/cancelar-servicio.service';
import { Router } from '@angular/router';


const swal = require('sweetalert');

@Component({
	selector: 'app-servicios',
	templateUrl: './servicios.component.html',
	styleUrls: ['./servicios.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ServiciosComponent implements OnInit {
	public model: any;
	servicio: Servicio;
	listaMpcs: Mpc[];
	listTiposRuta: TipoRuta[];
	listMonedas: Moneda[];
	listTiposCambio: TipoCambio[];
	tipoDolar: TipoCambio;
	tipoEuro: TipoCambio;
	tipoLibra: TipoCambio;
	tipoYen: TipoCambio;
	utilidadRutas: number;
	utilidadCargos: number;
	utilidadServicio: number;
	rutaSeleccionada: ServicioPorCliente;
	listCargos: Cargo[];
	posicionCargo: number;
	ordenRuta: number;
	clienteRequerido: boolean = false;
	mpcRequerido: boolean = false;
	fechaCarga: Date;
	fechaAgenteAduanal: Date;
	fechaCruce: Date;
	fechaDestino: Date;
	idServicioSeleccionado: number;
	idServicioStrSeleccionado: string;
	idChatSeleccionado: string;
	cartaPorteSeleccionado: CartaPorte;
	estatusRespaldo: EstatusServicio = null;
	members = [];
	uniqueMembers: string[] = [];
	notasRequeridas: boolean = false;
	pagadorAlt: boolean = false;
	pagadorRequerido: boolean = false;

	public searching = false;
	protected _onDestroy = new Subject < void > ();
	public clienteCtrl: FormControl = new FormControl();
	public clienteFilterCtrl: FormControl = new FormControl();
	public clientesFiltrados: ReplaySubject < Cliente[] > = new ReplaySubject < Cliente[] > (1);
	public pagadorCtrl: FormControl = new FormControl();
	public pagadorFilterCtrl: FormControl = new FormControl();
	public pagadoresFiltrados: ReplaySubject < Cliente[] > = new ReplaySubject < Cliente[] > (1);

	/*
	  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
	  		text$.pipe(
	  			debounceTime(200),
	  			distinctUntilChanged(),
	  			map((term) =>
	  				term.length < 2 ? [] : states.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
	  			),
	  		);
	*/
	constructor(
		public pt: PagetitleService,
		public authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private clientesService: ClientesService,
		private mpcsService: MpcsService,
		private tiposRutaService: TiposRutaService,
		private ubicacionesService: UbicacionesService,
		private proveedoresService: ProveedoresService,
		private monedasServices: MonedasService,
		private tiposCambioService: TiposCambioService,
		private detalleService: DetalleServicioService,
		private cargosService: CargosService,
		private serviciosService: ServiciosService,
		private cartaPorteService: CartaPorteService,
		private timbradoService: TimbradoService,
		private graphService: GraphService,
		private bitacoraSapService: BitacoraSapService,
		private spinnerService: NgxSpinnerService,
		public cancelarServicioService: CancelarServicioService,
		private router: Router
		
		//  private anioActual: number,
		//  private mesActual: number
	) {
		pt.setTitle('Servicios');
	}

	ngOnInit() {

		this.activatedRoute.paramMap.subscribe(
			params => {
				let anioActual = new Date().getFullYear();
				let mesActual = new Date().getMonth() + 1;
				let idServicioTC: number = +params.get('idServicio');
				//console.log('idServicioTC '+idServicioTC);

				if (idServicioTC == 0) {
					//console.log('1');
					anioActual = new Date().getFullYear();
					mesActual = new Date().getMonth() + 1;
					//console.log('BUSCA ANIO: ' + anioActual);
					//console.log('BUSCA   MES: ' + mesActual);
					this.tiposCambioService.getTiposCambioByAnioMes(anioActual, mesActual).subscribe(
						response => {
							this.listTiposCambio = response;
							this.listTiposCambio = this.listTiposCambio.filter(
								tipo => tipo.moneda.idMoneda == 1
							);
							if (this.listTiposCambio != null && this.listTiposCambio.length > 0) {
								this.seleccionaMonedas();
								this.cargarCatalogos();
								this.ordenRuta = 1;
								this.posicionCargo = 10;

								let idServicio: number = +params.get('idServicio');
								//console.log('idServicio: ' + idServicio);
								if (idServicio == 0) {
									this.servicio = new Servicio();
									this.servicio.idServicio = null;
									this.servicio.idServicioStr = '';
									this.servicio.ordenCompra = '';
									this.servicio.anio = anioActual;
									this.servicio.trafico = this.authService.account.localAccountId;
									this.servicio.nombreTrafico = this.authService.account.name;
									this.servicio.notasCompletas = false;
									this.servicio.costoServicioUsd = 0.00;
									this.servicio.ventaServicioUsd = 0.00;
									this.servicio.costoCargoUsd = 0.00;
									this.servicio.ventaCargoUsd = 0.00;
									this.utilidadRutas = 0.00;
									this.utilidadCargos = 0.00;
									this.utilidadServicio = 0.00;
									this.servicio.fechaServicio = new Date(); //.toLocaleDateString('es-MX', {timeZone: 'America/Mexico_City'});

									const estatusServicio = new EstatusServicio();
									estatusServicio.idEstatusServicio = 1; //En Transito
									estatusServicio.descripcion = 'En Tránsito';
									this.servicio.estatus = estatusServicio;
								} else {
									this.serviciosService.getServicio(idServicio).subscribe(
										servicio => {
											console.log(servicio);
											this.servicio = servicio;

											if (this.servicio.cliente) {
												this.filtrarClientes();
												this.clienteFilterCtrl.setValue(this.servicio.cliente.terminoBusqueda);
												this.clienteCtrl.setValue(this.servicio.cliente);
											}

											if (this.servicio.pagador) {
												this.pagadorAlt = true;
												this.filtrarPagadores();
												this.pagadorFilterCtrl.setValue(this.servicio.pagador.terminoBusqueda);
												this.pagadorCtrl.setValue(this.servicio.pagador);
											}

											if (this.servicio.serviciosPorCliente) {
												this.servicio.serviciosPorCliente.map(
													ruta => {
														ruta.tipoRutaRequerido = false;
														ruta.ubicacionRequerida = false;
														ruta.consignatarioRequerido = false;
														ruta.proveedorRequerido = false;
														ruta.monedaCostoRequerida = false;
														ruta.monedaVentaRequerida = false;
														ruta.configuracionRequerida = false;

														ruta.ubicacionCtrl = new FormControl();
														ruta.ubicacionFilterCtrl = new FormControl();
														this.filtrarUbicaciones(ruta);
														ruta.ubicacionCtrl.setValue(ruta.ubicacion);
														ruta.ubicacionFilterCtrl.setValue(ruta.ubicacion.ciudad);

														ruta.proveedorCtrl = new FormControl();
														ruta.proveedorFilterCtrl = new FormControl();
														this.filtrarProveedoresRuta(ruta);
														ruta.proveedorCtrl.setValue(ruta.proveedor);
														ruta.proveedorFilterCtrl.setValue(ruta.proveedor.razonComercial);

														this.ordenRuta = ruta.orden + 1;
													}
												);
											}

											if (this.servicio.cargosPorServicio) {
												this.servicio.cargosPorServicio.map(
													cargo => {
														cargo.proveedorCtrl = new FormControl();
														cargo.proveedorFilterCtrl = new FormControl();
														this.filtrarProveedoresCargo(cargo);
														cargo.proveedorCtrl.setValue(cargo.proveedor);
														cargo.proveedorFilterCtrl.setValue(cargo.proveedor.razonComercial);

														this.posicionCargo = cargo.posicion + 1;
													}
												)
											}
											this.actualizaTotales();
										}
									);
								}
							} else {
								swal('Tipos de Cambio', `No existen Tipos de Cambio configurados para ${anioActual}/${mesActual}`, 'error');
								//this.router.navigate(['/tiposCambio']);
							}
						}
					);
				} else {
					this.serviciosService.getServicio(idServicioTC).subscribe(
						servicio => {
							//console.log('se ejecuto--->'+servicio.fechaServicio);

							//this.servicio = servicio;
							anioActual = Number(servicio.fechaServicio.toString().substring(0, 4));
							mesActual = Number(servicio.fechaServicio.toString().substring(5, 7));
							//console.log('fecha anio ' + servicio.fechaServicio.toString().substring(5,7));
							//console.log('BUSCA ANIO: ' + anioActual);
							//console.log('BUSCA MES: ' + mesActual);
							this.tiposCambioService.getTiposCambioByAnioMes(anioActual, mesActual).subscribe(
								response => {
									this.listTiposCambio = response;
									this.listTiposCambio = this.listTiposCambio.filter(
										tipo => tipo.moneda.idMoneda == 1
									);
									if (this.listTiposCambio != null && this.listTiposCambio.length > 0) {
										this.seleccionaMonedas();
										this.cargarCatalogos();
										this.ordenRuta = 1;
										this.posicionCargo = 10;

										let idServicio: number = +params.get('idServicio');
										console.log('idServicio: ' + idServicio);
										if (idServicio == 0) {
											this.servicio = new Servicio();
											this.servicio.idServicio = null;
											this.servicio.idServicioStr = '';
											this.servicio.ordenCompra = '';
											this.servicio.anio = anioActual;
											this.servicio.trafico = this.authService.account.localAccountId;
											this.servicio.nombreTrafico = this.authService.account.name;
											this.servicio.notasCompletas = false;
											this.servicio.costoServicioUsd = 0.00;
											this.servicio.ventaServicioUsd = 0.00;
											this.servicio.costoCargoUsd = 0.00;
											this.servicio.ventaCargoUsd = 0.00;
											this.utilidadRutas = 0.00;
											this.utilidadCargos = 0.00;
											this.utilidadServicio = 0.00;
											this.servicio.fechaServicio = new Date(); //.toLocaleDateString('es-MX', {timeZone: 'America/Mexico_City'});

											const estatusServicio = new EstatusServicio();
											estatusServicio.idEstatusServicio = 1; //En Transito
											estatusServicio.descripcion = 'En Tránsito';
											this.servicio.estatus = estatusServicio;
										} else {
											this.serviciosService.getServicio(idServicio).subscribe(
												servicio => {
													console.log(servicio);
													this.servicio = servicio;

													if (this.servicio.cliente) {
														this.filtrarClientes();
														this.clienteFilterCtrl.setValue(this.servicio.cliente.terminoBusqueda);
														this.clienteCtrl.setValue(this.servicio.cliente);
													}

													if (this.servicio.pagador) {
														this.pagadorAlt = true;
														this.filtrarPagadores();
														this.pagadorFilterCtrl.setValue(this.servicio.pagador.terminoBusqueda);
														this.pagadorCtrl.setValue(this.servicio.pagador);
													}

													if (this.servicio.serviciosPorCliente) {
														console.log("CARGA SERVICIO EXISTENTE");
														this.servicio.serviciosPorCliente.map(
															ruta => {
																console.log("RUTA " + ruta.tipoRuta.idTipoRuta);
																if (ruta.tipoRuta.idTipoRuta == 1) {
																	ruta.fechaCarga = new Date(ruta.fechaCarga);
																	var os = ruta.fechaCarga.getTimezoneOffset();
																	ruta.fechaCarga = new Date(ruta.fechaCarga.getTime() + os * 60 * 1000);
																	ruta.fechaCarga.setHours(0, 0, 0, 0);
																}
																if (ruta.tipoRuta.idTipoRuta == 2) {
																	//console.log("ruta.fechaAgenteAduanal  "+ruta.fechaAgenteAduanal);
																	ruta.fechaAgenteAduanal = new Date(ruta.fechaAgenteAduanal);
																	var os = ruta.fechaAgenteAduanal.getTimezoneOffset();
																	ruta.fechaAgenteAduanal = new Date(ruta.fechaAgenteAduanal.getTime() + os * 60 * 1000);
																	ruta.fechaAgenteAduanal.setHours(0, 0, 0, 0);

																	ruta.fechaCruce = new Date(ruta.fechaCruce);
																	var os = ruta.fechaCruce.getTimezoneOffset();
																	ruta.fechaCruce = new Date(ruta.fechaCruce.getTime() + os * 60 * 1000);
																	ruta.fechaCruce.setHours(0, 0, 0, 0);
																	//console.log("ruta.fechaAgenteAduanal  "+ruta.fechaAgenteAduanal);
																}

																if (ruta.tipoRuta.idTipoRuta == 3) {
																	ruta.fechaDestino = new Date(ruta.fechaDestino);
																	var os = ruta.fechaDestino.getTimezoneOffset();
																	ruta.fechaDestino = new Date(ruta.fechaDestino.getTime() + os * 60 * 1000);
																	ruta.fechaDestino.setHours(0, 0, 0, 0);
																	console.log("-->" + ruta.fechaDestino);
																}
																ruta.tipoRutaRequerido = false;
																ruta.ubicacionRequerida = false;
																ruta.consignatarioRequerido = false;
																ruta.proveedorRequerido = false;
																ruta.monedaCostoRequerida = false;
																ruta.monedaVentaRequerida = false;
																ruta.configuracionRequerida = false;

																ruta.ubicacionCtrl = new FormControl();
																ruta.ubicacionFilterCtrl = new FormControl();
																this.filtrarUbicaciones(ruta);
																ruta.ubicacionCtrl.setValue(ruta.ubicacion);
																ruta.ubicacionFilterCtrl.setValue(ruta.ubicacion.ciudad);

																ruta.proveedorCtrl = new FormControl();
																ruta.proveedorFilterCtrl = new FormControl();
																this.filtrarProveedoresRuta(ruta);
																ruta.proveedorCtrl.setValue(ruta.proveedor);
																ruta.proveedorFilterCtrl.setValue(ruta.proveedor.razonComercial);

																this.ordenRuta = ruta.orden + 1;
															}
														);
													}

													if (this.servicio.cargosPorServicio) {
														this.servicio.cargosPorServicio.map(
															cargo => {
																cargo.proveedorCtrl = new FormControl();
																cargo.proveedorFilterCtrl = new FormControl();
																this.filtrarProveedoresCargo(cargo);
																cargo.proveedorCtrl.setValue(cargo.proveedor);
																cargo.proveedorFilterCtrl.setValue(cargo.proveedor.razonComercial);

																this.posicionCargo = cargo.posicion + 1;
															}
														)
													}
													this.actualizaTotales();
												}
											);
										}
									} else {
										swal('Tipos de Cambio', `No existen Tipos de Cambio configurados para ${anioActual}/${mesActual}`, 'error');
										//this.router.navigate(['/tiposCambio']);
									}
								}
							);
						}
					);

				}



			}
		);
	}



	public isReadOnly(): boolean {
		if (this.authService.hasRole('ROLE_SUPERADMIN')) {
			return false;
		}
		return this.authService.hasRole('ROLE_CUENTAS_PAGAR') || this.authService.hasRole('ROLE_CUENTAS_COBRAR') || this.authService.hasRole('ROLE_ANALISTA') || this.authService.hasRole('ROLE_EJECUTIVO') ||
			this.servicio.estatus.idEstatusServicio == 3 || this.servicio.estatus.idEstatusServicio == 4;
	}

	private cargarCatalogos(): void {
		this.filtrarClientes();
		this.filtrarPagadores();
		this.cargarMpcs();
		this.cargarTiposRuta();
		this.cargarMonedas();
		this.cargarCargos();
		this.cargarUsuariosChat();
	}

	private seleccionaMonedas(): void {
		this.listTiposCambio.map(tipo => {
			switch (tipo.moneda.idMoneda) {
				case 1:
					this.tipoDolar = tipo;
					break;
				case 2:
					this.tipoEuro = tipo;
					break;
				case 3:
					this.tipoLibra = tipo;
					break;
				case 4:
					this.tipoYen = tipo;
					break;
			}
		});
	}

	private filtrarClientes(): void {
		this.clienteFilterCtrl.valueChanges
			.pipe(
				filter(search => !!search),
				tap(() => this.searching = true),
				takeUntil(this._onDestroy),
				debounceTime(200),
				map(search => {
					//console.log("--> BUSQUEDA: "+search);
					return search;
				}),
				delay(100),
				takeUntil(this._onDestroy)
			)
			.subscribe(
				search => {
					//console.log("--> BUSQUEDA: "+search);
					if (search && search != null) {
						search = search.replace('&', '(amp)');
					}
					this.clientesService.listFirstClientesByBusqueda(search).subscribe(
						clientesFiltrados => {
							this.searching = false;
							if (this.clientesFiltrados == null) {
								this.clientesFiltrados = new ReplaySubject < Cliente[] > (1);
							}
							this.clientesFiltrados.next(clientesFiltrados);
						}
					);
				}
			);
	}

	private filtrarPagadores(): void {
		this.pagadorFilterCtrl.valueChanges
			.pipe(
				filter(search => !!search),
				tap(() => this.searching = true),
				takeUntil(this._onDestroy),
				debounceTime(200),
				map(search => {
					return search;
				}),
				delay(100),
				takeUntil(this._onDestroy)
			)
			.subscribe(
				search => {
					this.clientesService.listFirstClientesByBusqueda(search).subscribe(
						pagadoresFiltrados => {
							this.searching = false;
							if (this.pagadoresFiltrados == null) {
								this.pagadoresFiltrados = new ReplaySubject < Cliente[] > (1);
							}
							this.pagadoresFiltrados.next(pagadoresFiltrados);
						}
					);
				}
			);
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	private cargarMpcs(): void {
		this.mpcsService.listMpcs().subscribe(
			response => {
				this.listaMpcs = response;
			}
		);
	}

	private cargarTiposRuta(): void {
		this.tiposRutaService.listTiposRuta().subscribe(
			response => {
				this.listTiposRuta = response;
			}
		);
	}

	private filtrarUbicaciones(ruta: ServicioPorCliente): void {
		ruta.ubicacionFilterCtrl.valueChanges
			.pipe(
				filter(search => !!search),
				tap(() => this.searching = true),
				takeUntil(this._onDestroy),
				debounceTime(200),
				map(search => {
					return search;
				}),
				delay(100),
				takeUntil(this._onDestroy)
			).subscribe(
				search => {
					if (ruta.tipoRuta && ruta.tipoRuta.idTipoRuta == 2) {
						this.ubicacionesService.listFirstUbicacionesByCiudadAndPais(search, 'FRONTERA').subscribe(
							ubicacionesFiltradas => {
								this.searching = false;
								if (ruta.ubicacionesFiltradas == null) {
									ruta.ubicacionesFiltradas = new ReplaySubject < Ubicacion[] > (1);
								}
								ruta.ubicacionesFiltradas.next(ubicacionesFiltradas);
							}
						);
					} else {
						this.ubicacionesService.listFirstUbicacionesByCiudad(search).subscribe(
							ubicacionesFiltradas => {
								this.searching = false;
								if (ruta.ubicacionesFiltradas == null) {
									ruta.ubicacionesFiltradas = new ReplaySubject < Ubicacion[] > (1);
								}
								ruta.ubicacionesFiltradas.next(ubicacionesFiltradas);
							}
						);
					}
				}
			);
	}

	private filtrarProveedoresRuta(ruta: ServicioPorCliente): void {
		ruta.proveedorFilterCtrl.valueChanges
			.pipe(
				filter(search => !!search),
				tap(() => this.searching = true),
				takeUntil(this._onDestroy),
				debounceTime(200),
				map(search => {
					return search;
				}),
				delay(100),
				takeUntil(this._onDestroy)
			)
			.subscribe(search => {
					//console.log("--> BUSQUEDA PROVEEDOR RUTA: "+search);
					if (search && search != null) {
						search = search.replace('&', '(amp)');
					}
					this.proveedoresService.listFirstProveedoresByRazonComercial(search).subscribe(
						proveedoresFiltrados => {
							this.searching = false;
							if (ruta.proveedoresFiltrados == null) {
								ruta.proveedoresFiltrados = new ReplaySubject < Proveedor[] > (1);
							}
							ruta.proveedoresFiltrados.next(proveedoresFiltrados);
						}
					);
				},
				error => {
					console.log(error);
					this.searching = false;
				}
			);
	}

	private filtrarProveedoresCargo(cargo: CargoPorServicio): void {
		cargo.proveedorFilterCtrl.valueChanges
			.pipe(
				filter(search => !!search),
				tap(() => this.searching = true),
				takeUntil(this._onDestroy),
				debounceTime(200),
				map(search => {
					return search;
				}),
				delay(100),
				takeUntil(this._onDestroy)
			)
			.subscribe(search => {
					//console.log("--> BUSQUEDA PROVEEDOR RUTA: "+search);
					if (search && search != null) {
						search = search.replace('&', '(amp)');
					}
					this.proveedoresService.listFirstProveedoresByRazonComercial(search).subscribe(
						proveedoresFiltrados => {
							this.searching = false;
							if (cargo.proveedoresFiltrados == null) {
								cargo.proveedoresFiltrados = new ReplaySubject < Proveedor[] > (1);
							}
							cargo.proveedoresFiltrados.next(proveedoresFiltrados);
						}
					);
				},
				error => {
					console.log(error);
					this.searching = false;
				}
			);
	}

	private cargarMonedas(): void {
		this.monedasServices.listMonedas().subscribe(
			response => {
				this.listMonedas = response.filter(
					moneda => moneda.idMoneda == 1 || moneda.idMoneda == 5
				);
			}
		);
	}

	private cargarCargos(): void {
		this.cargosService.listCargos().subscribe(
			response => {
				this.listCargos = response;
			}
		);
	}

	private cargarUsuariosChat(): void {
		//Se agrega al usuario de tráfico al chatType
		let user_trafico = {
			'@odata.type': '#microsoft.graph.aadUserConversationMember',
			'roles': ['owner'],
			'user@odata.bind': 'https://graph.microsoft.com/v1.0/users(\'' + this.authService.account.localAccountId + '\')'
		};
		this.members.push(user_trafico);
		this.uniqueMembers.push(this.authService.account.localAccountId);

		this.graphService.getMembersSuperAdmin().subscribe(
			response => {
				response.value.map(
					usuario => {
						var existe: boolean = false;
						this.uniqueMembers.map(
							existente => {
								if (existente == usuario.id) {
									existe = true;
								}
							}
						);
						if (!existe) {
							let user = {
								'@odata.type': '#microsoft.graph.aadUserConversationMember',
								'roles': ['owner'],
								'user@odata.bind': 'https://graph.microsoft.com/v1.0/users(\'' + usuario.id + '\')'
							};
							this.members.push(user);
							this.uniqueMembers.push(usuario.id);
						}
					}
				);

				this.graphService.getMembersCuentasPagar().subscribe(
					response => {
						response.value.map(
							usuario => {
								var existe: boolean = false;
								this.uniqueMembers.map(
									existente => {
										if (existente == usuario.id) {
											existe = true;
										}
									}
								);
								if (!existe) {
									let user = {
										'@odata.type': '#microsoft.graph.aadUserConversationMember',
										'roles': ['owner'],
										'user@odata.bind': 'https://graph.microsoft.com/v1.0/users(\'' + usuario.id + '\')'
									};
									this.members.push(user);
									this.uniqueMembers.push(usuario.id);
								}
							}
						);
					}
				);
			}
		);
	}

	compararCliente(o1: Cliente, o2: Cliente) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idCliente === o2.idCliente;
	}

	compararPagador(o1: Cliente, o2: Cliente) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idCliente === o2.idCliente;
	}

	compararMpc(o1: Mpc, o2: Mpc) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idMpc === o2.idMpc;
	}

	compararTipoRuta(o1: TipoRuta, o2: TipoRuta) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idTipoRuta === o2.idTipoRuta;
	}

	compararUbicacion(o1: Ubicacion, o2: Ubicacion) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idUbicacion === o2.idUbicacion;
	}

	compararProveedor(o1: Proveedor, o2: Proveedor) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idProveedor === o2.idProveedor;
	}

	compararCargo(o1: Cargo, o2: Cargo) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idCargo === o2.idCargo;
	}

	compararMoneda(o1: Moneda, o2: Moneda) {
		if (o1 === undefined && o2 === undefined) {
			return true;
		}
		return o1 == null || o2 == null ? false : o1.idMoneda === o2.idMoneda;
	}

	agregarRuta(): void {
		if (this.servicio.serviciosPorCliente == null) {
			this.servicio.serviciosPorCliente = [];
		}
		let ruta = new ServicioPorCliente();
		ruta.orden = this.ordenRuta;
		ruta.costo = 0.00;
		ruta.venta = 0.00;
		ruta.ubicacionCtrl = new FormControl();
		ruta.ubicacionFilterCtrl = new FormControl();
		ruta.ubicacionesFiltradas = new ReplaySubject < Ubicacion[] > (1);
		this.filtrarUbicaciones(ruta);
		ruta.proveedorCtrl = new FormControl();
		ruta.proveedorFilterCtrl = new FormControl();
		ruta.proveedoresFiltrados = new ReplaySubject < Proveedor[] > (1);
		this.filtrarProveedoresRuta(ruta);
		ruta.pedidoCliente = '';
		this.servicio.serviciosPorCliente.push(ruta);
		this.ordenRuta = this.ordenRuta + 1;
	}

	agregarCargo(): void {
		if (this.servicio.cargosPorServicio == null) {
			this.servicio.cargosPorServicio = [];
		}
		let cargo = new CargoPorServicio();
		cargo.posicion = this.posicionCargo;
		cargo.costo = 0.00;
		cargo.venta = 0.00;
		cargo.proveedorCtrl = new FormControl();
		cargo.proveedorFilterCtrl = new FormControl();
		cargo.proveedoresFiltrados = new ReplaySubject < Proveedor[] > (1);
		cargo.pedidoCliente = '';
		this.filtrarProveedoresCargo(cargo);
		this.servicio.cargosPorServicio.push(cargo);
		this.posicionCargo = this.posicionCargo + 1;
	}

	actualizaTotales(): void {
		this.servicio.costoServicioUsd = 0.00;
		this.servicio.ventaServicioUsd = 0.00;
		this.servicio.costoCargoUsd = 0.00;
		this.servicio.ventaCargoUsd = 0.00;
		this.utilidadRutas = 0.00;
		this.utilidadCargos = 0.00;
		this.utilidadServicio = 0.00;

		if (this.servicio.serviciosPorCliente) {
			this.servicio.serviciosPorCliente.map(
				ruta => {
					let costoPesos: number;
					let costoDolares: number;

					if (!ruta.monedaCosto) {
						ruta.monedaCosto = new Moneda();
						ruta.monedaCosto.idMoneda = 1;
					}
					//  ruta.costo =    Number( ruta.costo.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }));
					//  console.log("cambia "+ruta.costo);
					//ruta.costo = ((Math.round(ruta.costo * 100) / 100).toFixed(2)).toString;

					switch (ruta.monedaCosto.idMoneda) {
						case 1:
							costoDolares = ruta.costo;
							break;
						case 2:
							costoPesos = ruta.costo * this.tipoEuro.valor;
							costoDolares = costoPesos / this.tipoDolar.valor;
							break;
						case 3:
							costoPesos = ruta.costo * this.tipoLibra.valor;
							costoDolares = costoPesos / this.tipoDolar.valor;
							break;
						case 4:
							costoPesos = ruta.costo * this.tipoYen.valor;
							costoDolares = costoPesos / this.tipoDolar.valor;
							break;
						case 5:
							costoDolares = ruta.costo / this.tipoDolar.valor;
							break;
					}
					this.servicio.costoServicioUsd = this.servicio.costoServicioUsd + costoDolares;

					if (!ruta.monedaVenta) {
						ruta.monedaVenta = new Moneda();
						ruta.monedaVenta.idMoneda = 1;
					}

					let ventaPesos: number;
					let ventaDolares: number;
					switch (ruta.monedaVenta.idMoneda) {
						case 1:
							ventaDolares = ruta.venta;
							break;
						case 2:
							ventaPesos = ruta.venta * this.tipoEuro.valor;
							ventaDolares = ventaPesos / this.tipoDolar.valor;
							break;
						case 3:
							ventaPesos = ruta.venta * this.tipoLibra.valor;
							ventaDolares = ventaPesos / this.tipoDolar.valor;
							break;
						case 4:
							ventaPesos = ruta.venta * this.tipoYen.valor;
							ventaDolares = ventaPesos / this.tipoDolar.valor;
							break;
						case 5:
							ventaDolares = ruta.venta / this.tipoDolar.valor;
							break;
					}
					this.servicio.ventaServicioUsd = this.servicio.ventaServicioUsd + ventaDolares;
				}
			);
		}

		if (this.servicio.cargosPorServicio) {
			this.servicio.cargosPorServicio.map(
				cargo => {
					let costoPesos: number;
					let costoDolares: number;

					if (!cargo.monedaCosto) {
						cargo.monedaCosto = new Moneda();
						cargo.monedaCosto.idMoneda = 1;
					}

					switch (cargo.monedaCosto.idMoneda) {
						case 1:
							costoDolares = cargo.costo;
							break;
						case 2:
							costoPesos = cargo.costo * this.tipoEuro.valor;
							costoDolares = costoPesos / this.tipoDolar.valor;
							break;
						case 3:
							costoPesos = cargo.costo * this.tipoLibra.valor;
							costoDolares = costoPesos / this.tipoDolar.valor;
							break;
						case 4:
							costoPesos = cargo.costo * this.tipoYen.valor;
							costoDolares = costoPesos / this.tipoDolar.valor;
							break;
						case 5:
							costoDolares = cargo.costo / this.tipoDolar.valor;
							break;
					}


					this.servicio.costoCargoUsd = this.servicio.costoCargoUsd + costoDolares;

					if (!cargo.monedaVenta) {
						cargo.monedaVenta = new Moneda();
						cargo.monedaVenta.idMoneda = 1;
					}

					let ventaPesos: number;
					let ventaDolares: number;
					ventaDolares = 0;
					switch (cargo.monedaVenta.idMoneda) {
						case 1:
							ventaDolares = cargo.venta;
							break;
						case 2:
							ventaPesos = cargo.venta * this.tipoEuro.valor;
							ventaDolares = ventaPesos / this.tipoDolar.valor;
							break;
						case 3:
							ventaPesos = cargo.venta * this.tipoLibra.valor;
							ventaDolares = ventaPesos / this.tipoDolar.valor;
							break;
						case 4:
							ventaPesos = cargo.venta * this.tipoYen.valor;
							ventaDolares = ventaPesos / this.tipoDolar.valor;
							break;
						case 5:
							ventaDolares = cargo.venta / this.tipoDolar.valor;
							break;
					}
						//console.log("=========");
						if (cargo.cargo.idCargo == 7) {
							//console.log("ES NC");
							ventaDolares = ventaDolares * -1;
						}
						//console.log("ventaDolares::"+ventaDolares);
						//console.log("this.servicio.ventaCargoUsd ::"+this.servicio.ventaCargoUsd );

					this.servicio.ventaCargoUsd = this.servicio.ventaCargoUsd + ventaDolares;
					//console.log("DESPUES DE SUMAR ::"+this.servicio.ventaCargoUsd );

				}
			);
		}
		this.utilidadRutas = this.servicio.ventaServicioUsd - this.servicio.costoServicioUsd;
		this.utilidadCargos = this.servicio.ventaCargoUsd - this.servicio.costoCargoUsd;
		this.utilidadServicio = this.utilidadRutas + this.utilidadCargos;
	}

	validarTipoRuta(tipoRuta: TipoRuta): boolean {
		if (!tipoRuta) {
			swal('Tipos de Ruta', 'Debe seleccionar un Tipo de Ruta', 'warning');
			return false;
		}
		return true;
	}

	configurarRuta(rutaSeleccionada: ServicioPorCliente) {
		if (this.validarTipoRuta(rutaSeleccionada.tipoRuta)) {
			this.rutaSeleccionada = rutaSeleccionada;
			this.servicio.serviciosPorCliente.map(
				ruta => {
					if (rutaSeleccionada.tipoRuta && ruta.tipoRuta && rutaSeleccionada.tipoRuta.idTipoRuta != ruta.tipoRuta.idTipoRuta) {
						if (ruta.tipoRuta.idTipoRuta == 1) {
							this.fechaCarga = ruta.fechaCarga;
						} else if (ruta.tipoRuta.idTipoRuta == 2) {
							this.fechaAgenteAduanal = ruta.fechaAgenteAduanal;
							this.fechaCruce = ruta.fechaCruce;
						} else if (ruta.tipoRuta.idTipoRuta == 3) {
							this.fechaDestino = ruta.fechaDestino;
						}
					}
				}
			);
			this.detalleService.abrirModal();
		}
	}

	actualizarRuta(ruta: ServicioPorCliente) {
		if (this.validarTipoRuta(ruta.tipoRuta)) {
			swal({
				title: ruta.pedidoCliente && ruta.pedidoCliente != '' ? 'Actualizar ruta' : 'Crear ruta',
				text: 'Esta seguro que desea ' + (ruta.pedidoCliente && ruta.pedidoCliente != '' ? 'actualizar la ruta?' : 'crear la ruta?'),
				icon: 'warning',
				buttons: {
					cancel: {
						text: 'No, cancelar.',
						value: null,
						visible: true,
						className: "",
						closeModal: true
					},
					confirm: {
						text: 'Si, confirmar!',
						value: true,
						visible: true,
						className: "bg-danger",
						closeModal: true
					}
				}
			}).then((isConfirm) => {
				if (isConfirm) {
					this.spinnerService.show();
					let rutaCopiada = Object.assign({}, ruta);
					rutaCopiada.ubicacionCtrl = null;
					rutaCopiada.ubicacionFilterCtrl = null;
					rutaCopiada.ubicacionesFiltradas = null;
					rutaCopiada.proveedorCtrl = null;
					rutaCopiada.proveedorFilterCtrl = null;
					rutaCopiada.proveedoresFiltrados = null;
					this.serviciosService.updateRuta(this.servicio.idServicio, rutaCopiada).subscribe(
						rutaActualizada => {
							console.log(rutaActualizada);
							swal('Ruta Actualizada', 'Se ha actualizado la ruta con exito', 'success');
							this.spinnerService.hide();
						}
					);
				}
			});
		}
	}

	eliminarRuta(ruta: ServicioPorCliente) {
		swal({
			title: 'Eliminar ruta',
			text: 'Esta seguro que desea eliminar la ruta?',
			icon: 'warning',
			buttons: {
				cancel: {
					text: 'No, cancelar.',
					value: null,
					visible: true,
					className: "",
					closeModal: true
				},
				confirm: {
					text: 'Si, eliminar!',
					value: true,
					visible: true,
					className: "bg-danger",
					closeModal: true
				}
			}
		}).then((isConfirm: boolean) => {
			if (isConfirm) {
				const index: number = this.servicio.serviciosPorCliente.indexOf(ruta);
				if (index !== -1) {
					this.servicio.serviciosPorCliente.splice(index, 1);
					this.actualizaTotales();
				}
			}
		});
	}

	eliminarCargo(cargo: CargoPorServicio, guardado: boolean) {
		swal({
			title: 'Eliminar cargo',
			text: 'Esta seguro que desea eliminar el cargo?',
			icon: 'warning',
			buttons: {
				cancel: {
					text: 'No, cancelar.',
					value: null,
					visible: true,
					className: "",
					closeModal: true
				},
				confirm: {
					text: 'Si, eliminar!',
					value: true,
					visible: true,
					className: "bg-danger",
					closeModal: true
				}
			}
		}).then((isConfirm: boolean) => {
			if (isConfirm) {
				const index: number = this.servicio.cargosPorServicio.indexOf(cargo);
				if (index !== -1) {
					if (guardado) {
						this.spinnerService.show();
						let cargoCopiado = Object.assign({}, cargo);
						cargoCopiado.proveedorCtrl = null;
						cargoCopiado.proveedorFilterCtrl = null;
						cargoCopiado.proveedoresFiltrados = null;
						this.serviciosService.deleteCargo(this.servicio.idServicio, cargoCopiado).subscribe(
							cargoEliminado => {
								console.log(cargoEliminado);
								this.servicio.cargosPorServicio.splice(index, 1);
								this.actualizaTotales();
								swal('Cargo Eliminado', 'Se ha eliminado el cargo con exito', 'success');
								this.spinnerService.hide();
							}
						);
					} else {
						this.servicio.cargosPorServicio.splice(index, 1);
						this.actualizaTotales();
					}
				}
			}
		});
	}

	actualizarCargo(cargo: CargoPorServicio) {
		swal({
			title: cargo.pedidoCliente && cargo.pedidoCliente != '' ? 'Actualizar cargo' : 'Crear cargo',
			text: 'Esta seguro que desea ' + (cargo.pedidoCliente && cargo.pedidoCliente != '' ? 'actualizar el cargo?' : 'crear el cargo?'),
			icon: 'warning',
			buttons: {
				cancel: {
					text: 'No, cancelar.',
					value: null,
					visible: true,
					className: "",
					closeModal: true
				},
				confirm: {
					text: 'Si, confirmar!',
					value: true,
					visible: true,
					className: "bg-danger",
					closeModal: true
				}
			}
		}).then((isConfirm: boolean) => {
			if (isConfirm) {
				this.spinnerService.show();
				let cargoCopiado = Object.assign({}, cargo);
				cargoCopiado.proveedorCtrl = null;
				cargoCopiado.proveedorFilterCtrl = null;
				cargoCopiado.proveedoresFiltrados = null;
				this.serviciosService.updateCargo(this.servicio.idServicio, cargoCopiado).subscribe(
					cargoActualizado => {
						console.log(cargoActualizado);
						swal('Cargo Actualizado', 'Se ha actualizado el cargo con exito', 'success');
						this.spinnerService.hide();
					}
				);
			}
		});
	}

	crear(borrador: boolean): void {
		this.spinnerService.show();
		const servicioGuardar: Servicio = new Servicio();

		if (this.servicio.idServicio) {
			servicioGuardar.idServicio = this.servicio.idServicio;
		}

		if (this.servicio.idServicioStr != '') {
			servicioGuardar.idServicioStr = this.servicio.idServicioStr;
		}

		servicioGuardar.estatus = this.servicio.estatus;
		servicioGuardar.anio = this.servicio.anio;
		servicioGuardar.trafico = this.servicio.trafico;
		servicioGuardar.nombreTrafico = this.servicio.nombreTrafico;
		servicioGuardar.fechaServicio = this.servicio.fechaServicio;
		servicioGuardar.costoServicioUsd = this.servicio.costoServicioUsd;
		servicioGuardar.ventaServicioUsd = this.servicio.ventaServicioUsd;
		servicioGuardar.costoCargoUsd = this.servicio.costoCargoUsd;
		servicioGuardar.ventaCargoUsd = this.servicio.ventaCargoUsd;
		servicioGuardar.idChat = this.servicio.idChat;
		servicioGuardar.consecutivo = this.servicio.consecutivo;
		servicioGuardar.ordenCompra = this.servicio.ordenCompra;
		servicioGuardar.fechaCierre = this.servicio.fechaCierre;

		let rutaOrigen: number = 0;
		let rutaDestino: number = 0;

		if (this.servicio.cliente) {
			this.clienteRequerido = false;
			servicioGuardar.cliente = this.servicio.cliente;
		} else if (!borrador) {
			this.clienteRequerido = true;
			swal('Cliente', 'Se debe seleccionar el Cliente', 'warning');
			this.spinnerService.hide();
			return;
		}

		if (this.pagadorAlt && this.servicio.pagador) {
			this.pagadorRequerido = false;
			servicioGuardar.pagador = this.servicio.pagador;
		}
		if (this.pagadorAlt && !this.servicio.pagador) {
			this.pagadorRequerido = true;
			swal('Pagador', 'Se debe seleccionar el Pagador', 'warning');
			this.spinnerService.hide();
			return;
		}

		servicioGuardar.mpc = this.servicio.mpc;
		this.mpcRequerido = false;

		if (this.servicio.estatus.idEstatusServicio == 3) {
			if (this.servicio.mpc && this.servicio.mpc.idMpc == 1) {
				this.mpcRequerido = false;
			} else if (this.servicio.mpc && this.servicio.mpc.idMpc == 2) {
				if (this.estatusRespaldo != null) {
					this.servicio.estatus = this.estatusRespaldo;
					this.estatusRespaldo = null;
				}
				this.mpcRequerido = true;
				swal('MPC', 'Se debe seleccionar "Final" en la MPC', 'warning');
				this.spinnerService.hide();
				return;
			}
		} else if (!this.servicio.mpc && !borrador) {
			this.mpcRequerido = true;
			swal('MPC', 'Se debe seleccionar la MPC', 'warning');
			this.spinnerService.hide();
			return;
		}

		servicioGuardar.notasCompletas = this.servicio.notasCompletas;
		servicioGuardar.notas = this.servicio.notas;
		this.notasRequeridas = false;

		if (this.servicio.estatus.idEstatusServicio == 3) {
			if (this.servicio.notasCompletas || (!this.servicio.notasCompletas && this.servicio.notas && this.servicio.notas.length > 0)) {
				this.notasRequeridas = false;
			} else {
				if (this.estatusRespaldo != null) {
					this.servicio.estatus = this.estatusRespaldo;
					this.estatusRespaldo = null;
				}
				this.notasRequeridas = true;
				swal('Notas', 'Se deben capturar notas sino es completo', 'warning');
				this.spinnerService.hide();
				return;
			}
		}

		if (this.servicio.serviciosPorCliente && this.servicio.serviciosPorCliente.length > 0) {
			const rutas: ServicioPorCliente[] = [];
			let rutaIncorrecta: boolean = false;
			let fechaCarga: Date;
			let fechaAgenteAduanal: Date;
			let fechaCruce: Date;
			let fechaDestino: Date;
			let fechasIncorrectas: boolean = false;

			this.servicio.serviciosPorCliente.map(
				ruta => {
					if (!rutaIncorrecta) {
						const rutaGuardar: ServicioPorCliente = new ServicioPorCliente();
						rutaGuardar.orden = ruta.orden;
						rutaGuardar.costo = ruta.costo;
						rutaGuardar.venta = ruta.venta;

						if (ruta.tipoRuta) {
							ruta.tipoRutaRequerido = false;
							rutaGuardar.tipoRuta = ruta.tipoRuta;
							if (ruta.tipoRuta.idTipoRuta == 1) {
								//console.log("-----> ruta.fechaCarga:"+ruta.fechaCarga);
								fechaCarga = ruta.fechaCarga;
								//console.log("-----> fecha carga:"+fechaCarga);
								rutaOrigen = rutaOrigen + 1;
							} else if (ruta.tipoRuta.idTipoRuta == 2) {
								fechaAgenteAduanal = ruta.fechaAgenteAduanal;
								fechaCruce = ruta.fechaCruce;
							} else if (ruta.tipoRuta.idTipoRuta == 3) {
								fechaDestino = ruta.fechaDestino;
								rutaDestino = rutaDestino + 1;
							}
						} else {
							ruta.tipoRutaRequerido = true;
						}

						if (ruta.ubicacion) {
							ruta.ubicacionRequerida = false;
							rutaGuardar.ubicacion = ruta.ubicacion;
						} else {
							ruta.ubicacionRequerida = true;
						}

						if (ruta.consignatario) {
							ruta.consignatarioRequerido = false;
							rutaGuardar.consignatario = ruta.consignatario;
						} else {
							ruta.consignatarioRequerido = true;
						}

						if (ruta.proveedor) {
							ruta.proveedorRequerido = false;
							rutaGuardar.proveedor = ruta.proveedor;
						} else {
							ruta.proveedorRequerido = true;
						}

						if (ruta.monedaCosto) {
							ruta.monedaCostoRequerida = false;
							rutaGuardar.monedaCosto = ruta.monedaCosto;
						} else {
							ruta.monedaCostoRequerida = true;
						}

						if (ruta.costo !== null) {
							ruta.costoRequerido = false;
							rutaGuardar.costo = ruta.costo;
						} else {
							ruta.costoRequerido = true;
						}

						if (ruta.venta !== null) {
							ruta.ventaRequerida = false;
							rutaGuardar.venta = ruta.venta;
						} else {
							ruta.ventaRequerida = true;
						}

						if (ruta.monedaVenta) {
							ruta.monedaVentaRequerida = false;
							rutaGuardar.monedaVenta = ruta.monedaVenta;
						} else {
							ruta.monedaVentaRequerida = true;
						}

						if (ruta.tipoRuta) {
							if (ruta.tipoRuta.idTipoRuta == 1 && ruta.fechaCarga) {
								ruta.configuracionRequerida = false;
								rutaGuardar.fechaCarga = ruta.fechaCarga;
								var os = rutaGuardar.fechaCarga.getTimezoneOffset();
								rutaGuardar.fechaCarga = new Date(rutaGuardar.fechaCarga.getTime() - os * 60 * 1000);

								rutaGuardar.fechaAgenteAduanal = null;
								rutaGuardar.fechaCruce = null;
								rutaGuardar.fechaDestino = null;
							} else if (ruta.tipoRuta.idTipoRuta == 2 && ruta.fechaAgenteAduanal && ruta.fechaCruce) {
								ruta.configuracionRequerida = false;
								rutaGuardar.fechaCarga = null;
								rutaGuardar.fechaAgenteAduanal = ruta.fechaAgenteAduanal;
								//console.log("rutaGuardar.fechaAgenteAduanal "+rutaGuardar.fechaAgenteAduanal);
								if (rutaGuardar.fechaAgenteAduanal) {
									var os = rutaGuardar.fechaAgenteAduanal.getTimezoneOffset();
									rutaGuardar.fechaAgenteAduanal = new Date(rutaGuardar.fechaAgenteAduanal.getTime() - os * 60 * 1000);
								}
								rutaGuardar.fechaCruce = ruta.fechaCruce;
								if (rutaGuardar.fechaCruce) {
									var os = rutaGuardar.fechaCruce.getTimezoneOffset();
									rutaGuardar.fechaCruce = new Date(rutaGuardar.fechaCruce.getTime() - os * 60 * 1000);
								}

								rutaGuardar.fechaDestino = null;

							} else if (ruta.tipoRuta.idTipoRuta == 3 && ruta.fechaDestino && ruta.podId &&
								ruta.tipoEquipo && ruta.numEquipoTipoEquipo) {
								ruta.configuracionRequerida = false;
								if (this.servicio.estatus.idEstatusServicio == 3 && ruta.podId == 2 && !ruta.archivoPod) {
									ruta.configuracionRequerida = true;
									if (this.estatusRespaldo != null) {
										this.servicio.estatus = this.estatusRespaldo;
										this.estatusRespaldo = null;
									}
								} else if (this.servicio.estatus.idEstatusServicio == 3 && ruta.podId == 3 && (!ruta.entregado || !ruta.comentarios)) {
									ruta.configuracionRequerida = true;
									if (this.estatusRespaldo != null) {
										this.servicio.estatus = this.estatusRespaldo;
										this.estatusRespaldo = null;
									}
								}
								if ((ruta.tipoEquipo.idTipoEquipo == 1 || ruta.tipoEquipo.idTipoEquipo == 3) && !ruta.tipoMovimiento) {
									ruta.configuracionRequerida = true;
								}
								if (ruta.tipoMovimiento && ruta.tipoMovimiento.idTipoMovimiento == 1 && !ruta.numEquipoTipoMovimiento) {
									ruta.configuracionRequerida = true;
								}
								if (!ruta.configuracionRequerida) {
									ruta.configuracionRequerida = false;
									rutaGuardar.fechaCarga = null;
									rutaGuardar.fechaAgenteAduanal = null;
									rutaGuardar.fechaCruce = null;
									rutaGuardar.fechaDestino = ruta.fechaDestino;
									if (rutaGuardar.fechaDestino) {
										var os = rutaGuardar.fechaDestino.getTimezoneOffset();
										rutaGuardar.fechaDestino = new Date(rutaGuardar.fechaDestino.getTime() - os * 60 * 1000);
									}
									rutaGuardar.podId = ruta.podId;
									rutaGuardar.archivoPod = ruta.archivoPod;
									rutaGuardar.entregado = ruta.entregado;
									rutaGuardar.comentarios = ruta.comentarios;
									rutaGuardar.tipoEquipo = ruta.tipoEquipo;
									rutaGuardar.numEquipoTipoEquipo = ruta.numEquipoTipoEquipo;
									rutaGuardar.tipoMovimiento = ruta.tipoMovimiento;
									rutaGuardar.numEquipoTipoMovimiento = ruta.numEquipoTipoMovimiento;
									rutaGuardar.referenciaCliente = ruta.referenciaCliente;
									//rutaGuardar.pedidoCliente = ruta.pedidoCliente;
								}

							} else {
								ruta.configuracionRequerida = true;
							}
						}
						//console.log("ruta.costoRequerido "+ruta.costoRequerido);
						//console.log("ruta.ventaRequerida "+ruta.ventaRequerida);
						if (ruta.tipoRutaRequerido || ruta.ubicacionRequerida || ruta.consignatarioRequerido ||
							ruta.proveedorRequerido || ruta.monedaCostoRequerida || ruta.monedaVentaRequerida ||
							ruta.configuracionRequerida || ruta.costoRequerido || ruta.ventaRequerida) {
							if (!borrador) {
								rutaIncorrecta = true;
								this.spinnerService.hide();
								return;
							}
						}
						rutaGuardar.pedidoCliente = ruta.pedidoCliente;
						rutaIncorrecta = false;
						rutas.push(rutaGuardar);
					}
				}
			);

			if (!borrador && rutaIncorrecta) {
				swal('Ruta Incorrecta', 'Se debe llenar la información completa de la ruta', 'warning');
				this.spinnerService.hide();
				return;
			}

			//se realiza ajuste a la fecha para quitar zonas horarias
			/*console.log("ANTES DE CONVERTIR->"+fechaCarga);
			var os = fechaCarga.getTimezoneOffset();
			  fechaCarga = new Date(fechaCarga.getTime() + os * 60 * 1000);
			  console.log("DESPUES DE CONVERTIR->"+fechaCarga);*/
			let erroresFecha: string = "";
			if (true) {
				//console.log("======== FECHAS GUARDADO ==========");
				fechaCarga.setHours(0, 0, 0, 0);
				//console.log("FECHA CARGA   -> " + fechaCarga.toISOString());
				if (fechaAgenteAduanal) {
					fechaAgenteAduanal.setHours(0, 0, 0, 0);
					//console.log("FECHA AA      -> " + fechaAgenteAduanal.toISOString());
				}
				if (fechaCruce) {
					fechaCruce.setHours(0, 0, 0, 0);
					//console.log("FECHA CRUCE   -> " + fechaCruce.toISOString());
				}
				fechaDestino.setHours(0, 0, 0, 0);

				//console.log("FECHA DESTINO -> " + fechaDestino.toISOString());

				if (fechaAgenteAduanal) {
					if (fechaCarga.toISOString() > fechaAgenteAduanal.toISOString()) {
						//swal('FECHA DE CARGA DEBE SER MAYOR O IGUAL A FECHA DE AGENTE ADUANAL', 'QUITAR', 'warning');
						erroresFecha += "\t - La fecha de carga debe ser menor o igual a la fecha de agente aduanal\n";
						fechasIncorrectas = true;
					}
				}

				if (fechaAgenteAduanal && fechaCruce) {
					if (fechaAgenteAduanal.toISOString() > fechaCruce.toISOString()) {
						//swal('FECHA DE CARGA DEBE SER MAYOR O IGUAL A FECHA DE AGENTE ADUANAL', 'QUITAR', 'warning');
						erroresFecha += "\t - La fecha de Agente aduanal debe ser menor o igual a la fecha de cruce\n";
						fechasIncorrectas = true;
					}
				}

				if (fechaCruce) {
					if (fechaCruce.toISOString() > fechaDestino.toISOString()) {
						//swal('FECHA DE CARGA DEBE SER MAYOR O IGUAL A FECHA DE AGENTE ADUANAL', 'QUITAR', 'warning');
						erroresFecha += "\t - La fecha de cruce debe ser menor o igual a la fecha destino\n";
						fechasIncorrectas = true;
					}
				}
				if (fechaCarga.toISOString() > fechaDestino.toISOString()) {
					//swal('FECHA DE CARGA DEBE SER MAYOR O IGUAL A FECHA DE AGENTE ADUANAL', 'QUITAR', 'warning');
					erroresFecha += "\t - La fecha de carga debe ser menor o igual a la fecha destino\n";
					fechasIncorrectas = true;
				}


				if (fechasIncorrectas) {
					swal('Fechas Incorrectas', erroresFecha, 'warning');
					this.spinnerService.hide();
					return;
				}
			}

			/*
			      if (!borrador && ((fechaAgenteAduanal && fechaCarga > fechaAgenteAduanal) || (fechaCruce && fechaCarga > fechaCruce) ||
			        fechaCarga > fechaDestino || (fechaAgenteAduanal && fechaAgenteAduanal > fechaDestino) ||
			        (fechaCruce && fechaCruce > fechaDestino))) {*/
			/*
if (!borrador){

        swal('Fechas Incorrectas', 'Las fechas estan incorrectas, debe revisarlas', 'warning');
        this.spinnerService.hide();
        return;
      }*/

			servicioGuardar.serviciosPorCliente = rutas;
		}

		if (!borrador && rutaOrigen > 1) {
			swal('Ruta Origen', 'Solo se puede tener una ruta origen', 'warning');
			this.spinnerService.hide();
			return;
		}

		if (!borrador && rutaDestino > 1) {
			swal('Ruta Destino', 'Solo se puede tener una ruta destino', 'warning');
			this.spinnerService.hide();
			return;
		}

		if (!borrador && (rutaOrigen < 1 || rutaDestino < 1)) {
			swal('Ruta Origen y Destino', 'Se debe tener una ruta origen y una ruta destino', 'warning');
			this.spinnerService.hide();
			return;
		}

		if (this.servicio.cargosPorServicio && this.servicio.cargosPorServicio.length > 0) {
			const cargos: CargoPorServicio[] = [];
			let cargoIncorrecto: boolean = false;

			this.servicio.cargosPorServicio.map(
				cargo => {
					if (!cargoIncorrecto) {
						const cargoGuardar: CargoPorServicio = new CargoPorServicio();
						cargoGuardar.posicion = cargo.posicion;
						cargoGuardar.costo = cargo.costo;
						cargoGuardar.venta = cargo.venta;

						if (cargo.proveedor) {
							cargo.proveedorRequerido = false;
							cargoGuardar.proveedor = cargo.proveedor;
						} else {
							cargo.proveedorRequerido = true;
						}

						if (cargo.cargo) {
							cargo.cargoRequerido = false;
							cargoGuardar.cargo = cargo.cargo;
						} else {
							cargo.cargoRequerido = true;
						}

						if (cargo.observaciones) {
							cargo.observacionesRequeridas = false;
							cargoGuardar.observaciones = cargo.observaciones;
						} else {
							cargo.observacionesRequeridas = true;
						}

						if (cargo.monedaCosto) {
							cargo.monedaCostoRequerida = false;
							cargoGuardar.monedaCosto = cargo.monedaCosto;
						} else {
							cargo.monedaCostoRequerida = true;
						}

						if (!cargo.cargo || (cargo.cargo && cargo.cargo.idCargo != 2)) {
							if (cargo.monedaVenta) {
								cargo.monedaVentaRequerida = false;
								cargoGuardar.monedaVenta = cargo.monedaVenta;
							} else {
								cargo.monedaVentaRequerida = true;
							}
						} else {
							cargo.monedaVentaRequerida = false;
						}

						if (cargo.costo !== null) {
							cargo.costoRequerida = false;
							cargoGuardar.costo = cargo.costo;
						} else {
							cargo.costoRequerida = true;
						}

						if (cargo.venta !== null) {
							cargo.ventaRequerida = false;
							cargoGuardar.venta = cargo.venta;
						} else {
							cargo.ventaRequerida = true;
						}

						if (cargo.proveedorRequerido || cargo.cargoRequerido || cargo.observacionesRequeridas ||
							cargo.monedaCostoRequerida || cargo.monedaVentaRequerida || cargo.ventaRequerida || cargo.costoRequerida) {
							if (!borrador) {
								cargoIncorrecto = true;
								this.spinnerService.hide();
								return;
							}
						}

						cargoGuardar.pedidoCliente = cargo.pedidoCliente;
						cargoIncorrecto = false;
						cargos.push(cargoGuardar);
					}
				}
			);

			if (!borrador && cargoIncorrecto) {
				swal('Cargo Incorrecto', 'Se debe llenar la información completa del cargo', 'warning');
				this.spinnerService.hide();
				return;
			}

			servicioGuardar.cargosPorServicio = cargos;
		}

		servicioGuardar.borrador = borrador;

		if (borrador && this.servicio.idServicioStr && this.servicio.idServicioStr.indexOf('Borrador') > -1) {
			this.serviciosService.updateServicio(servicioGuardar).subscribe(
				servicio => {
					this.servicio.idServicio = servicio.idServicio;
					this.servicio.idServicioStr = servicio.idServicioStr;
					this.servicio.consecutivo = servicio.consecutivo;
					this.servicio.ordenCompra = servicio.ordenCompra;
					this.spinnerService.hide();
					swal({
						title: 'Borrador actualizado',
						text: 'Borrador ' + servicio.idServicioStr + ' actualizado con exito, Desea volver al Inicio?',
						icon: 'success',
						buttons: {
							cancel: {
								text: 'No',
								value: false,
								visible: true,
								className: "bg-danger",
								closeModal: true
							},
							confirm: {
								text: 'Si',
								value: true,
								visible: true,
								className: "bg-success",
								closeModal: true
							}
						}
					}).then((isConfirm: boolean) => {
						if (isConfirm) {
							this.router.navigate(['/dashboard/']);
						} else {
							this.router.navigate(['/servicio/' + servicio.idServicio]);
						}
					});
				},
				error => {
					console.log(error);
					this.spinnerService.hide();
				}
			);
		} else if (borrador) {
			this.serviciosService.createServicio(servicioGuardar).subscribe(
				servicio => {
					this.servicio.idServicio = servicio.idServicio;
					this.servicio.idServicioStr = servicio.idServicioStr;
					this.servicio.consecutivo = servicio.consecutivo;
					this.servicio.ordenCompra = servicio.ordenCompra;
					this.spinnerService.hide();
					swal({
						title: 'Borrador creado',
						text: 'Borrador ' + servicio.idServicioStr + ' creado con exito, Desea volver al Inicio?',
						icon: 'success',
						buttons: {
							cancel: {
								text: 'No',
								value: false,
								visible: true,
								className: "bg-danger",
								closeModal: true
							},
							confirm: {
								text: 'Si',
								value: true,
								visible: true,
								className: "bg-success",
								closeModal: true
							}
						}
					}).then((isConfirm: boolean) => {
						if (isConfirm) {
							this.router.navigate(['/dashboard/']);
						} else {
							this.router.navigate(['/servicio/' + servicio.idServicio]);
						}
					});
				},
				error => {
					console.log(error);
					this.spinnerService.hide();
				}
			);
		} else if (this.servicio.idServicioStr && this.servicio.idServicioStr.indexOf('Borrador') == -1) {
			if (this.servicio.idChat == null) {
				this.graphService.createChat(servicioGuardar.idServicioStr, this.members).subscribe(
					chat => {
						this.servicio.idChat = chat.id;
						servicioGuardar.idChat = chat.id;
						this.serviciosService.updateServicio(servicioGuardar).subscribe(
							servicio => {
								this.servicio.idServicio = servicio.idServicio;
								this.servicio.idServicioStr = servicio.idServicioStr;
								this.servicio.consecutivo = servicio.consecutivo;
								this.servicio.ordenCompra = servicio.ordenCompra;
								this.spinnerService.hide();
								swal({
									title: 'Servicio actualizado',
									text: 'Servicio ' + servicio.idServicioStr + ' actualizado con exito, Desea volver al Inicio?',
									icon: 'success',
									buttons: {
										cancel: {
											text: 'No',
											value: false,
											visible: true,
											className: "bg-danger",
											closeModal: true
										},
										confirm: {
											text: 'Si',
											value: true,
											visible: true,
											className: "bg-success",
											closeModal: true
										}
									}
								}).then((isConfirm: boolean) => {
									if (isConfirm) {
										this.router.navigate(['/dashboard/']);
									} else {
										this.router.navigate(['/servicio/' + servicio.idServicio]);
									}
								});
							},
							error => {
								console.log(error);
								this.spinnerService.hide();
								if (this.estatusRespaldo != null) {
									this.servicio.estatus = this.estatusRespaldo;
									this.estatusRespaldo = null;
								}
							}
						);
					}
				);
			} else {
				this.serviciosService.updateServicio(servicioGuardar).subscribe(
					servicio => {
						this.servicio.idServicio = servicio.idServicio;
						this.servicio.idServicioStr = servicio.idServicioStr;
						this.servicio.consecutivo = servicio.consecutivo;
						this.servicio.ordenCompra = servicio.ordenCompra;
						this.spinnerService.hide();
						swal({
							title: 'Servicio actualizado',
							text: 'Servicio ' + servicio.idServicioStr + ' actualizado con exito, Desea volver al Inicio?',
							icon: 'success',
							buttons: {
								cancel: {
									text: 'No',
									value: false,
									visible: true,
									className: "bg-danger",
									closeModal: true
								},
								confirm: {
									text: 'Si',
									value: true,
									visible: true,
									className: "bg-success",
									closeModal: true
								}
							}
						}).then((isConfirm: boolean) => {
							if (isConfirm) {
								this.router.navigate(['/dashboard/']);
							} else {
								this.router.navigate(['/servicio/' + servicio.idServicio]);
							}
						});
					},
					error => {
						console.log(error);
						this.spinnerService.hide();
						if (this.estatusRespaldo != null) {
							this.servicio.estatus = this.estatusRespaldo;
							this.estatusRespaldo = null;
						}
					}
				);
			}
		} else {
			this.serviciosService.createServicio(servicioGuardar).subscribe(
				servicio => {
					this.servicio.idServicio = servicio.idServicio;
					//console.log(servicio);
					console.log(servicioGuardar);
					this.servicio.idServicioStr = servicio.idServicioStr;
					this.servicio.consecutivo = servicio.consecutivo;
					this.servicio.ordenCompra = servicio.ordenCompra;
					this.servicio.fechaServicio = servicio.fechaServicio;
					this.servicio.fechaCierre = servicio.fechaCierre;
					this.graphService.createChat(servicio.idServicioStr, this.members).subscribe(
						chat => {
							this.servicio.idChat = chat.id;
							servicioGuardar.idChat = chat.id;
							servicioGuardar.idServicio = servicio.idServicio;
							servicioGuardar.idServicioStr = servicio.idServicioStr;
							servicioGuardar.consecutivo = servicio.consecutivo;
							servicioGuardar.ordenCompra = servicio.ordenCompra;
							servicioGuardar.fechaServicio = servicio.fechaServicio;
							servicioGuardar.deCrm = true;
							this.serviciosService.updateServicio(servicioGuardar).subscribe(
								servicio => {
									this.spinnerService.hide();
									console.log(servicio);
									swal({
										title: 'Servicio creado',
										text: 'Servicio ' + this.servicio.idServicioStr + ' creado con exito, Desea volver al Inicio?',
										icon: 'success',
										buttons: {
											cancel: {
												text: 'No',
												value: false,
												visible: true,
												className: "bg-danger",
												closeModal: true
											},
											confirm: {
												text: 'Si',
												value: true,
												visible: true,
												className: "bg-success",
												closeModal: true
											}
										}
									}).then((isConfirm: boolean) => {
										if (isConfirm) {
											this.router.navigate(['/dashboard/']);
										} else {
											this.router.navigate(['/servicio/' + servicio.idServicio]);
										}
									});
								},
								error => {
									console.log(error);
									this.spinnerService.hide();
								}
							);
						}
					);
				},
				error => {
					console.log(error);
					this.spinnerService.hide();
				}
			);
		}
	}

	verMensajes(): void {
		this.idChatSeleccionado = this.servicio.idChat;
	}

	verCartaPorte(): void {
		this.idServicioSeleccionado = this.servicio.idServicio;
		if (this.servicio.cartaPorte != null) {
			this.cartaPorteSeleccionado = this.servicio.cartaPorte;
		} else {
			this.cartaPorteSeleccionado = new CartaPorte();
		}
		if (this.cartaPorteSeleccionado.mercancias == null) {
			this.cartaPorteSeleccionado.mercancias = new Mercancias();
		}
		if (this.cartaPorteSeleccionado.mercancias.autotransporte == null) {
			this.cartaPorteSeleccionado.mercancias.autotransporte = new Autotransporte();
		}
		if (this.cartaPorteSeleccionado.mercancias.autotransporte.identificacionVehicular == null) {
			this.cartaPorteSeleccionado.mercancias.autotransporte.identificacionVehicular = new IdentificacionVehicular();
		}
		if (this.cartaPorteSeleccionado.mercancias.transporteMaritimo == null) {
			this.cartaPorteSeleccionado.mercancias.transporteMaritimo = new TransporteMaritimo();
		}
		if (this.cartaPorteSeleccionado.mercancias.transporteAereo == null) {
			this.cartaPorteSeleccionado.mercancias.transporteAereo = new TransporteAereo();
		}
		if (this.cartaPorteSeleccionado.mercancias.transporteFerroviario == null) {
			this.cartaPorteSeleccionado.mercancias.transporteFerroviario = new TransporteFerroviario();
		}
		if (this.cartaPorteSeleccionado.figuraTransporte == null) {
			this.cartaPorteSeleccionado.figuraTransporte = new FiguraTransporte();
		}
		this.cartaPorteService.abrirModal();
	}

	timbrar() {
		this.timbradoService.pruebaTimbrarServicio(this.servicio);
	}

	cerrarServicio() {
		swal({
			title: 'Cerrar Servicio',
			text: 'Esta seguro que desea cerrar el servicio?',
			icon: 'warning',
			buttons: {
				cancel: {
					text: 'No, cancelar.',
					value: null,
					visible: true,
					className: "",
					closeModal: true
				},
				confirm: {
					text: 'Si, confirmar!',
					value: true,
					visible: true,
					className: "bg-danger",
					closeModal: true
				}
			}
		}).then((isConfirm: boolean) => {
			if (isConfirm) {
				this.estatusRespaldo = this.servicio.estatus;
				const estatusServicio = new EstatusServicio();
				estatusServicio.idEstatusServicio = 3; //Cerrado
				estatusServicio.descripcion = 'Cerrado';
				this.servicio.estatus = estatusServicio;
				this.crear(false);
			}
		});
	}

	cancelarServicio() {
		swal({
			title: 'Cancelar Servicio',
			text: 'Esta seguro que desea cancelar el servicio?',
			icon: 'warning',
			buttons: {
				cancel: {
					text: 'No, cancelar.',
					value: null,
					visible: true,
					className: "",
					closeModal: true
				},
				confirm: {
					text: 'Si, confirmar!',
					value: true,
					visible: true,
					className: "bg-danger",
					closeModal: true
				}
			}
		}).then((isConfirm: boolean) => {
			if (isConfirm) {
				this.idServicioSeleccionado = this.servicio.idServicio;
				this.cancelarServicioService.abrirModal();
			}
		});
	}

	verBitacoraSap() {
		this.idServicioSeleccionado = this.servicio.idServicio;
		this.bitacoraSapService.abrirModal();
	}


}
