import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Prospecto } from '../../../model/prospecto';
import { ProspectosService } from '../../../services/prospectos.service';
import { DetalleProspectoService } from './detalle-prospecto/detalle-prospecto.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PagetitleService } from '../../../core/pagetitle/pagetitle.service';
import { EvaluacionProveedorService } from '../../evaluacion-proveedor/evaluacion-proveedor.service';

@Component({
  selector: 'app-prospectos',
  templateUrl: './prospectos.component.html',
  styleUrls: ['./prospectos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProspectosComponent implements OnInit {
  public listaProspectos: Prospecto[];
  public prospectosTemp: Prospecto[];
  public prospectoSeleccionado: Prospecto;
  public idEvaluacionSeleccionada: number;
  public idProspectoSeleccionado: number;

  @ViewChild(DatatableComponent, { static: true }) table: DatatableComponent;
  @ViewChild('myTable', { static: true }) tableExp: any;

  constructor(
    private prospectosService: ProspectosService,
    private detalleService: DetalleProspectoService,
    private evaluacionProveedorService: EvaluacionProveedorService,
    public pt: PagetitleService
  ) {
    pt.setTitle('Prospectos');
  }

  ngOnInit() {
    this.prospectoSeleccionado = null;
    this.idEvaluacionSeleccionada = null;
    this.idProspectoSeleccionado = null;
    this.cargaProspectos();

    this.evaluacionProveedorService.saveEmitter.subscribe(
      () => {
        this.cargaProspectos();
      }
    );
  }

  private cargaProspectos(): void {
    this.prospectosService.listProspectos().subscribe(
      response => {
        this.listaProspectos = response;
        this.prospectosTemp = response;
      }
    );
  }

  abrirEvaluacion(prospecto: Prospecto): void {
    this.idEvaluacionSeleccionada = prospecto.idEvaluacion;
    this.idProspectoSeleccionado = prospecto.idProspecto;
    this.evaluacionProveedorService.abrirModal();
  }

  agregar(): void {
    this.prospectoSeleccionado = new Prospecto();
    this.detalleService.abrirModal();
  }

  editar(prospecto: Prospecto): void {
    this.prospectoSeleccionado = prospecto;
    this.detalleService.abrirModal();
  }

  filtrar(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.prospectosTemp.filter(function(d) {
          return d.idProspecto == val || d.razonComercial.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.listaProspectos = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
  }

}
